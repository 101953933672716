import * as React from "react";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router";

import MessagePanel from "../../controls/MessagePanel";

import { AuthorizationService, IPasswordResetRequest } from "../../../services/authorizationService";
import { DataService } from "../../../services/dataService";

import { IState, IPasswordResetState } from "../../../state/state";
import { IAction, setPasswordResetState, login, loginFailed, blockUi } from "../../../actions";

export interface IPasswordResetProps {
    dispatch: (action: IAction) => void,
    onStateChange: (newState: IPasswordResetState) => void,
    loginErrorMessage: string,
    passwordResetState: IPasswordResetState
}

const handleChange = (props: IPasswordResetProps, e: any, property: string): void => {
    let newState: IPasswordResetState = Object.assign({}, props.passwordResetState);
    switch (property) {
        case "email":
            newState.email = e.target.value;
            break;
        case "resetCode":
            newState.resetCode = e.target.value;
            break;
        case "newPassword":
            newState.newPassword = e.target.value;
            break;
    }
    props.onStateChange(newState);
}

const onLoginSuccess = (navigate: NavigateFunction, props: IPasswordResetProps, token: string): void => {
    props.dispatch(blockUi(false, ""));
    props.dispatch(login(token));
    DataService.getUserState(token, true, props.dispatch);
    navigate('/leagues');
};

const onLoginFailure = (props: IPasswordResetProps, message: string): void => {
    props.dispatch(loginFailed(message));
    props.dispatch(blockUi(false, ""));
};

const resetPassword = (navigate: NavigateFunction, props: IPasswordResetProps, e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    const passwordResetRequest: IPasswordResetRequest = {
        email: props.passwordResetState.email,
        resetCode: props.passwordResetState.resetCode,
        newPassword: props.passwordResetState.newPassword
    };
    props.dispatch(blockUi(true, "Authenticating..."));
    AuthorizationService.resetPassword(navigate, props, passwordResetRequest, onLoginSuccess, onLoginFailure);
}

const PasswordReset = (props: IPasswordResetProps) => {
    const navigate = useNavigate();
    return (
        <div>
            <div className="jumbotron">
            </div>

            <h2 className="tight-panel"><img src="/img/new/profile-32x32.png" className="icon32" alt=""/> Reset My Password</h2>
            <div className="content-panel">
                <div className="form-group">
                    <label htmlFor="emailInput">Email Address</label>
                    <input
                        type="email"
                        className="form-control"
                        id="emailInput"
                        placeholder="Email Address"
                        readOnly={true}
                        value={props.passwordResetState?.email ?? ''}
                        onChange={e => handleChange(props, e, 'email')}
                        />
                </div>
                <div className="form-group">
                    <label htmlFor="codeInput">Code</label>
                    <input
                        type="text"
                        className="form-control"
                        id="codeInput"
                        placeholder="Code"
                        readOnly={true}
                        value={props.passwordResetState?.resetCode ?? ''}
                        onChange={e => handleChange(props, e, 'resetCode')}
                        />
                </div>
                <div className="form-group">
                    <label htmlFor="passwordInput">Password</label>
                    <p>Type a new password here.</p>
                    <input
                        type="password"
                        className="form-control"
                        id="passwordInput"
                        placeholder="Password"
                        value={props.passwordResetState?.newPassword ?? ''}
                        onChange={e => handleChange(props, e, 'newPassword')}
                        />
                </div>
                <MessagePanel className="bg-danger" message={props.loginErrorMessage} />
                <button
                    type="submit"
                    className="btn btn-pickem-blue"
                    onClick={e => resetPassword(navigate, props, e)}>
                    Reset My Password
                </button>
            </div>
        </div>
        );
}

function mapStateToProps(state: IState) {
    return {
        loginErrorMessage: state.loginErrorMessage,
        passwordResetState: state.passwordResetState
    };
}

const mapDispatchToProps = (dispatch: (action: IAction) => void) => {
    return {
        dispatch,
        onStateChange: (newState: IPasswordResetState) => {
            dispatch(setPasswordResetState(newState));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset as any);
