import * as React from "react";
import _ from "lodash";

import { UtilService } from "../../../services/utilService";
import { ILeagueView, IGamePick } from "../../../models/interfaces";

export interface IGameDetailProps {
    league: ILeagueView;
    gamePick: IGamePick;        
}

const whosPicked = (props: IGameDetailProps) => {
    if (props.gamePick.locked || props.league.leagueRuleSetId === 3) {
        return null;
    } else {
        return (
            <tr>
                <td className="detail-cell">Who's Picked</td>
                <td className="detail-cell">{props.gamePick.detail.havePicked.join(', ')}</td>
            </tr>);
    }
}

const whoHasnt = (props: IGameDetailProps) => {
    let text: string;
    if (props.league.leagueRuleSetId === 3) {
        return null;
    }
    if (props.gamePick.locked) {
        text = "Did Not Pick";
    } else {
        text = "Who's Hasn't";
    }
    return (
        <tr>
            <td className="detail-cell">{text}</td>
            <td className="detail-cell">{props.gamePick.detail.haveNotPicked.join(', ')}</td>
        </tr>);
}

const pickedHome = (props: IGameDetailProps) => {
    if (props.gamePick.locked) {
        return (
            <tr>
                <td className="detail-cell">Picked {props.gamePick.homeTeamName}</td>
                <td className="detail-cell">{props.gamePick.detail.pickedHome.join(', ')}</td>
            </tr>);
    } else {
        return null;
    }
}

const pickedAway = (props: IGameDetailProps) => {
    if (props.gamePick.locked) {
        return (
            <tr>
                <td className="detail-cell">Picked {props.gamePick.awayTeamName}</td>
                <td className="detail-cell">{props.gamePick.detail.pickedAway.join(', ')}</td>
            </tr>);
    } else {
        return null;
    }
}

const spreadText = (props: IGameDetailProps): string => {
    if (_.isNull(props.gamePick.homeSpread)) {
        return 'No favorite yet.';
    }
    if (props.gamePick.homeSpread === 0) {
        return 'Even';
    }
    if (props.gamePick.homeSpread < 0) {
        return `${props.gamePick.homeTeamName} ${props.gamePick.homeSpread.toString()}`;
    }
    return `${props.gamePick.awayTeamName} ${(props.gamePick.homeSpread * -1).toString()}`;
}

const GameDetail = (props: IGameDetailProps) => {

    if (!props.gamePick.detailVisible || !_.isObject(props.gamePick.detail))
    {
        return <span/>;
    }

    return (
        <table className="detail-table table-condensed table-striped shadow">
            <tbody>
                <tr>
                    <td className="detail-cell">Location</td>
                    <td className="detail-cell">{props.gamePick.gameLocation}</td>
                </tr>
                <tr>
                    <td className="detail-cell">Network</td>
                    <td className="detail-cell">{props.gamePick.network}</td>
                </tr>
                <tr>
                    <td className="detail-cell">Favorite</td>
                    <td className="detail-cell">{spreadText(props)}</td>
                </tr>
                <tr>
                    <td className="detail-cell">Start</td>
                    <td className="detail-cell">{UtilService.dateForDisplay(props.gamePick.start)}</td>
                </tr>
                <tr>
                    <td className="detail-cell">Finish</td>
                    <td className="detail-cell">{UtilService.dateForDisplay(props.gamePick.complete)}</td>
                </tr>
                <tr>
                    <td className="detail-cell">Final</td>
                    <td className="detail-cell">{props.gamePick.final ? 'Yes' : 'No'}</td>
                </tr>
                {pickedHome(props)}
                {pickedAway(props)}
                {whosPicked(props)}
                {whoHasnt(props)}
            </tbody>
        </table>
    );
}

export default GameDetail;
