import { Store } from "redux";
import _ from "lodash";

import { DataService } from "./dataService";
import { UtilService } from "./utilService";

import { IState, ILeagueState, ILeagueSeasonState } from "../state/state";
import { ILeagueView, IWeek, IGamePick, ILeagueWeekTeamEliminationSummary } from "../models/interfaces";

export class GameRefreshService {

    static liveUpdateSeconds: number = 15;

    static initialized: boolean = false;

    static debug: boolean = false;

    static init(store: Store<IState>): void {
        if (!this.initialized) {
            this.initialized = true;
            if (this.debug) console.info("refresh loop INIT " + (new Date()).toString());
            setTimeout(() => this.refreshLoop(store), this.liveUpdateSeconds * 1000);
        }
    }

    static refreshLoop(store: Store<IState>): void {

        const leagueState: ILeagueState | null = store.getState().leagueState;
        const leagueSeasonState: ILeagueSeasonState | null = store.getState().leagueSeasonState;

        if ((_.isNull(leagueState) || _.isUndefined(leagueState)) || (_.isNull(leagueSeasonState) || _.isUndefined(leagueSeasonState))) {
            if (this.debug) {
                console.info("refresh loop SKIP (1) " + (new Date()).toString());
            }
            this.initialized = false;
            return;
        }

        const leagueView: ILeagueView | null = _.isNull(leagueState) ? leagueSeasonState.leagueView : leagueState.leagueView;

        if (_.isNull(leagueView) || _.isUndefined(leagueView)) {
            if (this.debug) console.info("refresh loop SKIP (2) " + (new Date()).toString());
            this.initialized = false;
            return;
        }

        const leagueId: number = leagueView.leagueId;
        const leagueMemberId: number | null =
            leagueSeasonState !== null && leagueSeasonState.leagueMember !== null
                ? leagueSeasonState.leagueMember.leagueMemberId
                : leagueState !== null && leagueState.leagueMember !== null
                    ? leagueState.leagueMember.leagueMemberId
                    : null;

        if (_.isNull(leagueId) || _.isNull(leagueMemberId) || leagueId < 1) {
            if (this.debug) {
                console.info("refresh loop SKIP (3) " + (new Date()).toString());
            }
            this.initialized = false;
            return;
        }

        if (this.debug) {
            console.info("refresh loop [" + leagueId.toString() + '] ' + (new Date()).toString());
        }

        let updateInitiated: boolean = false;

        if (leagueSeasonState != null) {

            let week: IWeek | undefined = _.find(leagueSeasonState.weeks, (w: IWeek) => w.weekId === leagueSeasonState.leagueMemberCurrentWeekPicksDue?.weekId);

            // if we found the current week and game picks are visible...
            if (week &&_.isObject(week) && week.gamePicks && week.gamePicks.length > 0) {
                // refresh if there may be game updates.
                if (this.shouldDoLiveUpdatesFromGamePicks(week.gamePicks)) {
                    DataService.getGamePickLiveUpdates(
                        store.getState().authenticationToken,
                        leagueId,
                        leagueMemberId,
                        week.weekId,
                        store.dispatch);
                    updateInitiated = true;
                }
            }
        }

        if (leagueState != null && !updateInitiated) {

            // update if this is an elimination week and games are happening.

            if (leagueState.leagueRuleSet?.elimination) {

                if (leagueState.thisWeekSummary) {

                    let weekId = leagueState.thisWeekSummary.weekId;

                    if (this.shouldDoLiveUpdatesFromSummaries(leagueState.thisWeekSummary.leagueWeekTeamEliminationSummaries)) {
                        DataService.getGamePickLiveUpdates(
                            store.getState().authenticationToken,
                            leagueId,
                            leagueMemberId,
                            weekId,
                            store.dispatch);
                    }
                }
            }            
        }

        setTimeout(() => this.refreshLoop(store), this.liveUpdateSeconds * 1000);
    }

    static shouldDoLiveUpdatesFromGamePicks(gamePicks: Array<IGamePick>): boolean {
        let gamePick: IGamePick | undefined = _.find(gamePicks, gp => {
            return this.shouldDoLiveUpdatesFromTime(gp.start, gp.complete, gp.locked, gp.final);
        });
        return _.isObject(gamePick);
    }

    static shouldDoLiveUpdatesFromSummaries(summaries: Array<ILeagueWeekTeamEliminationSummary>): boolean {
        let summary: ILeagueWeekTeamEliminationSummary | undefined = _.find(summaries, summary => {
            return this.shouldDoLiveUpdatesFromTime(summary.start, summary.complete, true, summary.final);
        });
        return _.isObject(summary);
    }

    static shouldDoLiveUpdatesFromTime(st: any, comp: any, locked: boolean, final: boolean): boolean {
        const now: Date = new Date();
        const start: Date | null = UtilService.dateFromString(st);
        const complete: Date | null = UtilService.dateFromString(comp);
        
        if (!start) {
            return false;            
        }

        const startDiff: number = UtilService.getDateDiff(start, now) / 1000 / 60; // get minutes

        const completeDiff: number | null = complete != null ? UtilService.getDateDiff(complete, now) / 1000 / 60 : null; // get minutes

        // start diff of 10 means the game starts in 10 minutes.
        // start diff of -180 means the game started 3 hours ago

        return (startDiff < 10 && startDiff > -180) || (locked && !final) || (final && !_.isNull(completeDiff) && completeDiff > -10);
    }
}
