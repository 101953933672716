import * as React from "react";
import { Link } from "react-router-dom";

const NavMenu = () => (

    <ul className="nav navbar-nav">
        <li>
            <Link to="/publicPage" data-toggle="collapse" data-target=".navbar-collapse">View Leagues</Link>
        </li>
        <li>
            <Link to="/login" data-toggle="collapse" data-target=".navbar-collapse">Login</Link>
        </li>
        <li>
            <Link to="/createProfile" data-toggle="collapse" data-target=".navbar-collapse">Create Profile</Link>
        </li>
    </ul>
);

export default NavMenu;
