import * as React from "react";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router";
import _ from "lodash";

import HidableButton from "../../controls/HidableButton";
import MessagePanel from "../../controls/MessagePanel";

import { routeToLeague, routeToCreateProfile } from "../../../services/routingService";

import { IState, IUserState, IUserLeaguesState, IPublicPageState } from "../../../state/state";
import { IAction } from "../../../actions";
import { ILeagueView } from "../../../models/interfaces";

export interface IPublicPageProps {
    dispatch: (action: IAction) => void,
    userState: IUserState,
    userLeaguesState: IUserLeaguesState,
    publicPageState: IPublicPageState
}

const publicLeagueMapper = (navigate: NavigateFunction, props: IPublicPageProps) => {
    if (props.userLeaguesState.publicLeagues.length === 0) {
        return [<tr key="row1"><td colSpan={5}>No public leagues found.</td></tr>]
    } else {        
        return props.userLeaguesState.publicLeagues.map(
            (league: ILeagueView) =>
                <tr key={league.leagueId}>
                    <td>
                        <button
                            type="submit"
                            className="btn btn-pickem-blue button-margin"
                            onClick={() => routeToLeague(navigate, league.leagueId, null)}>
                            <i className="fa fa-eye" aria-hidden="true"/> View
                            </button>
                        <HidableButton
                            className="btn btn-pickem-blue button-margin"
                            visible={league.isPublic && !league.locked}
                            image="plus-square"
                            text="Join"
                            onClick={() => {
                                routeToCreateProfile(navigate);
                            } }
                            />
                    </td>
                    <td>{league.leagueName}</td>
                    <td>{league.seasonName}</td>
                    <td>{league.ownerName}</td>
                    <td>{league.members}</td>
                </tr>
        )
    }
}

const PublicPage = (props: IPublicPageProps) => {
    const navigate = useNavigate();
    
    if (!_.isObject(props.userState) || !_.isObject(props.userLeaguesState)) {
        // seemingly getting here with no userState...
        // returning a blank template until the state arrives fixes this.
        return <div/>;
    }
    
    return <div>
        <h2 className="tight-panel"><img src="/img/new/leagues-32x32.png" className="icon32" alt=""/> Public Leagues</h2>
        <div className="content-panel">
            <p>Logged in users can create their own, private leagues.</p>
            <MessagePanel className="bg-danger" message={props.userLeaguesState.publicLeaguesErrorMessage} />
        </div>
        <table className="table table-bordered table-hover shadow">
            <thead>
                <tr>
                    <th>Nav</th>
                    <th>Name</th>
                    <th>Season</th>
                    <th>Owner</th>
                    <th>Members</th>
                </tr>
            </thead>
            <tbody>
                {publicLeagueMapper(navigate, props)}
            </tbody>
        </table>
    </div>;
}

function mapStateToProps(state: IState) {
    return {
        userState: state.userState,
        userLeaguesState: state.userLeaguesState,
        publicPageState: state.publicPageState
    };
}

export default connect(mapStateToProps)(PublicPage as any);
