import * as React from "react";

import { UtilService } from "../../../services/utilService";

import { IGamePick } from "../../../models/interfaces";

export interface IGameDayTimeProps {
    gamePick: IGamePick;
    toggleGameDetail: (weekId: number, gameId: number) => void;
}

const GameDayTime = (props: IGameDayTimeProps): JSX.Element | null => {

    const startDate: Date | null = UtilService.dateFromString(props.gamePick.start);
    if (!startDate) {
        return null;
    }
    const day: string = UtilService.weekString(startDate.getDay());
    const dateShort: string = UtilService.dateForDisplayShort(startDate);
    const time: string = UtilService.dateFormatAmPm(startDate).replace(':00', '');

    let period: string;
    let progress: string = '';

    switch (props.gamePick.period)
    {
        case '':
        case null:
            period = '';
            break;
        case '1':
        case '2':
        case '3':
        case '4':
            period = `Q${props.gamePick.period}`;
            progress = props.gamePick.progress;
            break;
        case '5':
            period = 'Overtime';
            progress = props.gamePick.progress;
            break;
        case 'H':
            period = 'Halftime';
            break;
        case 'F':
            period = 'Final';
            break;
        case 'FO':
            period = 'Final O/T';
            break;
        default:
            period = props.gamePick.period;
            break;
    }
    
    let display: string = `${day}, ${dateShort}, ${time}`;

    if (period && period.length > 0) {
        display = `${display}, ${period}`;
    }

    if (progress && progress.length > 0) {
        display = `${display} ${progress}`;
    }

    if (props.gamePick.lastNote && props.gamePick.lastNote.length > 0) {
        display = `${display},  ${props.gamePick.lastNote}`;
    }
        
    return (
        <div
            className="time-box clickable"
            onClick={e => props.toggleGameDetail(props.gamePick.weekId, props.gamePick.gameId)}
            >
            <span className="glyphicon glyphicon-triangle-bottom" aria-hidden="true"/>
            &nbsp;
            {display}
        </div>
    );
}

export default GameDayTime;
