import * as React from "react";
import _ from "lodash";

export interface MessagePanelProps {
    className: string;
    message: string;
}

const visibilityClass = (message: string) => {
    if (_.isNull(message) || _.isUndefined(message) || message.length === 0) {
        return "element-hide";
    }
    return "element-show";
}

const MessagePanel = (props: MessagePanelProps) => (
    <p className={`message-panel ${props.className} ${visibilityClass(props.message)}`}>{props.message}</p>
);

export default MessagePanel;
