import { ILeagueState, ILeagueManageState, ILeagueMemberState, ILeagueSeasonState, IInviteState, IInviteVerifyState, ICreateLeagueState } from "./state";

export class DefaultStateProvider {

    static getInviteStateError(errorMessage: string): IInviteState {
        return {
            initErrorMessage: errorMessage,
            leagueView: null,
            leagueRuleSet: null,
            invites: [],
            emailAddresses: '',
            lastResponse: null
        };
    }

    static getInviteVerifyStateError(errorMessage: string): IInviteVerifyState {
        return {
            initErrorMessage: errorMessage,
            valid: false,
            authRequired: false,
            code: null,
            leagueView: null,
            leagueRuleSet: null,
            leagueMember: null
        };
    }

    static getLeagueStateError(errorMessage: string) : ILeagueState {
        return {
            initErrorMessage: errorMessage,
            leagueView: null,
            leagueRuleSet: null,
            leagueMember: null,
            leagueMemberCurrentWeekPicksDue: null,
            leagueMembers: [],
            eliminationGrid: [],
            lastWeekSummary: null,
            thisWeekSummary: null,
            standings: null
        };
    }

    static getLeagueManageStateError(errorMessage: string): ILeagueManageState {
        return {
            initErrorMessage: errorMessage,
            leagueView: null,
            leagueRuleSet: null,
            leagueMembers: []
        };
    }

    static getLeagueMemberStateError(errorMessage: string): ILeagueMemberState {
        return {
            initErrorMessage: errorMessage,
            leagueView: null,
            leagueRuleSet: null,
            leagueMember: null,
            userEntries: 0,
            userInfo: null,
            totalGames: 0,
            all: null,
            home: null,
            away: null,
            favorite: null,
            underdog: null,
            even: null,
            conferenceStats: [],
            divisionStats: [],
            teamStats: [],
            weeks: [],
            managerPickInfo: null
        };
    }

    static getLeagueSeasonStateError(errorMessage: string) : ILeagueSeasonState {
        return {
            initErrorMessage: errorMessage,
            leagueView: null,
            leagueRuleSet: null,
            leagueMember: null,
            leagueMemberCurrentWeekPicksDue: null,
            leagueMembers: [],
            currentEliminationPicks: [],
            weeks: []
        };
    }

    static getDefaultCreateLeagueState(): ICreateLeagueState {
        return {
            createLeague: {
                name: '',
                seasonId: -1,
                leagueTypeId: 1,
                isPublic: true
            },
            errorMessage: ''
        };
    }
}