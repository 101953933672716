import _ from "lodash";
import * as bowser from 'bowser';
import dateFormat from "dateformat";

// declare var dateFormat: any;

// set to last league of previous season
export const oldLeagueCutOffId: number = 1154; // 832

export const isChrome = !!bowser.chrome;
export const isSafari = !!bowser.safari;
export const isIe = !!bowser.msie;
export const isEdge = !!bowser.msedge;
export const isIos = !!bowser.ios;
export const isApple = !!bowser.mac;

export class UtilService {

    /*static isChrome(): boolean {
        return navigator.userAgent.toLowerCase().indexOf('applewebkit') > -1 && navigator.userAgent.toLowerCase().indexOf('edge') < 0;
    }

    static isIOs(): boolean {
        return navigator.userAgent.toLowerCase().indexOf('applewebkit') > -1 && navigator.userAgent.toLowerCase().indexOf('edge') < 0;
    }*/

    static dateFromString(dt: any): Date | null {

        if (_.isUndefined(dt) || _.isNull(dt)) {
            return null;
        }

        const date = new Date(dt);

        // 6/27/2017, Chrome 58.0.3029.110
        // looks like this is no longer required.
        /*if (UtilService.isChrome()) {
            const userTimezoneOffset = new Date().getTimezoneOffset() * 60000;
            return new Date(date.getTime() + userTimezoneOffset);            
        }*/

        // 9/26/2020, IOS 14
        // looks like this is no longer required.
        /*if (isIos) {
            const userTimezoneOffset = new Date().getTimezoneOffset() * 60000;
            return new Date(date.getTime() + userTimezoneOffset);            
        }*/

        return date;
    }

    static dateFormatAmPm(dt: Date): string {
        const hours: number = dt.getHours() % 12;
        const minutes: number = dt.getMinutes();
        const ampm: string = dt.getHours() >= 12 ? 'pm' : 'am';
        const hoursStr: string = hours > 0 ? hours.toString() : '12';
        const minutesStr: string = minutes < 10 ? '0' + minutes.toString() : minutes.toString();
        const time: string = hoursStr + ':' + minutesStr + ' ' + ampm;
        return time;
    }

    static dateForDisplay(dt: any): string {

        // https://www.npmjs.com/package/dateformat

        if (_.isUndefined(dt) || _.isNull(dt)) {
            return '';
        }

        // let date: Date | null;

        // looks like this is no longer required.
        // let convert: boolean = UtilService.isChrome();
        let convert: boolean = false;
        // let convert: boolean = isIos;

        /*if (dt instanceof Date) {
            date = dt;
            convert = false;
        } else {
            date = this.dateFromString(dt);
        }*/

        return dateFormat(dt, 'm/d/yyyy h:MM TT', convert);
    }

    static dateForDisplayShort(dt: any): string {

        // https://www.npmjs.com/package/dateformat

        if (_.isUndefined(dt) || _.isNull(dt)) {
            return '';
        }

        // let date: Date | null;
        // looks like this is no longer required.
        // let convert: boolean = UtilService.isChrome();
        let convert: boolean = false;
        // let convert: boolean = isIos;

        /*if (dt instanceof Date) {
            date = dt;
            convert = false;
        } else {
            date = this.dateFromString(dt);
        }*/

        return dateFormat(dt, 'm/d', convert);
    }

    static weekString(day: number): string {
        switch (day) {
            case 0:
                return 'SUN';
            case 1:
                return 'MON';
            case 2:
                return 'TUE';
            case 3:
                return 'WED';
            case 4:
                return 'THU';
            case 5:
                return 'FRI';
            default:
                return 'SAT';
        }
    }

    static getDateDiff(dt1: Date, dt2: Date): number {
        return (dt1 as any) - (dt2 as any);
    }

    static indexedLeagueName(leagueName: string, teamName: string, idx: number): string {
        const parts = teamName.split(' ');
        const lastPart = parts[parts.length - 1];
        if (lastPart.match(/\[(\d)+\]/) === null) {
            return idx === 1 ? leagueName : `${leagueName} [${teamName}]`;
        }
        return `${leagueName} ${lastPart}`;
    }

    static fbEventTrack(eventName: string, data?: any): void {
        if (!_.isUndefined((window as any).fbq) && !_.isNull((window as any).fbq)) {
            (window as any).fbq('track', eventName, data || {data: 'none'});
        }
    }
}
