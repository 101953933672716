import * as React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";

import { IState, IUserState } from "../../state/state";
import { IUserLeagueView } from "../../models/interfaces";

import { oldLeagueCutOffId, UtilService  } from "../../services/utilService";

/* eslint-disable jsx-a11y/anchor-is-valid */

export interface INavMenuAuthenticatedProps { authenticationToken: string | null, userState: IUserState | null }

const userLeagueMenuMapper = (props: INavMenuAuthenticatedProps) => {
    if (!props.userState) {
        return null;
    }
    return props.userState.leagues.filter(l => l.leagueId >= oldLeagueCutOffId).map(
        (league: IUserLeagueView) =>
            <li key={`${league.leagueId}+${league.leagueMemberId}`}>
                <Link to={`/league?leagueId=${league.leagueId.toString()}&leagueMemberId=${league.leagueMemberId.toString()}`} data-toggle="collapse" data-target=".navbar-collapse">{UtilService.indexedLeagueName(league.leagueName, league.teamName, league.idx)}</Link>
            </li>
    )
}

const userLeagueMenu = (props: INavMenuAuthenticatedProps) => {
    if (!_.isObject(props.userState) || props.userState?.leagues.length === 0) {
        return null;
    } else {
        return (
            <li role="presentation" className="dropdown">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">League<span className="caret"/></a>
                <ul className="dropdown-menu">
                    {userLeagueMenuMapper(props)}
                </ul>
            </li>
        );
    }
}

const userPicksMenuMapper = (props: INavMenuAuthenticatedProps) => {
    if (!props.userState) {
        return null;
    }
    return props.userState.leagues.filter(l => l.leagueId >= oldLeagueCutOffId).map(
        (league: IUserLeagueView) =>
            <li key={`${league.leagueId}+${league.leagueMemberId}`}>
                <Link to={`/leagueSeason?leagueId=${league.leagueId.toString()}&leagueMemberId=${league.leagueMemberId.toString()}`} data-toggle="collapse" data-target=".navbar-collapse">{UtilService.indexedLeagueName(league.leagueName, league.teamName, league.idx)}</Link>
            </li>
    )
}

const userPicksMenu = (props: INavMenuAuthenticatedProps) => {
    if (!_.isObject(props.userState) || props.userState?.leagues.length === 0) {
        return null;
    } else {
        return (
            <li role="presentation" className="dropdown">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Picks<span className="caret"/></a>
                <ul className="dropdown-menu">
                    {userPicksMenuMapper(props)}
                </ul>
            </li>
        );
    }
}

const NavMenuAuthenticated = (props: INavMenuAuthenticatedProps) => {

    return (
        <ul className="nav navbar-nav">
            <li>
                <Link to="/leagues" data-toggle="collapse" data-target=".navbar-collapse">My Leagues</Link>
            </li>
            {userLeagueMenu(props)}
            {userPicksMenu(props)}
            <li>
                <Link to="/profile" data-toggle="collapse" data-target=".navbar-collapse">My Profile</Link>
            </li>
            <li>
                <Link to="/logout" data-toggle="collapse" data-target=".navbar-collapse">Logout</Link>
            </li>
        </ul>);
};

function mapStateToProps(state: IState): INavMenuAuthenticatedProps {
    return {
        authenticationToken: state ? state.authenticationToken : null,
        userState: state.userState
    };
}

export default connect(mapStateToProps)(NavMenuAuthenticated as any);
