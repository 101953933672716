import $ from "jquery";
import * as React from "react";
import _ from "lodash";

import { ILeagueWeekSummary, ILeagueWeekTeamEliminationSummary } from "../../models/interfaces";

/* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid, no-script-url */

const isSmall: boolean = $(window).width() < 600;

export interface ILeagueWeekSummaryProps {
    titleSuffix: string;    
    leagueWeekSummary: ILeagueWeekSummary | null
}

const possessionIndicator = (summary: ILeagueWeekTeamEliminationSummary) => {
    if (summary.possession) {
        if (summary.rz) {
            return (<img src="/img/football-rz.png" alt=""/>);
        } else {
            return (<img src="/img/football.png" alt=""/>);
        }
    } else {
        return null;
    }
}

const periodProgress = (summary: ILeagueWeekTeamEliminationSummary) => {

    let period: string = '';
    let progress: string = '';

    switch (summary.period) {
        case '':
        case null:
            period = '';
            break;
        case '1':
        case '2':
        case '3':
        case '4':
            period = `Q${summary.period}`;
            progress = summary.progress;
            break;
        case 'H':
            period = 'H';
            break;
        case 'F':
            period = 'F';
            break;
        case 'FO':
            period = 'F O/T';
            break;
        default:
            period = summary.period;
            break;
    }

    return progress.length > 1 ? `${period} ${progress}` : period;
}

const status = (summary: ILeagueWeekTeamEliminationSummary) => {
    if (_.isNull(summary.period)) {
        return null;
    }

    if (summary.status > 0) {
        return <span><i className="fa fa-arrow-up icon-margin-right" aria-hidden="true"/>{summary.status.toString()}</span>;
    }

    if (summary.status === 0) {
        return <span>0</span>;
    }

    return <span><i className="fa fa-arrow-down icon-margin-right" aria-hidden="true"/>{summary.status.toString().replace('-', '')}</span>;
}

const rowClass = (summary: ILeagueWeekTeamEliminationSummary) => {
    if (_.isNull(summary.period)) {
        return null;
    }

    if (summary.final) {
        return summary.winner ? "elimination-status-won" : "elimination-status-lost";
    }
    else {
        return (summary.status > 0) ? "elimination-status-winning" : "elimination-status-losing";
    }
}

const playersCell = (summary: ILeagueWeekTeamEliminationSummary): JSX.Element => {
    const className: string = isSmall ? "players-scroller-small" : "players-scroller";
    return (<td><div className={className}>{summary.players}</div></td>);
}

const summaryMapper = (props: ILeagueWeekSummaryProps): Array<JSX.Element> | null => {
    if (!props.leagueWeekSummary) {
        return null;        
    }
    if (props.leagueWeekSummary.leagueWeekTeamEliminationSummaries.length === 0) {
        return [<tr key="row1"><td colSpan={5}>No results yet! (When there are picks for games this week that are locked, they'll appear here.)</td></tr>]
    } else {
        return props.leagueWeekSummary.leagueWeekTeamEliminationSummaries.map(
            (summary: ILeagueWeekTeamEliminationSummary) =>
                <tr key={summary.teamId}>
                    <td>{summary.teamName} {possessionIndicator(summary)}</td>
                    <td>{periodProgress(summary)}</td>
                    <td className={`${rowClass(summary)} no-wrap`}>{status(summary)}</td>
                    <td>{summary.playerCount}</td>
                    {playersCell(summary)}
                </tr>
        )
    }
}

const LeagueWeekSummary = (props: ILeagueWeekSummaryProps): JSX.Element | null => {
    if (!props.leagueWeekSummary) {
        return null;
    }
    
    if (_.isUndefined(props.leagueWeekSummary.weekName) || _.isNull(props.leagueWeekSummary.weekName) || props.leagueWeekSummary.weekName.length === 0) {
        return null;
    }

    return (
        <span>
            <h2><img src="/img/new/calendar-32x32.png" className="icon32" alt=""/> {props.leagueWeekSummary.weekName} {props.titleSuffix}</h2>
            <table className="table table-bordered table-hover shadow">
                <thead>
                    <tr>
                        <th><a className="tooltip-anchor" href="javascript:" data-toggle="tooltip" data-placement="top" title="Name of the team."><i className="fa fa-bars" aria-hidden="true"/></a></th>                        
                        <th><a className="tooltip-anchor" href="javascript:" data-toggle="tooltip" data-placement="top" title="Game time tracker. 'F' mean 'final'."><i className="fa fa-clock-o" aria-hidden="true"/></a></th>
                        <th><a className="tooltip-anchor" href="javascript:" data-toggle="tooltip" data-placement="top" title="The team's game score differential."><i className="fa fa-sort" aria-hidden="true"/></a></th>
                        <th><a className="tooltip-anchor" href="javascript:" data-toggle="tooltip" data-placement="top" title="Number of league members who picked this team this week."><i className="fa fa-check-square-o" aria-hidden="true"/></a></th>
                        <th><a className="tooltip-anchor" href="javascript:" data-toggle="tooltip" data-placement="top" title="Names of the league members that picked this team this week."><i className="fa fa-user" aria-hidden="true"/></a></th>
                    </tr>
                </thead>
                <tbody>
                    {summaryMapper(props)}
                </tbody>
            </table>
        </span>);
};

export default LeagueWeekSummary;
