import * as React from "react";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router";

import { AuthorizationService, IFbLoginRequest } from "../../services/authorizationService";
import { DataService } from "../../services/dataService";
import { routeToLeagues, routeToCustom } from "../../services/routingService";

import { IState, ILoginDestinationState } from "../../state/state";
import { IAction, login, loginFailed, blockUi, setLoginDestination } from "../../actions";

/* eslint-disable jsx-a11y/scope */

export interface IFacebookLoginButtonProps {
    dispatch: (action: IAction) => void,
    loginDestination: ILoginDestinationState,
    invitationCode: string,
    loginErrorMessage: string
}

const onLoginSuccess = (navigate: NavigateFunction, props: IFacebookLoginButtonProps, token: string): void => {
    props.dispatch(blockUi(false, ""));
    props.dispatch(login(token));
    DataService.getUserState(token, true, props.dispatch);

    const destination = Object.assign({}, props.loginDestination, {});
    
    if (destination.destination) {
        props.dispatch(setLoginDestination(null));
        routeToCustom(navigate, destination.destination);
    } else {
        routeToLeagues(navigate);
    }
};

const onLoginFailure = (props: IFacebookLoginButtonProps, message: string): void => {
    props.dispatch(loginFailed(message));
    props.dispatch(blockUi(false, ""));
};

const doFbLogin = (navigate: NavigateFunction, props: IFacebookLoginButtonProps): void => {
    const opts = { scope: "public_profile,email" };
    if (!FB) {
        return;
    }
    FB.login(function (loginResponse) {
        if (loginResponse.authResponse) {
            FB.api('/me?fields=name,email,first_name,last_name', function (meResponse: any) {
                const fbLoginRequest: IFbLoginRequest = {
                    email: meResponse.email || 'no-email@test.com',
                    password: meResponse.id,
                    code: props.invitationCode,
                    facebookId: meResponse.id,
                    name: meResponse.name,
                    fName: meResponse.first_name,
                    lName: meResponse.last_name,
                    accessToken: loginResponse.authResponse.accessToken,
                    expiresIn: loginResponse.authResponse.expiresIn,
                    signedRequest: loginResponse.authResponse.signedRequest,
                    graphDomain: (loginResponse.authResponse as any).graphDomain,
                    dataAccessExpirationTime: (loginResponse.authResponse as any).data_access_expiration_time,
                    status: loginResponse.status
                };
                props.dispatch(blockUi(true, "Authenticating..."));
                AuthorizationService.login(navigate, props, fbLoginRequest, onLoginSuccess, onLoginFailure);
            });
        }
    }, opts);
};

const fbLoginButtonContainerId = "fb-login-button-container";
const fbLoginButtonDivId = "fb-login-button-div";

const finished_login_rendering = function () {
    const container = document.getElementById(fbLoginButtonContainerId);
    if (container) {
        container.removeAttribute("style");
        container.removeChild(container.childNodes[0]);
    }
}

let renderEventRegistered = false;

const registerRenderEvent = () => {
    if (renderEventRegistered) {
        return;
    }
    try {
        if (window.FB !== undefined) {
            FB.Event.subscribe('xfbml.render', finished_login_rendering);
            renderEventRegistered = true;
        }
    }
    catch (e) {
        console.info(e);
    }   
}

registerRenderEvent();


const FacebookLoginButton = (props: IFacebookLoginButtonProps) => {
    
    const navigate = useNavigate();
    
    registerRenderEvent();
    
    setTimeout(() => {
        // see if it has been rendered already...
        registerRenderEvent();
        
        const fbLoginButtonDiv = document.getElementById(fbLoginButtonDivId);
        if (!fbLoginButtonDiv) {
            return;
        }
        const childCount = fbLoginButtonDiv.childElementCount;
        if (childCount === 0) {
            // nothing rendered yet... re-render.
            if (FB) {
                FB.XFBML.parse(document.getElementById(fbLoginButtonContainerId));
            }
        }
    }, 1000);

    (window as any).onFbLoginComplete = () => {
        doFbLogin(navigate, props);
    };

    // @ts-ignore
    return (
        <div id={fbLoginButtonContainerId}
            style={{
                background: '#4267b2',
                borderRadius: '5px',
                color: 'white',
                height: '40px',
                textAlign: 'center',
                width: '250px',
                paddingTop: '10px',
                fontWeight: 'bold'
            }}>
            <div>Loading...</div>
            <div
                id={fbLoginButtonDivId}
                className="fb-login-button"
                data-size="large"
                data-button-type="login_with"
                data-layout="default"
                data-auto-logout-link="false"
                data-use-continue-as="false"
                data-width=""
                scope="public_profile,email"
                data-onlogin="onFbLoginComplete();"
            />
        </div>);
};

function mapStateToProps(state: IState) {
    return {
        loginDestination: state.loginDestination,
        invitationCode: state.invitationCode,
        loginErrorMessage: state.loginErrorMessage
    };
}

export default connect(mapStateToProps)(FacebookLoginButton as any);
