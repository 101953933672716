import * as React from "react";

export interface ILoaderGifProps {
    gif: string;
    visible: boolean;
}

const LoaderGif = (props: ILoaderGifProps) => {

    if (!props.visible) {
        return <span/>;
    }

    return (<span>        
        <i className="fa fa-refresh fa-spin" aria-hidden="true" alt=""/>
    </span>);
};

export default LoaderGif;
