import { AuthorizationService } from "./authorizationService";
import { DataService } from "./dataService";

import { IState } from "../state/state";
import { IAction } from "../actions";

export const bootstrapAuth = (callback: (initialState: IState) => void) => {

    let state: IState = {
        authenticationToken: null,
        fbLoginState: null,
        invitationCode: null,
        blockUiState: { blockedCount: 0, message: ''},
        generalErrorMessage: null,
        loginErrorMessage: null,
        loginDestination: null,
        passwordResetState: null,
        publicPageState: null,
        userState: null,
        userLeaguesState: null,
        profileUpdateState: null,
        emailVerifyState: null,
        inviteVerifyState: null,
        seasons: null,
        createLeagueState: null,
        leagueState: null,
        leagueManageState: null,
        leagueSeasonState: null,
        inviteState: null,
        leagueMemberState: null
    }

    // do we have a cookie?
    if (AuthorizationService.appearsAuthenticated()) {
        state.authenticationToken = AuthorizationService.currentCookie();
    }
    
    if (window.FB === undefined) {
        callback(state);
        return;
    }

    FB.getLoginStatus(function (response: any) {
        if (response.status === 'connected') {
            // The user is logged in and has authenticated your
            // app, and response.authResponse supplies
            // the user's ID, a valid access token, a signed
            // request, and the time the access token 
            // and signed request each expire.
            state.fbLoginState = {
                facebookId: response.authResponse.userID,
                accessToken: response.authResponse.accessToken,
                expiresIn: response.authResponse.expiresIn,
                signedRequest: response.authResponse.signedRequest,
                dataAccessExpirationTime: response.authResponse.dataAccessExpirationTime
            };
        } else if (response.status === 'not_authorized') {
            // The user hasn't authorized your application.  They
            // must click the Login button, or you must call FB.login
            // in response to a user gesture, to launch a login dialog.
        } else {
            // The user isn't logged in to Facebook. You can launch a
            // login dialog with a user gesture, but the user may have
            // to log in to Facebook before authorizing your application.
        }
        callback(state);
    });    
}

export const bootstrapUserState = (state: IState, dispatch: (action: IAction) => IAction): void => {

    // are we authorized?
    if (state.authenticationToken) {
        DataService.getUserState(state.authenticationToken, false, dispatch);
    } else if (state.fbLoginState) {
        DataService.getFbUserState(state.fbLoginState, false, dispatch);
    } else {
        DataService.getUserState('', false, dispatch);
    }
}
