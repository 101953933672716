import * as React from "react";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router";
import _ from "lodash";

import GamesList from "./GamesList";

import MessagePanel from "../../controls/MessagePanel";
import LoaderGif from "../../controls/LoaderGif";
import LeagueShareLink from "../../controls/LeagueShareLink";

import { DataService, IGamePickUpdateResponse } from "../../../services/dataService";
import { routeToLeague } from "../../../services/routingService";
import { confirm, popup } from "../../../services/notifyService";

import { IState, ILeagueSeasonState, IUserState } from "../../../state/state";
import {IAction, pickGame, hideLeagueWeekPicks, toggleGamePickDetail, setPickMargin} from "../../../actions";
import { IWeek, IGamePick, ILeagueMember } from "../../../models/interfaces";

export interface ILeagueSeasonProps {
    dispatch: (action: IAction) => void,
    authenticationToken: string,
    leagueSeasonState: ILeagueSeasonState,
    userState: IUserState
}

const onSelectGame = (props: ILeagueSeasonProps, weekId: number, gameId: number, teamId: number): void => {

    let week: IWeek | undefined = _.find(props.leagueSeasonState.weeks, w => w.weekId === weekId);
    
    if (!week){
        return;
    }
    
    let gamePick: IGamePick | undefined = _.find(week.gamePicks, gp => gp.gameId === gameId);

    if (!gamePick){
        return;
    }
    
    if (!props.leagueSeasonState.leagueMember || !props.leagueSeasonState.leagueView) {
        return;
    }
    
    if (gamePick.locked || props.leagueSeasonState.leagueMember.eliminated || props.leagueSeasonState.leagueView.complete) {
        // game is locked, 
        // or the player has been eliminated from an elimination contest,
        // or the league is complete (someone won)
        return;
    }

    // set team to pending...
    props.dispatch(pickGame(weekId, gameId, teamId));

    gamePick.currentPickTeamId = teamId;
    
    const leagueId = props.leagueSeasonState.leagueView.leagueId;
    const leagueMemberId = props.leagueSeasonState.leagueMember.leagueMemberId;

    const onUpdateGamePickComplete = (response: IGamePickUpdateResponse) => {
        if (response.success) {
            if (gamePick && gamePick.detailVisible) {
                // refresh it
                if (props.leagueSeasonState.leagueView) {
                    DataService.getGamePickDetail(
                        props.authenticationToken,
                        props.leagueSeasonState.leagueView.leagueId,
                        weekId,
                        gamePick.gameId,
                        props.dispatch);
                }
            }
            // check if another week needs refreshing.
            if (response.overwriteWeekId !== null && response.overwriteWeekId > 0) {
                // is this week visible?
                let thisWeek: IWeek | undefined = _.find(props.leagueSeasonState.weeks, w => w.weekId === response.overwriteWeekId);
                if (thisWeek) {
                    let showPicks: boolean = thisWeek.gamePicks.length > 0
                    DataService.getGamePicks(
                        props.authenticationToken,
                        leagueId,
                        leagueMemberId,
                        response.overwriteWeekId,
                        showPicks,
                        props.dispatch);
                }
            }
        } else {
            if (!response.overwritable) {
                popup("Error Making Pick", response.message, 'error');
                // refresh week picks...
                // todo: it is weird here when the details section is visible... it disappears.
                DataService.getGamePicks(
                    props.authenticationToken,
                    leagueId,
                    leagueMemberId,
                    weekId,
                    true,
                    props.dispatch);
            } else {
                confirm("Replace Pick?", response.message)
                    .then((ok: Boolean) => {
                        if (ok && gamePick) {
                            // attempt again, with replace pick option
                            DataService.updateGamePick(
                                props.authenticationToken,
                                leagueId,
                                leagueMemberId,
                                weekId,
                                true,
                                gamePick,
                                onUpdateGamePickComplete,
                                props.dispatch);
                        } else {
                            // cancel
                            DataService.getGamePicks(
                                props.authenticationToken,
                                leagueId,
                                leagueMemberId,
                                weekId,
                                true,
                                props.dispatch);
                        }
                    });
            }
        }

        if (props.leagueSeasonState.leagueRuleSet?.elimination === true) {
            DataService.getCurrentEliminationPicks(
                props.authenticationToken,
                leagueId,
                leagueMemberId,
                props.dispatch);
            if (props.leagueSeasonState.leagueRuleSet.maxWeekPicks > 1) {
                DataService.getGamePicks(
                    props.authenticationToken,
                    leagueId,
                    leagueMemberId,
                    weekId,
                    true,
                    props.dispatch);
            }
        }
    }

    DataService.updateGamePick(
        props.authenticationToken,
        props.leagueSeasonState.leagueView.leagueId,
        props.leagueSeasonState.leagueMember.leagueMemberId,
        week.weekId,
        false,
        gamePick,
        onUpdateGamePickComplete,
        props.dispatch);
};

const onSelectGameMarginInProgress = (props: ILeagueSeasonProps, weekId: number, gameId: number, margin: number): void => {

    props.dispatch(setPickMargin(weekId, gameId, margin))
};

const onSelectGameMargin = (props: ILeagueSeasonProps, weekId: number, gameId: number, margin: number): void => {

    let week: IWeek | undefined = _.find(props.leagueSeasonState.weeks, w => w.weekId === weekId);

    if (!week){
        return;
    }

    let gamePick: IGamePick | undefined = _.find(week.gamePicks, gp => gp.gameId === gameId);

    if (!gamePick){
        return;
    }

    if (!props.leagueSeasonState.leagueMember || !props.leagueSeasonState.leagueView) {
        return;
    }

    if (gamePick.locked || props.leagueSeasonState.leagueMember.eliminated || props.leagueSeasonState.leagueView.complete) {
        // game is locked, 
        // or the player has been eliminated from an elimination contest,
        // or the league is complete (someone won)
        return;
    }

    gamePick.margin = margin;

    const leagueId = props.leagueSeasonState.leagueView.leagueId;
    const leagueMemberId = props.leagueSeasonState.leagueMember.leagueMemberId;

    const onUpdateGamePickComplete = (response: IGamePickUpdateResponse) => {
        if (response.success) {
            if (gamePick && gamePick.detailVisible) {
                // refresh it
                if (props.leagueSeasonState.leagueView) {
                    DataService.getGamePickDetail(
                        props.authenticationToken,
                        props.leagueSeasonState.leagueView.leagueId,
                        weekId,
                        gamePick.gameId,
                        props.dispatch);
                }
            }
            // check if another week needs refreshing.
            if (response.overwriteWeekId !== null && response.overwriteWeekId > 0) {
                // is this week visible?
                let thisWeek: IWeek | undefined = _.find(props.leagueSeasonState.weeks, w => w.weekId === response.overwriteWeekId);
                if (thisWeek) {
                    let showPicks: boolean = thisWeek.gamePicks.length > 0
                    DataService.getGamePicks(
                        props.authenticationToken,
                        leagueId,
                        leagueMemberId,
                        response.overwriteWeekId,
                        showPicks,
                        props.dispatch);
                }
            }
        } else {
            if (!response.overwritable) {
                popup("Error Making Pick", response.message, 'error');
                // refresh week picks...
                // todo: it is weird here when the details section is visible... it disappears.
                DataService.getGamePicks(
                    props.authenticationToken,
                    leagueId,
                    leagueMemberId,
                    weekId,
                    true,
                    props.dispatch);
            } else {
                confirm("Replace Pick?", response.message)
                    .then((ok: Boolean) => {
                        if (ok && gamePick) {
                            // attempt again, with replace pick option
                            DataService.updateGamePick(
                                props.authenticationToken,
                                leagueId,
                                leagueMemberId,
                                weekId,
                                true,
                                gamePick,
                                onUpdateGamePickComplete,
                                props.dispatch);
                        } else {
                            // cancel
                            DataService.getGamePicks(
                                props.authenticationToken,
                                leagueId,
                                leagueMemberId,
                                weekId,
                                true,
                                props.dispatch);
                        }
                    });
            }
        }

        if (props.leagueSeasonState.leagueRuleSet?.elimination === true) {
            DataService.getCurrentEliminationPicks(
                props.authenticationToken,
                leagueId,
                leagueMemberId,
                props.dispatch);
            if (props.leagueSeasonState.leagueRuleSet.maxWeekPicks > 1) {
                DataService.getGamePicks(
                    props.authenticationToken,
                    leagueId,
                    leagueMemberId,
                    weekId,
                    true,
                    props.dispatch);
            }
        }
    }

    DataService.updateGamePick(
        props.authenticationToken,
        props.leagueSeasonState.leagueView.leagueId,
        props.leagueSeasonState.leagueMember.leagueMemberId,
        week.weekId,
        false,
        gamePick,
        onUpdateGamePickComplete,
        props.dispatch);
};

const onClickWeek = (props: ILeagueSeasonProps, weekId: number): void => {

    let week = _.find(props.leagueSeasonState.weeks, (w: IWeek) => w.weekId === weekId);
    
    if (!week) {
        return;
    }

    if (week.gamePicks.length === 0) {
        if (props.leagueSeasonState.leagueView && props.leagueSeasonState.leagueMember) {
            // show week picks
            DataService.getGamePicks(
                props.authenticationToken,
                props.leagueSeasonState.leagueView.leagueId,
                props.leagueSeasonState.leagueMember.leagueMemberId,
                weekId,
                true,
                props.dispatch);
        }
    } else {
        // hide week picks
        props.dispatch(hideLeagueWeekPicks(weekId));
    }
}

const backToLeagueButton = (navigate: NavigateFunction, props: ILeagueSeasonProps): JSX.Element => {
    return (
        <button
            type="submit"
            className="btn btn-pickem-blue"
            onClick={() => {
                if (props.leagueSeasonState.leagueView && props.leagueSeasonState.leagueMember) {
                    routeToLeague(navigate, props.leagueSeasonState.leagueView.leagueId, props.leagueSeasonState.leagueMember.leagueMemberId);
                }
            } }
            >
            <span className="glyphicon glyphicon-triangle-left" aria-hidden="true"/>
            &nbsp;
            Back To League
        </button>
    );
}

const leagueRules = (props: ILeagueSeasonProps): JSX.Element | null => {
    if (!props.leagueSeasonState.leagueRuleSet || !props.leagueSeasonState.leagueView) {
        return null;
    }
    if (props.leagueSeasonState.leagueRuleSet.elimination) {
        if (props.leagueSeasonState.leagueRuleSet.maxWeekPicks === 1) {
            return (<span>
                <h4>League Rules</h4>
                <p>This is an elimination league for the {props.leagueSeasonState.leagueView.seasonName}.</p>
                <p>Pick one team per week. The team must win straight up (i.e. the spread does not matter).</p>
                <p>If that team wins, you move on to the next week. If that team loses or ties, you are eliminated.</p>
                <p>The same team cannot be picked more than once.</p>
                <p>Each Thursday game locks 5 minutes before the official game time. To pick a Thursday team, pick the team before the game locks.</p>
                <p>All other games (Saturday, Sunday, or Monday) lock 5 minutes before the first game starts. If you do not pick a team by this deadline, you are assigned the most even team.</p>                
            </span>);
        } else if (props.leagueSeasonState.leagueRuleSet.maxWeekPicks === 2) {
            return (<span>
                <h4>League Rules</h4>
                <p>This is a double elimination league for the {props.leagueSeasonState.leagueView.seasonName}.</p>
                <p>Pick two teams per week. Both teams must win straight up (i.e. the spread does not matter).</p>
                <p>If both teams wins, you move on to the next week. If either team loses or ties, you are eliminated.</p>
                <p>The same team cannot be picked more than once.</p>
                <p>Each Thursday game locks 5 minutes before the official game time. To pick a Thursday team, pick the team before the game locks.</p>
                <p>All other games (Saturday, Sunday, or Monday) lock 5 minutes before the first game starts. If you do not pick both teams by this deadline, you are assigned the most even team or teams.</p>                
            </span>);
        }
    }
    if (props.leagueSeasonState.leagueRuleSet.vsSpread) {
        return (<span>
        <h4>League Rules</h4>
            <p>This is a Pick'Em league for the {props.leagueSeasonState.leagueView.seasonName}.</p>
            <p>Pick a winner in every game, for every week. Each team must win vs the spread.</p>
            <p>Spreads are not locked in when a pick is made. The spread used is the game time spread.</p>
            <p>When a picked team wins (w/ spread), you receive a point.</p>
            <p>When a picked team loses (w/ spread), you receive no point.</p>
            <p>Each game is locked 5 minutes before the official game time. If no pick is made by this time, no points can be earned.</p>
            <p>Ties are broken by AVM.</p>
        </span>);    
    }
    if (props.leagueSeasonState.leagueRuleSet.leagueRuleSetId === 5) {
        return (<span>
        <h4>League Rules</h4>
            <p>This is a Pick'Em league for the {props.leagueSeasonState.leagueView.seasonName}.</p>
            <p>Pick a winner in every game, for every week. Each team must win straight up (i.e. the spread does not matter).</p>
            <p>In addition to picking the winner, optionally pick a margin greater than 1 (or leave the margin at 1).</p>
            <p>When a picked team wins, you receive six points.</p>
            <p>When a picked team wins by an amount equal to or greater than your margin, you get the margin points also.</p>
            <p>When a picked team loses, you receive nothing.</p>
            <p>Each game is locked 5 minutes before the official game time. If no pick is made by this time, no points can be earned.</p>
            <p>Ties are broken by AVM.</p>
        </span>);
    }
    return (<span>
        <h4>League Rules</h4>
            <p>This is a Pick'Em league for the {props.leagueSeasonState.leagueView.seasonName}.</p>
            <p>Pick a winner in every game, for every week. Each team must win straight up (i.e. the spread does not matter).</p>
            <p>When a picked team wins, you receive a point.</p>
            <p>When a picked team loses, you receive no point.</p>
            <p>Each game is locked 5 minutes before the official game time. If no pick is made by this time, no points can be earned.</p>
            <p>Ties are broken by AVM.</p>
        </span>);
}

const userIsLeagueWinner = (props: ILeagueSeasonProps): boolean => {
    if (props.leagueSeasonState.leagueView?.complete === false) {
        return false;
    }
    const userId = props.userState.userId;
    const leagueMember: ILeagueMember | undefined = _.find(props.leagueSeasonState.leagueMembers, lm => lm.userId === userId);
    if (!_.isObject(leagueMember)) {
        return false;
    }
    // @ts-ignore
    return leagueMember.winner;
}

const completionMessage = (props: ILeagueSeasonProps): JSX.Element | null => {
    if (userIsLeagueWinner(props)) {
        return (<span>
            <h4>You Won!</h4>
            <div className="alert alert-success" role="alert">
                Excellent job. You have crushed the competition, and taken your place in history. You may feel free to request that all others bow down!
                </div>
        </span>);
    } else if (props.leagueSeasonState.leagueRuleSet?.elimination && props.leagueSeasonState.leagueMember?.eliminated === true) {
        return (<span>
            <h4>You Have Been Eliminated</h4>
            <div className="alert alert-danger" role="alert">
                Unfortunately you have been eliminated. You cannot make further picks.
                </div>
        </span>);
    } else {
        return null;
    }
}

const LeagueSeason = (props: ILeagueSeasonProps) => {

    const navigate = useNavigate();

    if (!_.isObject(props.leagueSeasonState)) {
        // seemingly getting here with no state...
        // returning a blank template until the state arrives fixes this.
        return <div/>;
    }

    if (props.leagueSeasonState.initErrorMessage && props.leagueSeasonState.initErrorMessage.length > 0) {
        return (
            <div>
                <h2 className="tight-panel">League Season</h2>
                <div className="content-panel">
                    <MessagePanel className="bg-danger" message={props.leagueSeasonState.initErrorMessage} />
                </div>
            </div>
        );
    }

    // todo: look at moving outside of render.
    const onToggleGameDetail = (weekId: number, gameId: number): void => {

        let week: IWeek | undefined = _.find(props.leagueSeasonState.weeks, w => w.weekId === weekId);
        
        if (!week) {
            return;
        }
            
        let gamePick: IGamePick | undefined = _.find(week.gamePicks, gp => gp.gameId === gameId);

        if (!gamePick) {
            return;
        }

        if (!gamePick.detailVisible) {
            // load it up
            if (props.leagueSeasonState.leagueView) {
                DataService.getGamePickDetail(
                    props.authenticationToken,
                    props.leagueSeasonState.leagueView.leagueId,
                    week.weekId,
                    gamePick.gameId,
                    props.dispatch);
            }
        }

        props.dispatch(toggleGamePickDetail(weekId, gameId));
    }


    const weekMapper = props.leagueSeasonState.weeks.map(
        (week: IWeek): JSX.Element | null => {
            
            if (!props.leagueSeasonState.leagueRuleSet || !props.leagueSeasonState.leagueView || !props.leagueSeasonState.leagueMemberCurrentWeekPicksDue) {
                return null;
            }

            const minWeekPicks = props.leagueSeasonState.leagueRuleSet.minWeekPicks;

            const className = week.pickCount === minWeekPicks ? "fa fa-check-square-o" : "fa fa-square-o";

            const loader: JSX.Element = (<div className="float-right"><LoaderGif gif='squares' visible={week.loading} /></div>);

            const pickDisplay: string = !_.isUndefined(week.elimPickName) && week.elimPickName !== null && week.elimPickName.length > 0 ? `[${week.elimPickName}] ` : "";

            const header: JSX.Element = props.leagueSeasonState.leagueView.leagueRuleSetId === 3 || props.leagueSeasonState.leagueView.leagueRuleSetId === 4
                ? <div><i className={className} aria-hidden="true"/>&nbsp;{week.name}&nbsp;{pickDisplay}{week.points === minWeekPicks ? 'Survived!' : props.leagueSeasonState.leagueMember?.eliminated && week.pickCount > 0 ? 'Eliminated.' : ''}{loader}</div>
                : <div>{week.name}&nbsp;({week.pickCount.toString()}/{Math.min(week.gameCount, props.leagueSeasonState.leagueRuleSet.maxWeekPicks).toString()}) Points: {week.points}{loader}</div>;

            return (
                <div key={`weeksList${week.weekId}`}>
                    <div
                        className="league-box blue-box big-text"
                        onClick={() => onClickWeek(props, week.weekId)}
                        >{header}                        
                    </div>
                    <GamesList
                        league={props.leagueSeasonState.leagueView}
                        gamePicks={week.gamePicks}
                        currentWeekId={props.leagueSeasonState.leagueMemberCurrentWeekPicksDue.weekId}
                        currentEliminationPicks={props.leagueSeasonState.currentEliminationPicks}
                        toggleGameDetail={onToggleGameDetail}
                        updateGameSelection={(weekId: number, gameId: number, teamId: number) => onSelectGame(props, weekId, gameId, teamId)}
                        updateMarginSelection={(weekId: number, gameId: number, margin: number) => onSelectGameMargin(props, weekId, gameId, margin)}
                        updateMarginSelectionInProgress={(weekId: number, gameId: number, margin: number) => onSelectGameMarginInProgress(props, weekId, gameId, margin)}
                        />
                </div>
            );
        }
    );

    return (
        <div>
            <h2 className="tight-panel"><img src="/img/new/leagues-32x32.png" className="icon32" alt=""/> {props.leagueSeasonState.leagueView?.leagueName} - {props.leagueSeasonState.leagueMember?.teamName}</h2>
            <div className="content-panel">
                {leagueRules(props)}
                {completionMessage(props)}
                {backToLeagueButton(navigate, props)}&nbsp;&nbsp;
                {props.leagueSeasonState.leagueView?.leagueId === 646000 && <LeagueShareLink top="0px" altImage={true} guid="035b8bf8-34ab-468c-af0a-3b26af0bba9d" message="Pick'Em Leagues - 2022 Free Elimination League (Win $250)" />}
                {props.leagueSeasonState.leagueView?.leagueId === 646000 && <LeagueShareLink top="0px" altImage={false} guid="035b8bf8-34ab-468c-af0a-3b26af0bba9d" message="Pick'Em Leagues - 2022 Free Elimination League (Win $250)" />}
                {props.leagueSeasonState.leagueView?.leagueId === 647000 && <LeagueShareLink top="0px" altImage={true} guid="8b5e2c32-c842-490b-b91b-24fb623da438" message="Pick'Em Leagues - 2022 Free Pick'Em League (Win $250)" />}
                {props.leagueSeasonState.leagueView?.leagueId === 647000 && <LeagueShareLink top="0px" altImage={false} guid="8b5e2c32-c842-490b-b91b-24fb623da438" message="Pick'Em Leagues - 2022 Free Pick'Em League (Win $250)" />}
            </div>
            {weekMapper}
            {backToLeagueButton(navigate, props)}
        </div>
    );
}

function mapStateToProps(state: IState) {
    return {
        leagueSeasonState: state.leagueSeasonState,
        userState: state.userState,
        authenticationToken: state.authenticationToken
    };
}

export default connect(mapStateToProps)(LeagueSeason as any);
