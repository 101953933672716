import * as React from "react";

import ResponsiveImage from "../controls/ResponsiveImage";

const HowItWorksElimination = () => {

    return (<div>
        <h3 className="tight-panel"><img src="/img/new/picks-32x32.png" className="icon32" alt=""/> Make Your Picks (Elimination)</h3>
        <div className="content-panel">
            <p>For each Elimination league, make ONE pick per week. Previously picked teams are grayed out. If no pick is made before the first non-Thursday game locks, you are assigned the default pick for that week.</p>
            <ResponsiveImage fileName="elimination-pick.png" />
            <p>The only season available right now is NFL 2024. You pick ONE game, every week, straight up.</p>
            <p>You stay in the league if your pick is right - otherwise - ELIMINATION!</p>
        </div>

        <h3 className="tight-panel"><img src="/img/new/view-32x32.png" className="icon32" alt=""/> View the Results (Elimination)</h3>
        <div className="content-panel">
            <p>Results are populated in real time. (OK, it's delayed a minute or so...)</p>
            <ResponsiveImage fileName="elimination-results.png" />
        </div>

        <h3 className="tight-panel"><img src="/img/new/check-list-32x32.png" className="icon32" alt=""/> Check the Standings (Elimination)</h3>
        <div className="content-panel">
            <p>Check out those standing and behold the glory. You survived! Or not.</p>
            <ResponsiveImage fileName="elimination-standings.png" />
        </div>
    </div>);
};

export default HowItWorksElimination;
