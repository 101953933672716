import _ from "lodash";
import $ from "jquery";
import * as React from "react";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router";

import LeagueTable from "../../widgets/LeagueTable";

import { DataService } from "../../../services/dataService";
import { UtilService } from "../../../services/utilService";
import { routeToLeague } from "../../../services/routingService";
import { popup, confirm, prompt, promptWithContent, IPrompt } from "../../../services/notifyService";

import { IState, ILeagueManageState } from "../../../state/state";
import { IAction } from "../../../actions";
import { ILeagueMemberView, ILeagueView, IUpdateLeague, IAjaxResponse } from "../../../models/interfaces";

/* eslint-disable jsx-a11y/anchor-has-content */

export interface ILeagueManageProps {
    dispatch: (action: IAction) => void,
    authenticationToken: string,
    leagueManageState: ILeagueManageState
}

const refreshData = (props: ILeagueManageProps) => {
    if (props.leagueManageState.leagueView) {
        DataService.getLeagueManageState(
            props.authenticationToken,
            props.leagueManageState.leagueView.leagueId,
            props.dispatch);
    }
}

const backToLeagueButton = (navigate: NavigateFunction, props: ILeagueManageProps): JSX.Element => {    
    return (
        <button
            type="submit"
            className="btn btn-pickem-blue"
            onClick={() => {
                if (props.leagueManageState.leagueView) {
                    routeToLeague(navigate, props.leagueManageState.leagueView.leagueId, 0);
                }
            } }
            >
            <span className="glyphicon glyphicon-triangle-left" aria-hidden="true"/>
            &nbsp;
            Back To League
        </button>
    );
}

const deleteLeagueMember = (leagueMember: ILeagueMemberView, props: ILeagueManageProps): void => {
    const leagueMemberId = leagueMember.leagueMemberId;
    const teamName = leagueMember.teamName;
    confirm(`Confirm Remove ${teamName}`, `Are you sure you want to remove "${teamName}" from the league? This cannot be undone.`)
        .then((ok: Boolean) => {
            if (ok) {
                DataService.deleteLeagueMember(
                    props.authenticationToken,
                    leagueMemberId,
                    props.dispatch).then((result: IAjaxResponse) => {
                        if (result.success) {
                            popup(`${teamName} Removed`, `"${teamName}" was removed successfully.`, "info");           
                            refreshData(props);
                        } else {
                            popup(`Error Removing ${teamName}`, `Unable to remove "${teamName}". ${result.message}`, "error");
                        }
                    });
            }
        });
}

const deleteButton = (leagueMember: ILeagueMemberView, props: ILeagueManageProps) => {
    if (leagueMember.deleted) {
        return null;
    }
    return (
        <button
            type="submit"
            className="btn btn-sm btn-pickem-blue button-margin"
            onClick={() => { deleteLeagueMember(leagueMember, props) } }
            >
            <i className="fa fa-trash" aria-hidden="true"/>
        </button>
    );
}

const addEntries = (leagueMember: ILeagueMemberView, props: ILeagueManageProps): void => {
    const leagueMemberId = leagueMember.leagueMemberId;
    const teamName = leagueMember.teamName;

    prompt(`Confirm Add Entries`, `Add entries for "${teamName}"? From 1 to 10 entries can be added at a time.`, 1, 'number')
        .then((result: IPrompt) => {
            if (result.ok) {
                const addCount = parseInt(result.value, 10);
                if (_.isNaN(addCount) || addCount < 1 || addCount > 10) {
                    popup('Error Adding Entries', 'From 1 to 10 entries can be added at a time.', "error");
                    return;
                }
                const word = addCount === 1 ? 'Entry' : 'Entries';
                DataService.addUserLeagueEntries(
                    props.authenticationToken,
                    leagueMemberId,
                    addCount,
                    props.dispatch).then((result: IAjaxResponse) => {
                        if (result.success) {
                            const word2 = addCount === 1 ? 'was' : 'were';
                            popup(`${word} Added`, `${word} ${word2} added successfully.`, "info");
                            refreshData(props);
                        } else {
                            popup(`Error Adding ${word}`, `Unable to add ${word.toLowerCase()}. ${result.message}`, "error");
                        }
                    });
            }
        });
}

const addButton = (leagueMember: ILeagueMemberView, props: ILeagueManageProps) => {
    if (!props.leagueManageState.leagueView) {
        return null;
    }
    if (leagueMember.deleted || props.leagueManageState.leagueView.locked || props.leagueManageState.leagueView.deleted) {
        return null;
    }
    return (
        <button
            type="submit"
            className="btn btn-sm btn-pickem-blue button-margin"
            onClick={() => { addEntries(leagueMember, props) } }
            >
            <i className="fa fa-plus" aria-hidden="true"/>
        </button>
    );
}

const doRebuy = (leagueMember: ILeagueMemberView, props: ILeagueManageProps): void => {
    const leagueMemberId = leagueMember.leagueMemberId;
    const teamName = leagueMember.teamName;

    confirm(`Confirm Add Rebuy`, `Add rebuy for "${teamName}"?`)
        .then((result: Boolean) => {
            if (result) {
                DataService.addUserLeagueRebuy(
                    props.authenticationToken,
                    leagueMemberId,
                    props.dispatch).then((result: IAjaxResponse) => {
                        if (result.success) {
                            popup('Rebuy Added', 'Rebuy added successfully.', "info");
                            refreshData(props);
                        } else {
                            popup('Error Adding Rebuy', `Unable to add rebuy. ${result.message}`, "error");
                        }
                    });
            }
        });
}

const rebuyButton = (leagueMember: ILeagueMemberView, props: ILeagueManageProps) => {
    if (!leagueMember.canRebuy) {
        return null;
    }
    return (
        <button
            type="submit"
            className="btn btn-sm btn-pickem-blue button-margin"
            onClick={() => { doRebuy(leagueMember, props) } }
            >
            <i className="fa fa-usd" aria-hidden="true"/>
        </button>
    );
}

const editTeamName = (leagueMember: ILeagueMemberView, props: ILeagueManageProps): void => {

    prompt(`Update Team Name`, `Enter the new team name:`, leagueMember.teamName, "text")
        .then((result: IPrompt) => {
            if (result.ok) {
                const teamName = result.value;
                if (teamName.length < 2 || teamName.length > 20) {
                    popup('Error Changing Name', 'Team name must be from 2 to 20 characters.', "error");
                    return;
                }
                DataService.updateTeamName(
                    props.authenticationToken,
                    leagueMember.leagueMemberId,
                    teamName,
                    props.dispatch).then((result: IAjaxResponse) => {
                        if (result.success) {
                            popup('Team Name Updated', 'Team name updated successfully.', "info");
                            refreshData(props);
                        } else {
                            popup('Error Updating Team Name', `Unable to update team name. ${result.message}`, "error");
                        }
                    });
            }
        });
}

const editTeamNameButton = (leagueMember: ILeagueMemberView, props: ILeagueManageProps) => {
    if (leagueMember.userId !== props.leagueManageState.leagueView?.userId) {
        // league manager can only rename teams for themselves.
        return null;
    }
    return (
        <button
            type="submit"
            className="btn btn-sm btn-pickem-blue button-margin"
            onClick={() => { editTeamName(leagueMember, props) } }
            >
            <i className="fa fa-pencil" aria-hidden="true"/>
        </button>
    );
}

const userMapperLarge = (props: ILeagueManageProps): Array<JSX.Element> => {
    if (!props.leagueManageState.leagueMembers) {
        return [];
    }
    if (props.leagueManageState.leagueMembers.length === 0) {
        return [<tr key="row1"><td colSpan={6}>No teams. Invite some members!</td></tr>]
    } else {        
        let elimClass = (eliminated: boolean) => {
            return eliminated ? "eliminated" : "";
        };

        return props.leagueManageState.leagueMembers.filter(lm => !lm.deleted).map(
            (leagueMember: ILeagueMemberView, index: number) => {
                const picksDue = leagueMember.picksDue - leagueMember.picksMade;
                let picksColumn: JSX.Element = (<td>{picksDue.toString()}</td>);
                if (props.leagueManageState.leagueView?.leagueRuleSetId === 3) {
                    const className = picksDue === 0 ? "fa fa-check-square-o" : "fa fa-square-o";
                    picksColumn = (<td><i className={className} aria-hidden="true"/></td>);
                }
                return (
                    <tr key={leagueMember.leagueMemberId}>
                        <td>
                            {deleteButton(leagueMember, props)} {addButton(leagueMember, props)} {rebuyButton(leagueMember, props)} {editTeamNameButton(leagueMember, props)}
                        </td>
                        <td>{index + 1}</td>
                        <td>{leagueMember.userName}</td>
                        <td className={elimClass(leagueMember.eliminated)}>
                            <a href={'#/leagueMember?leagueMemberId=' + leagueMember.leagueMemberId.toString()}><i className="fa fa-search" aria-hidden="true"/></a>
                            &nbsp;
                            {leagueMember.teamName}
                        </td>
                        <td>{leagueMember.rebuy === 2 ? 'R' : leagueMember.rebuy === 1 ? "*" : ""}</td>
                        <td>{UtilService.dateForDisplay(leagueMember.joined)}</td>
                        <td><a href={`mailto:${leagueMember.email}`}>{leagueMember.email}</a></td>
                        <td>{leagueMember.verified ? 'Yes' : 'No'}</td>
                        <td>{leagueMember.timeZone}</td>
                        {picksColumn}
                    </tr>
                );
            }
        )
    }
}

const userMapperSmall = (props: ILeagueManageProps): Array<JSX.Element> => {
    if (!props.leagueManageState.leagueMembers) {
        return [];
    }
    if (props.leagueManageState.leagueMembers.length === 0) {
        return [<div>No teams. Invite some members!</div>]
    } else {
        let elimClass = (eliminated: boolean) => {
            return eliminated ? "eliminated" : "";
        };

        return props.leagueManageState.leagueMembers.filter(lm => !lm.deleted).map(
            (leagueMember: ILeagueMemberView, index: number) => {
                const picksDue = leagueMember.picksDue - leagueMember.picksMade;
                let picksColumn: JSX.Element = (<td>Picks Due: {picksDue.toString()}</td>);
                if (props.leagueManageState.leagueView?.leagueRuleSetId === 3) {
                    const className = picksDue === 0 ? "fa fa-check-square-o" : "fa fa-square-o";
                    picksColumn = (<p>Picked? <i className={className} aria-hidden="true"/></p>);
                }
                return (
                    <div key={leagueMember.leagueMemberId} className="row">
                        <div key={leagueMember.leagueMemberId} className="col col-12 content-panel">
                            <p><b>#{index + 1} {leagueMember.userName}</b></p>
                            <p>
                                {deleteButton(leagueMember, props)} {addButton(leagueMember, props)} {rebuyButton(leagueMember, props)} {editTeamNameButton(leagueMember, props)}
                            </p>                        
                            <p className={elimClass(leagueMember.eliminated)}>
                                <a href={'#/leagueMember?leagueMemberId=' + leagueMember.leagueMemberId.toString()}><i className="fa fa-search" aria-hidden="true"/></a>
                                &nbsp;
                                {leagueMember.teamName}
                            </p>
                            <p>{leagueMember.rebuy === 2 ? 'Rebuy: yes' : leagueMember.rebuy === 1 ? "Rebuy: *" : ""}</p>
                            <p>Joined: {UtilService.dateForDisplay(leagueMember.joined)}</p>
                            <p><a href={`mailto:${leagueMember.email}`}>{leagueMember.email}</a></p>
                            <p>Verified: {leagueMember.verified ? 'Yes' : 'No'}</p>
                            <p>Time Zone: {leagueMember.timeZone}</p>
                            {picksColumn}
                        </div>
                    </div>
                );
            }
        )
    }
}

const editLeagueName = (props: ILeagueManageProps): void => {
    const leagueView: ILeagueView | null = props.leagueManageState.leagueView;
    
    if (!leagueView) {
        return;
    }
    
    prompt(`Update League Name`, `Enter the new league name:`, leagueView.leagueName, "text")
        .then((result: IPrompt) => {
            if (result.ok) {
                const leagueName = result.value;
                if (leagueName.length < 2 || leagueName.length > 50) {
                    popup('Error Changing Name', 'League name must be from 2 to 50 characters.', "error");
                    return;
                }                
                const league: IUpdateLeague = {
                    leagueId: leagueView.leagueId,
                    name: leagueName,
                    description: leagueView.description,
                };
                DataService.updateLeague(
                    props.authenticationToken,
                    league,                    
                    props.dispatch).then((result: IAjaxResponse) => {
                        if (result.success) {                            
                            popup('League Updated', 'League updated successfully.', "info");
                            refreshData(props);
                        } else {
                            popup('Error Updating League', `Unable to update league. ${result.message}`, "error");
                        }
                    });
            }
        });
}

const editLeagueDescription = (props: ILeagueManageProps): void => {
    const leagueView: ILeagueView | null = props.leagueManageState.leagueView;

    if (!leagueView) {
        return;
    }

    const id = "league-description-id";
    const content: string = `<div class="ajs-content"><p>Enter the new league description:</p><textArea id="${id}" rows="5" cols="70">${leagueView.description}</textArea></div>`;
    
    promptWithContent(`Update League Description`, `Enter the new league description:`, leagueView.description, "text", content, id)
        .then((result: IPrompt) => {
            if (result.ok) {
                const description = result.value;
                if (description.length > 1000) {
                    popup('Error Changing Name', 'League description cannot exceed 1,000 characters.', "error");
                    return;
                }
                const league: IUpdateLeague = {
                    leagueId: leagueView.leagueId,
                    name: leagueView.leagueName,
                    description: description,
                };
                DataService.updateLeague(
                    props.authenticationToken,
                    league,
                    props.dispatch).then((result: IAjaxResponse) => {
                        if (result.success) {
                            popup('League Updated', 'League updated successfully.', "info");
                            refreshData(props);
                        } else {
                            popup('Error Updating League', `Unable to update league. ${result.message}`, "error");
                        }
                    });
            }
        });
}

const editLeagueNameButton = (props: ILeagueManageProps) => {
    return (
        <button
            type="submit"
            className="btn btn-sm btn-pickem-blue button-margin"
            onClick={() => { editLeagueName(props) } }
            >
            <i className="fa fa-pencil" aria-hidden="true"/>
        </button>
    );
}

const editLeagueDescriptionButton = (props: ILeagueManageProps) => {
    return (
        <button
            type="submit"
            className="btn btn-sm btn-pickem-blue button-margin"
            onClick={() => { editLeagueDescription(props) } }
            >
            <i className="fa fa-pencil" aria-hidden="true"/>
        </button>
    );
}

const largeLayout = (props: ILeagueManageProps): JSX.Element => {
    return (
        <table className="table table-bordered table-hover shadow">
            <thead>
                <tr>
                    <th>Actions</th>
                    <th>#</th>
                    <th>Member</th>
                    <th>Team Name</th>
                    <th>Rebuy</th>
                    <th>Joined</th>
                    <th>Email</th>
                    <th>Verified</th>
                    <th>Time Zone</th>
                    <th><i className="fa fa-check-square-o" aria-hidden="true"/></th>
                </tr>
            </thead>
            <tbody>
                {userMapperLarge(props)}
            </tbody>
        </table>
    );
}

const smallLayout = (props: ILeagueManageProps): JSX.Element => {
    return (
        <div className="container">{userMapperSmall(props)}</div>
    );
}

const LeagueManage = (props: ILeagueManageProps) => {
    const navigate = useNavigate();
    
    if (!_.isObject(props.leagueManageState)) {
        // seemingly getting here with no leagueManageState...
        // returning a blank template until the state arrives fixes this.
        return <div/>;
    }

    if (!props.leagueManageState.leagueMembers || !props.leagueManageState.leagueView) {
        return <div/>;
    }

    const notDeleted = props.leagueManageState.leagueMembers.filter(lm => !lm.deleted);
    const userCount = _.uniq(notDeleted.map(m => m.userId)).length;

    const deletedCount = props.leagueManageState.leagueMembers.filter(lm => lm.deleted).length;
    const memberCount = props.leagueManageState.leagueMembers.length - deletedCount;

    const aliveCount = props.leagueManageState.leagueMembers.filter(lm => !lm.deleted && !lm.eliminated).length;
    const eliminatedCount = memberCount - aliveCount;
    const pickedCount = props.leagueManageState.leagueMembers.filter(lm => !lm.deleted && !lm.eliminated && (lm.picksDue - lm.picksMade === 0)).length;
    const notPickedCount = aliveCount - pickedCount;

    const multipleEntriesMessage = memberCount === userCount ? null : (<span>Some users have more than one entry, bringing the count of league members up to <b>{memberCount}</b>.</span>);

    const aliveEntriesMessage: JSX.Element | null =
        props.leagueManageState.leagueView.leagueRuleSetId !== 3
            ? null
            : aliveCount === 1
                ? <span>Only one player is left alive!</span>
                : <span><b>{aliveCount}</b> league members are still alive.</span>;

    const aPick: string = props.leagueManageState.leagueView.leagueRuleSetId === 3 ? "a pick" : "picks";
    const notPickedCountMessage: JSX.Element | null = aliveCount <= 1
        ? null
        : notPickedCount === 0
            ? <span>Everyone has picked for this week.</span>
            : notPickedCount === 0
                ? <span>One league member has not made {aPick} for this week.</span>
                : <span><b>{notPickedCount}</b> members have not made {aPick} this week.</span>;

    const eliminatedMessage = eliminatedCount > 0 ? <span>&nbsp;Eliminated players are highlighted in red [<span className="eliminated">&nbsp;&nbsp;&nbsp;&nbsp;</span>].</span> : null;

    const userUi: JSX.Element = $(window).width() > 450 ? largeLayout(props) : smallLayout(props);

    return (
        <div>
            <h2 className="tight-panel">
                <img src="/img/new/leagues-32x32.png" className="icon32" alt=""/> Manage {props.leagueManageState.leagueView.leagueName}
            </h2>
            <div className="content-panel">
                <LeagueTable leagueView={props.leagueManageState.leagueView} leagueRuleSet={props.leagueManageState.leagueRuleSet} />
                {backToLeagueButton(navigate, props)}
            </div>

            <h2 className="tight-panel"><img src="/img/new/check-list-32x32.png" className="icon32" alt=""/> Name & Description</h2>
            <div className="content-panel">
                <table className="table table-bordered table-hover shadow">
                    <tbody>
                        <tr>
                            <td style={{width: '50px'}}>{editLeagueNameButton(props)}</td>
                            <td style={{ width: '50px' }}>Name</td>
                            <td>{props.leagueManageState.leagueView.leagueName}</td>
                        </tr>
                        <tr>
                            <td style={{ width: '50px' }}>{editLeagueDescriptionButton(props)}</td>
                            <td style={{ width: '50px' }}>Description</td>
                            <td>{props.leagueManageState.leagueView.description}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <h2 className="tight-panel"><img src="/img/new/check-list-32x32.png" className="icon32" alt=""/> League Members</h2>
            <div className="content-panel">
                <p>The number of distinct users is <b>{userCount}</b>.&nbsp;{multipleEntriesMessage}</p>
                <p>{aliveEntriesMessage} {notPickedCountMessage} {eliminatedMessage}</p>
            </div>

            {userUi}
        </div>
    );
}

function mapStateToProps(state: IState) {
    return {
        leagueManageState: state.leagueManageState,
        authenticationToken: state.authenticationToken
    };
}

export default connect(mapStateToProps)(LeagueManage as any);
