import * as React from "react";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router";
import _ from "lodash";

import { DataService } from "../../../services/dataService";
import { routeToLeague, routeToLeagueManagement } from "../../../services/routingService";
import { popup, confirm } from "../../../services/notifyService";

import { IState, ILeagueMemberState, IUserState, IPickTeamStat, ILeagueMemberConferenceStats, ILeagueMemberDivisionStats, IAvailableTeamPick} from "../../../state/state";
import { IAction } from "../../../actions";
import { IAjaxResponse, IUserLeagueView } from "../../../models/interfaces";

export interface ILeagueMemberProps {
    dispatch: (action: IAction) => void,
    authenticationToken: string,
    leagueMemberState: ILeagueMemberState,
    userState: IUserState
}

const refreshData = (props: ILeagueMemberProps) => {
    if (props.leagueMemberState.leagueMember) {
        DataService.getLeagueMemberState(
            props.authenticationToken,
            props.leagueMemberState.leagueMember.leagueMemberId,
            props.dispatch);
    }
}

const percentDisplay = (p: number): string | null => {
    if (_.isUndefined(p) || _.isNull(p) || _.isNaN(p)) {
        return null;
    }

    return `${(p * 100).toFixed(2)}%`;
}

const conferenceMapper = (props: ILeagueMemberProps): Array<JSX.Element> => {
    return props.leagueMemberState.conferenceStats.map(
        (conferenceStat: ILeagueMemberConferenceStats) => {
            const loses = conferenceStat.count - conferenceStat.wins;
            return (<tr key={conferenceStat.conferenceId}>
                <td>{conferenceStat.conferenceName}</td>
                <td>{conferenceStat.count}</td>
                <td>{conferenceStat.wins}-{loses}</td>
                <td>{percentDisplay(conferenceStat.wins / conferenceStat.count)}</td>
            </tr>);
        }
    )
}

const divisionMapper = (props: ILeagueMemberProps): Array<JSX.Element> => {
    return props.leagueMemberState.divisionStats.map(
        (divisionStat: ILeagueMemberDivisionStats) => {
            const loses = divisionStat.count - divisionStat.wins;
            return (<tr key={divisionStat.divisionId}>
                <td>{divisionStat.divisionName}</td>
                <td>{divisionStat.count}</td>
                <td>{divisionStat.wins}-{loses}</td>
                <td>{percentDisplay(divisionStat.wins / divisionStat.count)}</td>
            </tr>);
        }
    )
}

const teamMapper = (props: ILeagueMemberProps): Array<JSX.Element> => {
    return props.leagueMemberState.teamStats.map(
        (teamStat: IPickTeamStat) =>
            <tr key={teamStat.teamId}>
                <td>{teamStat.teamName} ({teamStat.wins}{'\u2011'}{teamStat.loses}{'\u2011'}{teamStat.ties})</td>
                <td>{teamStat.picksFor}</td>
                <td>{teamStat.picksForWins}-{teamStat.picksForLoses}</td>
                <td>{teamStat.picksAgainst}</td>
                <td>{teamStat.pickAgainstWins}-{teamStat.pickAgainstLoses}</td>
                <td>{teamStat.totalWins}-{teamStat.totalLoses}</td>
            </tr>
    )
}

// only shown for non-elimination...
const leagueGamesRow = (props: ILeagueMemberProps): JSX.Element | null => {
    if (!props.leagueMemberState.leagueRuleSet || !props.leagueMemberState.all) {
        return null;
    }
    if (props.leagueMemberState.leagueRuleSet.elimination) {
        return null;
    } else {
        return (<tr>
            <td>League Games</td>
            <td>{props.leagueMemberState.totalGames}</td>
            <td>{props.leagueMemberState.all.wins}-{props.leagueMemberState.totalGames - props.leagueMemberState.all.wins}</td>
            <td>{percentDisplay(props.leagueMemberState.all.wins / props.leagueMemberState.totalGames)}</td>
        </tr>);
    }
}

// only shown for non-elimination...
const leaguePicksNotMadeRow = (props: ILeagueMemberProps): JSX.Element | null => {
    if (!props.leagueMemberState.leagueRuleSet || !props.leagueMemberState.all) {
        return null;
    }
    if (props.leagueMemberState.leagueRuleSet.elimination) {
        return null;
    } else {
        return (<tr>
            <td>Picks Not Made</td>
            <td>{props.leagueMemberState.totalGames - props.leagueMemberState.all.count}</td>
            <td>0-{props.leagueMemberState.totalGames - props.leagueMemberState.all.count}</td>
            <td>0.00%</td>
        </tr>);
    }
}

const backToLeagueButton = (navigate: NavigateFunction, props: ILeagueMemberProps): JSX.Element | null => {
    if (!props.leagueMemberState.leagueView) {
        return null;
    }
    return (
        <button
            type="submit"
            className="btn btn-pickem-blue"
            onClick={e => {
                if (props.leagueMemberState.leagueView) {
                    routeToLeague(navigate, props.leagueMemberState.leagueView.leagueId, null);
                }
            } }
            >
                <span className="glyphicon glyphicon-triangle-left" aria-hidden="true"/>
                &nbsp;  Back To League
        </button>
    );
}

const backToLeagueManageButton = (navigate: NavigateFunction, props: ILeagueMemberProps) => {
    return (
        <button
            type="submit"
            className="btn btn-pickem-blue"
            onClick={e => {
                if (props.leagueMemberState.leagueView) {
                    routeToLeagueManagement(navigate, props.leagueMemberState.leagueView.leagueId);
                }
            } }
            >
            <span className="glyphicon glyphicon-triangle-left" aria-hidden="true"/>
            &nbsp;  Back To League Management
        </button>
    );
}

const addEntries = (props: ILeagueMemberProps): void => {
    if (!props.leagueMemberState.leagueMember) {        
        return;
    }
    const leagueMemberId = props.leagueMemberState.leagueMember.leagueMemberId;
    const teamName = props.leagueMemberState.leagueMember.teamName;
    const addCount = parseInt($('#add-entries-input-id').val(), 10);
    const word = addCount === 1 ? 'Entry' : 'Entries';
    confirm(`Confirm Add ${word}`, `Are you sure you want to ${addCount} ${word.toLowerCase()} for "${teamName}"?`)
        .then((ok: Boolean) => {
            if (ok) {
                DataService.addUserLeagueEntries(
                    props.authenticationToken,
                    leagueMemberId,
                    addCount,
                    props.dispatch).then((result: IAjaxResponse) => {
                        if (result.success) {
                            const word2 = addCount === 1 ? 'was' : 'were';
                            popup(`${word} Added`, `${word} ${word2} added successfully.`, "info");
                        } else {
                            popup(`Error Adding ${word}`, `Unable to add ${word.toLowerCase()}. ${result.message}`, "error");
                        }
                    });                
            }
        });
}

const deleteLeagueMember = (navigate: NavigateFunction, props: ILeagueMemberProps): void => {
    if (!props.leagueMemberState.leagueMember) {
        return;
    }
    const leagueMemberId = props.leagueMemberState.leagueMember.leagueMemberId;
    const teamName = props.leagueMemberState.leagueMember.teamName;
    confirm(`Confirm Remove ${teamName}`, `Are you sure you want to remove "${teamName}" from the league? This cannot be undone.`)
        .then((ok: Boolean) => {
            if (ok) {
                DataService.deleteLeagueMember(
                    props.authenticationToken,
                    leagueMemberId,
                    props.dispatch).then((result: IAjaxResponse) => {
                        if (result.success) {
                            popup(`${teamName} Removed`, `"${teamName}" was removed successfully.`, "info");
                            if (props.leagueMemberState.leagueView) {
                                routeToLeague(navigate, props.leagueMemberState.leagueView.leagueId, null);
                            }
                        } else {
                            popup(`Error Removing ${teamName}`, `Unable to remove "${teamName}". ${result.message}`, "error");
                        }
                    });
            }
        });
}

const infoPanel = (props: ILeagueMemberProps) => {
    const userInfo = props.leagueMemberState.userInfo;
    return (
        <table className="table table-bordered table-hover">
            <tbody>
                <tr>
                    <td>League Member Name</td>
                    <td>{props.leagueMemberState.leagueMember?.teamName}</td>
                </tr>
                <tr>
                    <td>Entries</td>
                    <td>{props.leagueMemberState.userEntries}</td>
                </tr>
                <tr>
                    <td>Email Address</td>
                    <td><a href={`mailto:${userInfo?.email}`}>{userInfo?.email}</a></td>
                </tr>
                <tr>
                    <td>Email Address Verified?</td>
                    <td>{userInfo?.verified.toString()}</td>
                </tr>
                <tr>
                    <td>Time Zone</td>
                    <td>{userInfo?.timeZone}</td>
                </tr>
            </tbody>
        </table>
    );
}

const addEntriesSection = (props: ILeagueMemberProps): JSX.Element => {
    if (!props.leagueMemberState.leagueView) {
        return (<span>Data error.</span>); 
    }
    const locked: boolean = props.leagueMemberState.leagueView.locked;
    const deleted: boolean = props.leagueMemberState.leagueView.deleted;
    if (locked || deleted) {
        return (<span>Further entries cannot be added because the league is {locked ? "locked" : "deleted"}.</span>);
    }
    return (
        <p>Add
            &nbsp;
            <select id="add-entries-input-id" defaultValue="1">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
            </select>
            &nbsp;
                    Entries <span className="glyphicon glyphicon-arrow-right" aria-hidden="true"/>
            &nbsp;&nbsp;
                    <button
                type="submit"
                className="btn btn-pickem-blue button-margin"
                onClick={e => { addEntries(props) } }
                >
                <i className="fa fa-plus" aria-hidden="true"/> Add
                    </button>
        </p>
    );
};

const mapOptions = (availableTeamPicks: IAvailableTeamPick[]) => {
    return availableTeamPicks.map(t => {
        return (<option key={t.teamId} value={t.teamId}>{t.teamName}</option>);
    });
};

const pickSection = (props: ILeagueMemberProps) => {
    const managerPickInfo = props.leagueMemberState.managerPickInfo;
    const availableTeamPicks = managerPickInfo?.availableTeamPicks || [];
    if (availableTeamPicks.length === 0 || props.leagueMemberState.leagueMember?.eliminated) {
        return null;
    }
    return (
        <div>
            <select id="available-team-id" defaultValue="0">
                <option value="0">(select)</option>
                {mapOptions(availableTeamPicks)}
            </select>
            <span className="glyphicon glyphicon-arrow-right" aria-hidden="true"/>
            &nbsp;
            <button
                type="submit"
                className="btn btn-pickem-blue button-margin"
                onClick={e => { managerPickOverride(props) } }
                >
                <i className="fa fa-check-square-o" aria-hidden="true"/> Pick
            </button>
        </div>
    );
};

const managerPickOverride = (props: ILeagueMemberProps): void => {
    if (!props.leagueMemberState.leagueMember || !props.leagueMemberState.managerPickInfo) {
        return;
    }
    const leagueMemberId = props.leagueMemberState.leagueMember.leagueMemberId;
    const userName = props.leagueMemberState.leagueMember.teamName;
    const managerPickInfo = props.leagueMemberState.managerPickInfo;
    const selectedTeamId = parseInt($('#available-team-id').val(), 10);
    if (selectedTeamId === 0) {
        popup('Team Selection Required', 'Please select a team first', "error");
        return;
    }
    const selectedTeamName = _.find(managerPickInfo.availableTeamPicks, t => t && t.teamId === selectedTeamId)?.teamName;
    confirm(`Confirm Pick For ${userName}`, `Click OK to set the pick for ${userName} for ${managerPickInfo.weekName} to the ${selectedTeamName}.`)
        .then((ok: Boolean) => {
            if (ok) {
                DataService.managerPickOverride(
                    props.authenticationToken,
                    leagueMemberId,
                    managerPickInfo.weekId,
                    selectedTeamId,
                    props.dispatch).then((result: IAjaxResponse) => {
                        if (result.success) {
                            popup('Pick Successful', `The ${selectedTeamName} were picked for ${userName} for ${managerPickInfo.weekName}.`, "info");
                            refreshData(props);
                        } else {
                            popup('Error Making Pick', `Unable to pick the ${selectedTeamName} for ${userName} for ${managerPickInfo.weekName}. ${result.message}`, "error");
                        }
                    });
            }
        });
}

const actionPanel = (navigate: NavigateFunction, props: ILeagueMemberProps) => {
    if (!props.leagueMemberState.leagueMember || !props.leagueMemberState.managerPickInfo || !props.leagueMemberState.leagueView) {
        return;
    }
    const entriesMessage = props.leagueMemberState.userEntries === 1 ? "This user has a single entry." : `This user has ${props.leagueMemberState.userEntries} entries.`;
    const teamName = props.leagueMemberState.leagueMember.teamName;
    const managerPickInfo = props.leagueMemberState.managerPickInfo;
    const availableTeamPicks = managerPickInfo.availableTeamPicks || [];
    const weekName = managerPickInfo.weekName;
    let pickMessage = "";

    if (managerPickInfo.pickIsManagerPick) {
        pickMessage = `You have selected the ${managerPickInfo.pickedTeam} for ${teamName} for ${weekName}.`
    } else if (managerPickInfo.hasPicked) {
        pickMessage = `${teamName} has made their pick for ${weekName}.`
    } else {
        pickMessage = `${teamName} has not yet made their selection for ${weekName}.`
    }

    if (props.leagueMemberState.leagueMember.eliminated) {
        pickMessage += ` You may not pick for ${teamName} because they have been eliminated.`
    } else if (availableTeamPicks.length === 0) {
        pickMessage += ` You may not pick for ${teamName} because all of the ${weekName} games are already locked.`
    } else if (managerPickInfo.hasPicked) {
        pickMessage += ` You may override the pick for ${teamName}.`
    } else {        
        pickMessage += ` You may set the pick on behalf of ${teamName}.`
    }

    const className = props.leagueMemberState.leagueView.leagueRuleSetId === 3 ? "" : "hidden";

    return (
        <table className="table table-bordered table-hover">
            <tbody>
                <tr>
                    <td>Entries</td>
                    <td>
                        {entriesMessage}<br /><br />
                        {addEntriesSection(props)}
                    </td>
                </tr>
                <tr>
                    <td>Removal</td>
                    <td>Remove Member Profile&nbsp;<span className="glyphicon glyphicon-arrow-right" aria-hidden="true"/>&nbsp;
                        <button
                            type="submit"
                            className="btn btn-pickem-blue button-margin"
                            onClick={e => { deleteLeagueMember(navigate, props) } }
                            >
                                <i className="fa fa-trash" aria-hidden="true"/> Remove
                        </button>
                    </td>
                </tr>
                <tr className={className}>
                    <td>Pick</td>
                    <td>{pickMessage}<br /><br />
                        {pickSection(props)} 
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

const adminPanel = (navigate: NavigateFunction, props: ILeagueMemberProps) => {
    if (!props.leagueMemberState.leagueView) {
        return null;
    }
    const leagueId: number = props.leagueMemberState.leagueView.leagueId;
    const league: IUserLeagueView | undefined = _.find(props.userState.leagues, l => l.leagueId === leagueId);
    if (league === null || _.isUndefined(league)) {
        return null;
    }
    if (props.userState.userId !== league.ownerId || props.leagueMemberState.userInfo === null) {
        return null;
    }
    
    return (
        <div>
            <h2 className="tight-panel">
                <img src="/img/new/exclamation-point-32x32.png" className="icon32" alt=""/> Admin
            </h2>
            <div className="content-panel">

                <div className="row">
                    <div className="col-md-6">{infoPanel(props)}</div>
                    <div className="col-md-6">{actionPanel(navigate, props)}</div>
                </div>

                <p>{backToLeagueManageButton(navigate, props)}</p>
            </div>
        </div>
    );
}

const LeagueMember = (props: ILeagueMemberProps) => {

    const navigate = useNavigate();
    
    if (!_.isObject(props.leagueMemberState) || !_.isObject(props.userState)) {
        // seemingly getting here with no leagueMemberState...
        // returning a blank template until the state arrives fixes this.
        return (<div/>);
    }

    if (props.leagueMemberState.initErrorMessage && props.leagueMemberState.initErrorMessage.length > 0) {        
        return (
            <div>
                <h2 className="tight-panel">
                    <img src="/img/new/profile-32x32.png" className="icon32" alt=""/> Error
                </h2>   
                <div className="content-panel">
                    <p>{props.leagueMemberState.initErrorMessage}</p>
                </div>
            </div>
        );
    }
    
    if (!props.leagueMemberState.leagueMember) {
        return null;
    }

    const eliminatedMessage: JSX.Element | null = props.leagueMemberState.leagueMember.eliminated 
        ? (<p style={{ color: 'red'}}>This league member has been eliminated.</p>) 
        : null;

    if (!props.leagueMemberState.leagueView || !props.leagueMemberState.all || !props.leagueMemberState.home || !props.leagueMemberState.away) {
        return null;
    }

    if (!props.leagueMemberState.favorite || !props.leagueMemberState.underdog || !props.leagueMemberState.even) {
        return null;
    }
    
    return (
        <div>
            <h2 className="tight-panel">
                <img src="/img/new/profile-32x32.png" className="icon32" alt=""/> {props.leagueMemberState.leagueMember.teamName}
            </h2>        
            <div className="content-panel">
                <p><b>{props.leagueMemberState.leagueView.leagueName}</b> member profile for <b>{props.leagueMemberState.leagueMember.teamName}</b>.</p>
                {eliminatedMessage}
                <p>{backToLeagueButton(navigate, props)}</p>
            </div>
            {adminPanel(navigate, props)}
            <h2>
                <img src="/img/new/bar-chart-32x32.png" className="icon32" alt=""/> Situational Statistics
            </h2>
            <table className="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th/>
                        <th>Count</th>
                        <th>Record</th>
                        <th>%</th>
                    </tr>
                </thead>
                <tbody>
                    {leagueGamesRow(props)}
                    <tr>
                        <td>Picks Made</td>
                        <td>{props.leagueMemberState.all.count}</td>
                        <td>{props.leagueMemberState.all.wins}-{props.leagueMemberState.all.count-props.leagueMemberState.all.wins}</td>
                        <td>{percentDisplay(props.leagueMemberState.all.wins/props.leagueMemberState.all.count)}</td>
                    </tr>
                    {leaguePicksNotMadeRow(props)}
                    <tr>
                        <td>Picked Home Team</td>
                        <td>{props.leagueMemberState.home.count}</td>
                        <td>{props.leagueMemberState.home.wins}-{props.leagueMemberState.home.count - props.leagueMemberState.home.wins}</td>
                        <td>{percentDisplay(props.leagueMemberState.home.wins/props.leagueMemberState.home.count)}</td>
                    </tr>
                    <tr>
                        <td>Picked Away Team</td>
                        <td>{props.leagueMemberState.away.count}</td>
                        <td>{props.leagueMemberState.away.wins}-{props.leagueMemberState.away.count - props.leagueMemberState.away.wins}</td>
                        <td>{percentDisplay(props.leagueMemberState.away.wins/props.leagueMemberState.away.count)}</td>
                    </tr>
                    <tr>
                        <td>Picked Favorite</td>
                        <td>{props.leagueMemberState.favorite.count}</td>
                        <td>{props.leagueMemberState.favorite.wins}-{props.leagueMemberState.favorite.count - props.leagueMemberState.favorite.wins}</td>
                        <td>{percentDisplay(props.leagueMemberState.favorite.wins/props.leagueMemberState.favorite.count)}</td>
                    </tr>
                    <tr>
                        <td>Picked Underdog</td>
                        <td>{props.leagueMemberState.underdog.count}</td>
                        <td>{props.leagueMemberState.underdog.wins}-{props.leagueMemberState.underdog.count - props.leagueMemberState.underdog.wins}</td>
                        <td>{percentDisplay(props.leagueMemberState.underdog.wins/props.leagueMemberState.underdog.count)}</td>
                    </tr>
                    <tr>
                        <td>Picked Even</td>
                        <td>{props.leagueMemberState.even.count}</td>
                        <td>{props.leagueMemberState.even.wins}-{props.leagueMemberState.even.count - props.leagueMemberState.even.wins}</td>
                        <td>{percentDisplay(props.leagueMemberState.even.wins/props.leagueMemberState.even.count)}</td>
                    </tr>
                </tbody>
            </table>
            <h2>
                <img src="/img/new/bar-chart-32x32.png" className="icon32" alt=""/> Conference Based Statistics
            </h2>
            <table className="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>Conference</th>
                        <th>Count</th>
                        <th>Record</th>
                        <th>%</th>
                    </tr>
                </thead>
                <tbody>
                    {conferenceMapper(props)}
                </tbody>
            </table>
            <h2>
                <img src="/img/new/bar-chart-32x32.png" className="icon32" alt=""/> Division Based Statistics
            </h2>
            <table className="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>Division</th>
                        <th>Count</th>
                        <th>Record</th>
                        <th>%</th>
                    </tr>
                </thead>
                <tbody>
                    {divisionMapper(props)}
                </tbody>
            </table>
            <h2>
                <img src="/img/new/bar-chart-32x32.png" className="icon32" alt=""/> Team Based Statistics
            </h2>
            <table className="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>Team</th>
                        <th>For</th>
                        <th>Rec</th>
                        <th>Against</th>
                        <th>Rec</th>
                        <th>Overall</th>
                    </tr>
                </thead>
                <tbody>
                    {teamMapper(props)}
                </tbody>
            </table>
        </div>
    );
}

function mapStateToProps(state: IState) {
    return {
        leagueMemberState: state.leagueMemberState,
        userState: state.userState,
        authenticationToken: state.authenticationToken
    };
}

export default connect(mapStateToProps)(LeagueMember as any);
