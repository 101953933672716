import * as React from "react";
import { compose, createStore, Store } from "redux";
import { Provider } from "react-redux";
// import { devToolsEnhancer } from 'redux-devtools-extension';
import "font-awesome/css/font-awesome.css";
import defaultReducer from "./reducers/defaultReducer"
import { bootstrapAuth, bootstrapUserState } from "./services/bootstrapService"
import { createRoot } from 'react-dom/client';

import { IState } from "./state/state";
import AppWrapper from "./components/App";

const run = (initialState: IState) => {

    const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    // @ts-ignore
    const store: Store<IState> = createStore(defaultReducer, initialState, composeEnhancers());
   
    bootstrapUserState(initialState, store.dispatch);
    
    const container = document.getElementById("appTopDiv")
    const root = createRoot(container!);
    root.render(
        <Provider store={store}>
            <AppWrapper store={store}/>
        </Provider>
    );
}

bootstrapAuth(run);
