import * as React from "react";

const Footer = () => {

    return (<footer className="bs-docs-footer hidden-print">
        <div className="container-fluid no-pad">
            <div className="row-fluid">
                <div className="col-sm-12">
                    <br />
                    <br />
                    <hr />
                    <img src="/img/comodo_secure_seal_76x26_transp.png" alt=""/>
                    <br />
                    Copyright 2016-2024 UpWay2Late.com Software. All rights reserved.
                    <br />
                </div>
            </div>
        </div>
    </footer>);
};

export default Footer;
