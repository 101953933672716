import * as React from "react";

import GameDayTime from "./GameDayTime";
import TeamBox from "./TeamBox";
import GameDetail from "./GameDetail";
import GameSlider from "./GameSlider";

import { ILeagueView, IGamePick, ICurrentEliminationPick  } from "../../../models/interfaces";

export interface IGameProps {
    league: ILeagueView;
    gamePick: IGamePick;
    currentWeekId: number;
    currentEliminationPicks: Array<ICurrentEliminationPick>;
    toggleGameDetail: (weekId: number, gameId: number) => void;
    updateGameSelection: (weekId: number, gameId: number, teamId: number) => void;
    updateMarginSelection: (weekId: number, gameId: number, margin: number) => void;
    updateMarginSelectionInProgress: (weekId: number, gameId: number, margin: number) => void;
}

const Game = (props: IGameProps) => {
    
    return (
        <div className="container-fluid game-box">
            <div className="row no-pad">
                <div className="col-xs-12">
                    <GameDayTime gamePick={props.gamePick} toggleGameDetail={props.toggleGameDetail} />
                </div>
            </div>
            <div className="row no-pad">
                <div className="col-xs-6">
                    <TeamBox
                        league={props.league}
                        gamePick={props.gamePick}
                        currentWeekId={props.currentWeekId}
                        currentEliminationPicks={props.currentEliminationPicks}
                        isAway={true}
                        updateGameSelection={props.updateGameSelection}
                        toggleGameDetail={props.toggleGameDetail} />
                </div>
                <div className="col-xs-6">
                    <TeamBox
                        league={props.league}
                        gamePick={props.gamePick}
                        currentWeekId={props.currentWeekId}
                        currentEliminationPicks={props.currentEliminationPicks}
                        isAway={false}
                        updateGameSelection={props.updateGameSelection}
                        toggleGameDetail={props.toggleGameDetail} />
                </div>
            </div>
            <GameSlider
                league={props.league}
                gamePick={props.gamePick}
                currentWeekId={props.currentWeekId}
                currentEliminationPicks={props.currentEliminationPicks}
                isAway={false}
                updateMarginSelection={props.updateMarginSelection}
                updateMarginSelectionInProgress={props.updateMarginSelectionInProgress}
            />
            <div className="row no-pad">
                <div className="col-xs-12">
                    <GameDetail league={props.league} gamePick={props.gamePick} />
                </div>
            </div>
        </div>
    );
}

export default Game;
