import { useEffect } from "react";

// @ts-ignore
const OnEnterWrapper = ({ children, onEnter }) => {
    useEffect(() => {
        onEnter();
    }, [onEnter]);

    return children;
};

export default OnEnterWrapper;
