import * as React from "react";
import { useEffect, useRef } from "react";
import { Slider } from "@mui/material";
import _ from "lodash";

import { ICurrentEliminationPick, IGamePick, ILeagueView } from "../../../models/interfaces";

export interface IGameSliderProps {
    league: ILeagueView;
    gamePick: IGamePick;
    isAway: boolean;
    currentWeekId: number;
    currentEliminationPicks: Array<ICurrentEliminationPick>;
    updateMarginSelection: (weekId: number, gameId: number, margin: number) => void;
    updateMarginSelectionInProgress: (weekId: number, gameId: number, margin: number) => void;
}

const debounced_update = _.debounce(function (updateMarginSelection: (arg0: any, arg1: any, arg2: number) => void, weekId: number, gameId: number, value: number) {
    // console.log('Function debounced after 50ms!');
    // props.updateMarginSelection(props.gamePick.weekId, props.gamePick.gameId, value as number);
    updateMarginSelection(weekId, gameId, value as number);
}, 500);

const handleChangeInProgress = (props: IGameSliderProps) => (e: any, value: number | number[]) => {
    if (props.gamePick.locked) {
        return;
    }
    const newValue = value as number;
    if (newValue !== props.gamePick.margin) {
        //props.gamePick.margin = value as number;
        //console.info(`update ${props.gamePick.gameId}`)
        props.updateMarginSelectionInProgress(props.gamePick.weekId, props.gamePick.gameId, value as number);
        debounced_update(props.updateMarginSelection, props.gamePick.weekId, props.gamePick.gameId, value as number);
    }    
};

const handleChangeComplete = (props: IGameSliderProps) => (e: any, value: number | number[]) => {    
    if (props.gamePick.locked) {
        return;
    }
    props.updateMarginSelection(props.gamePick.weekId, props.gamePick.gameId, value as number);
};

const isWinningMargin = (props: IGameSliderProps): boolean => {
    const {gamePick} = props;
    if (!gamePick.locked) {
        // hasn't started
        return false;
    }
    
    const pickedAway = gamePick.currentPickTeamId === gamePick.awayTeamId;
    const pickedHome = gamePick.currentPickTeamId === gamePick.homeTeamId;
    
    if (!pickedAway && !pickedHome) {
        // didn't even pick
        return false;        
    }

    const lead = pickedAway 
        ? gamePick.awayPoints - gamePick.homePoints
        : gamePick.homePoints - gamePick.awayPoints;

    return lead >= gamePick.margin;
}

const GameSlider = (props: IGameSliderProps): JSX.Element | null => {
    const ref = useRef<any>(null);

    useEffect(() => {
        if (ref.current) {
            ref.current.addEventListener(
                "touchstart",
                (e: { target: { dataset: { index: any; }; }; stopPropagation: () => void; }) => {
                    const isThumb = e.target.dataset.index;

                    if (!isThumb) {
                        e.stopPropagation();
                    }
                },
                { capture: true }
            );
        }
    });
    
    if (props.league.leagueRuleSetId !== 5) {
        return null;
    }
    const {gamePick} = props;
    const disabled = !gamePick.currentPickTeamId;

    const marks = [];
    
    for (let i=1; i<=21; i++) {
        marks.push({value: i, label: i.toString()});
    }

    let color;
    
    if (gamePick.locked) {
        if (gamePick.final) {
            if (isWinningMargin(props)) {
                color = "#3ac63a";
            } else {
                color = "#ff3333";
            }            
        } else {
            if (isWinningMargin(props)) {
                color = "#70db70";
            } else {
                color = "#ff7878";
            }            
        }       
    } else {
        color = "blue";
    }
    
    // let color = props.gamePick.locked ? "gray" : "blue";
    
    const slider = disabled 
        ? <Slider
                aria-label="Margin"
                defaultValue={1}
                valueLabelDisplay="on"
                step={1}
                marks={marks}
                min={1}
                max={21}
                value={gamePick.margin}
                disabled
            />
            :
            <Slider
                ref={ref}
                aria-label="Margin"
                defaultValue={1}
                valueLabelDisplay="on"
                sx={{
                    color: color,
                    touchAction: "auto"
                }}
                step={1}
                marks={marks}
                min={1}
                max={21}
                value={props.gamePick.margin}
                onChange={handleChangeInProgress(props)}
                onChangeCommitted={handleChangeComplete(props)}                
            />;
    
    return (<div className="row slider-box">
        <div className="col-xs-12">
            {slider}
        </div>
    </div>);
}

export default GameSlider;
