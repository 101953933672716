import * as React from "react";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router";
import _ from "lodash";

import { AuthorizationService } from "../../../services/authorizationService";

import { logout, blockUi } from "../../../actions";

import { IState, IUserState } from "../../../state/state";

export interface LogoutProps {
    dispatch: any,
    userState: IUserState
}

let logOutInProgress: boolean = false;

const doLogout = (navigate: NavigateFunction, props: LogoutProps, e: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLInputElement> | null): void => {
    if (logOutInProgress) {
        return; // multiple clicks
    }
    logOutInProgress = true;
    props.dispatch(blockUi(true, "Logging out..."));
    if (e) {
        e.preventDefault();
    }
    const onLogoutSuccess = (): void => {
        logOutInProgress = false;
        props.dispatch(blockUi(false, ""));        
        props.dispatch(logout());
        navigate('/login');
    }
    AuthorizationService.logout(onLogoutSuccess);
};

const Logout = (props: LogoutProps) => {
    const navigate = useNavigate();
    
    const isFacebookLogin = !_.isNil(props.userState) && !_.isNil(props.userState.facebookId) && props.userState.facebookId.length;

    return isFacebookLogin
        ?
        (<div>
            <h2 className="tight-panel"><img src="/img/new/profile-32x32.png" className="icon32" alt=""/> Facebook Log Out</h2>
            <div className="content-panel">
                <p>All done? Click here to log out!</p>
                <input
                    type="image"
                    src="/img/fb-log-out-button.png"
                    alt=''
                    onClick={e => doLogout(navigate, props, e)}>                    
                </input>
            </div>
        </div>)
        :
        (<div>
            <h2 className="tight-panel"><img src="/img/new/profile-32x32.png" className="icon32" alt=""/> Log Out</h2>
            <div className="content-panel">
                <p>All done? Click here to log out!</p>
                <button
                    type="submit"
                    className="btn btn-pickem-blue"
                    onClick={e => doLogout(navigate, props, e)}>
                    <i className="fa fa-sign-out" aria-hidden="true"/> Log Out
                </button>
            </div>
        </div>);
};

function mapStateToProps(state: IState) {
    return {
        userState: state.userState
    };
}

export default connect(mapStateToProps)(Logout as any);
