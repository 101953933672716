import $ from "jquery";
import _ from "lodash";

const provideAlertify = (): any => {
    const alertify: any = (window as any).alertify;
    if (_.isUndefined(alertify)) {
        console.warn('Alertify is missing.')
        return {
            alert: () => {},
            confirm: () => {},
            prompt: () => {}
        };
    }
    alertify.defaults.maintainFocus = false;
    alertify.defaults.preventBodyShift = true;
    alertify.defaults.transition = 'fade';
    return alertify;
}

export const popup = (title: string, text: string, type: string): void => {

    provideAlertify().alert(title, text);
}

export const confirm = (title: string, text: string): Promise<Boolean> => {
    return new Promise((resolve) => {
        provideAlertify().confirm(
            title,
            text,
            () => { resolve(true); },
            () => { resolve(false); });
    });
}

export interface IPrompt {
    ok: boolean;
    value: any
}

export const prompt = (title: string, text: string, value: any, type: string): Promise<IPrompt> => {
    return new Promise((resolve) => {
        provideAlertify().prompt(
            title,
            text,
            value,
            (evt: any, value: any) => { resolve({ ok: true, value }); },
            // () => { resolve(false); }).setting({ type: type});
            () => { resolve({ok: false, value: undefined}); }).setting({ type: type});
    });
}

export const promptWithContent = (title: string, text: string, value: any, type: string, content: string, id: string): Promise<IPrompt> => {
    return new Promise((resolve) => {
        provideAlertify().prompt(
            title,
            text,
            value,
            (evt: any, value: any) => {
                resolve({
                    ok: true, value: $(`#${id}`).val()
                });
                provideAlertify().prompt().destroy(); 
            },
            () => {
                // resolve(false);
                resolve({ok: false, value: undefined});
                provideAlertify().prompt().destroy(); 
            }).setting({ type: type }).setContent(content);
    });
}