import * as React from "react";
import {NavigateFunction, useLocation, useNavigate, Location} from "react-router";
import {HashRouter, useRoutes, useSearchParams,} from "react-router-dom";
import {Store} from "redux";
import _ from "lodash";

import Nav from "./nav/Nav";
import UiBlocker from "./widgets/UiBlocker";
import Footer from "./widgets/Footer";

import PublicPage from "./pages/public/PublicPage";
import OnEnterWrapper from "./controls/OnEnterWrapper";
import Home from "./pages/public/Home";
import Privacy from "./pages/public/Privacy";
import TermsOfService from "./pages/public/TermsOfService";
import Login from "./pages/auth/Login";
import CreateProfile from "./pages/profile/CreateProfile";
import EmailVerify from "./pages/verify/EmailVerify";
import InviteVerify from "./pages/verify/InviteVerify";
import Leagues from "./pages/league/Leagues";
import CreateLeague from "./pages/league/CreateLeague";
import League from "./pages/league/League";
import LeagueManage from "./pages/leagueManage/LeagueManage";
import LeagueMember from "./pages/league/LeagueMember";
import LeagueSeason from "./pages/leagueSeason/LeagueSeason";
import ProfileUpdate from "./pages/profile/ProfileUpdate";
import Invite from "./pages/league/Invite";
import Logout from "./pages/auth/Logout";
import PasswordReset from "./pages/auth/PasswordReset";

import {IState} from "../state/state";
import {
    setCreateLeagueState,
    setInviteState,
    setLeagueSeasonState,
    setLeagueState,
    setLoginDestination,
    setPasswordResetState
} from "../actions";

import {DefaultStateProvider} from "../state/defaultStateProvider";
import {DataService} from "../services/dataService";
import {GameRefreshService} from "../services/gameRefreshService";
import {routeToLogin} from "../services/routingService";

const needsAuth = (navigate: NavigateFunction, store: Store<IState>, location: Location): boolean => {

    const token: string = store.getState().authenticationToken || '';

    if (token.length === 0) {
        store.dispatch(setLoginDestination(`${location.pathname}${location.search}`));
        // routeToLogin(navigate);
        setTimeout(() => routeToLogin(navigate), 1000);
        return true;
    }

    return false;
}

function onEnterPasswordReset(store: Store<IState>, searchParams: URLSearchParams): void {
    const resetCode = searchParams.get('reset');
    const email = searchParams.get('e');
    if (_.isString(resetCode) && _.isString(email)) {
        store.dispatch(setPasswordResetState({ email: email, resetCode: resetCode, newPassword: '' }));
    }
}

function onEnterEmailVerify(store: Store<IState>, searchParams: URLSearchParams): void {
    DataService.verifyEmail(searchParams.get('g') ?? '', store.dispatch);
}

function onEnterInviteVerify(store: Store<IState>, searchParams: URLSearchParams): void {
    DataService.getInviteVerifyState(
        store.getState().authenticationToken,
        searchParams.get('g') ?? '', store.dispatch);
}

function onEnterLeagues(navigate: NavigateFunction, store: Store<IState>, location: Location): void {
    if (needsAuth(navigate, store, location)) {
        return;
    }
    DataService.getUserState(
        store.getState().authenticationToken,
        false,
        store.dispatch);
    DataService.getPublicLeagues(
        store.getState().authenticationToken,
        false,
        store.dispatch);
    store.dispatch(setLeagueState(null));
    store.dispatch(setLeagueSeasonState(null));
    store.dispatch(setInviteState(null));
}

function onEnterPublicPage(store: Store<IState>): void {
    DataService.getUserState(
        '',
        false,
        store.dispatch);
    DataService.getPublicLeagues(
        '',
        false,
        store.dispatch);
    store.dispatch(setLeagueState(null));
    store.dispatch(setLeagueSeasonState(null));
    store.dispatch(setInviteState(null));
}

function onEnterCreateLeague(navigate: NavigateFunction, store: Store<IState>, location: Location): void {
    if (needsAuth(navigate, store, location)) {
        return;
    }
    // update seasons
    DataService.getSeasons(store.dispatch);
    // set the initial/default state
    store.dispatch(setCreateLeagueState(DefaultStateProvider.getDefaultCreateLeagueState()));
}

function onEnterLeague(navigate: NavigateFunction, store: Store<IState>, searchParams: URLSearchParams): void {
    const leagueId = parseInt(searchParams.get('leagueId') ?? '0', 10);
    const leagueMemberId = parseInt(searchParams.get('leagueMemberId') ?? '0', 10);

    DataService.getLeagueState(
        store.getState().authenticationToken,
        leagueId,
        leagueMemberId,
        store.dispatch);

    const leagueSeasonState = store.getState().leagueSeasonState;

    if (leagueSeasonState && leagueSeasonState.leagueView && leagueSeasonState.leagueView.leagueId !== leagueId) {
        // since you can switch around now... make sure states match (for live update)
        DataService.getLeagueSeasonState(
            store.getState().authenticationToken,
            leagueId,
            leagueMemberId,
            store.dispatch);
    }

    GameRefreshService.init(store);
}

function onEnterLeagueManage(navigate: NavigateFunction, store: Store<IState>, location: Location, searchParams: URLSearchParams): void {
    if (needsAuth(navigate, store, location)) {
        return;
    }
    const leagueId = parseInt(searchParams.get('leagueId') ?? '0', 10);
    DataService.getLeagueManageState(
        store.getState().authenticationToken,
        leagueId,
        store.dispatch);
}

function onEnterLeagueMember(navigate: NavigateFunction, store: Store<IState>, searchParams: URLSearchParams): void {
    // this is fine now. some are public. cannot haxxor to non-public.
    /*if (needsAuth(nextState, replace)) {
        return;
    }*/
    const leagueMemberId = parseInt(searchParams.get('leagueMemberId') ?? '0', 10);

    DataService.getLeagueMemberState(
        store.getState().authenticationToken,
        leagueMemberId,
        store.dispatch);
}

function onEnterLeagueSeason(navigate: NavigateFunction, store: Store<IState>, location: Location, searchParams: URLSearchParams): void {
    if (needsAuth(navigate, store, location)) {
        return;
    }
    const leagueId = parseInt(searchParams.get('leagueId') ?? '0', 10);
    const leagueMemberId = parseInt(searchParams.get('leagueMemberId') ?? '0', 10);

    DataService.getLeagueSeasonState(
        store.getState().authenticationToken,
        leagueId,
        leagueMemberId,
        store.dispatch);

    const leagueState = store.getState().leagueState;

    if (leagueState && leagueState.leagueView && leagueState.leagueView.leagueId !== leagueId) {
        // since you can switch around now... make sure states match (for live update)
        DataService.getLeagueState(
            store.getState().authenticationToken,
            leagueId,
            leagueMemberId,
            store.dispatch);
    }

    GameRefreshService.init(store);
}

function onEnterProfileUpdate(navigate: NavigateFunction, store: Store<IState>, location: Location): void {
    if (needsAuth(navigate, store, location)) {
        return;
    }
    DataService.getProfileUpdateState(
        store.getState().authenticationToken,
        store.dispatch);
}

function onEnterInvite(navigate: NavigateFunction, store: Store<IState>, location: Location, searchParams: URLSearchParams): void {
    if (needsAuth(navigate, store, location)) {
        return;
    }
    const leagueId = parseInt(searchParams.get('leagueId') ?? '0', 10);
    DataService.getInviteState(
        store.getState().authenticationToken,
        leagueId,
        store.dispatch);
}

const App = (props: any): JSX.Element => (

    <div className="fullPage">
        <div className="pageNoFooter">
            <Nav/>
            <div className="container-fluid bs-docs-container">
                {props.children}
            </div>
            <UiBlocker />
        </div>
        <Footer />
    </div>
);

const RouterApp = (props: StoreProps) => {
    const [searchParams] = useSearchParams();
    const location: Location = useLocation();
    const navigate = useNavigate();
    return useRoutes([
        {path: "/", element: <Home />},
        {path: "/privacy", element: <Privacy />},
        {path: "/tos", element: <TermsOfService />},
        {path: "/login", element: <Login />},
        {path: "/logout", element: <Logout />},
        {
            path: "/passwordReset", 
            element: <OnEnterWrapper onEnter={() => onEnterPasswordReset(props.store, searchParams)}><PasswordReset/></OnEnterWrapper>
        },
        {path: "/createProfile", element: <CreateProfile />},
        {
            path: "/emailVerify",
            element: <OnEnterWrapper onEnter={() => onEnterEmailVerify(props.store, searchParams)}><EmailVerify/></OnEnterWrapper>
        },
        {
            path: "/inviteVerify",
            element: <OnEnterWrapper onEnter={() => onEnterInviteVerify(props.store, searchParams)}><InviteVerify/></OnEnterWrapper>
        },
        {
            path: "/leagues",
            element: <OnEnterWrapper onEnter={() => onEnterLeagues(navigate, props.store, location)}><Leagues/></OnEnterWrapper>
        },
        {
            path: "/publicPage",
            element: <OnEnterWrapper onEnter={() => onEnterPublicPage(props.store)}><PublicPage/></OnEnterWrapper>
        },
        {
            path: "/createLeague",
            element: <OnEnterWrapper onEnter={() => onEnterCreateLeague(navigate, props.store, location)}><CreateLeague/></OnEnterWrapper>
        },
        {
            path: "/league",
            element: <OnEnterWrapper onEnter={() => onEnterLeague(navigate, props.store, searchParams)}><League/></OnEnterWrapper>
        },
        {
            path: "/leagueManage",
            element: <OnEnterWrapper onEnter={() => onEnterLeagueManage(navigate, props.store, location, searchParams)}><LeagueManage/></OnEnterWrapper>
        },
        {
            path: "/leagueMember",
            element: <OnEnterWrapper onEnter={() => onEnterLeagueMember(navigate, props.store, searchParams)}><LeagueMember/></OnEnterWrapper>
        },
        {
            path: "/leagueSeason",
            element: <OnEnterWrapper onEnter={() => onEnterLeagueSeason(navigate, props.store, location, searchParams)}><LeagueSeason/></OnEnterWrapper>
        },
        {
            path: "/profile",
            element: <OnEnterWrapper onEnter={() => onEnterProfileUpdate(navigate, props.store, location)}><ProfileUpdate/></OnEnterWrapper>
        },
        {
            path: "/invite",
            element: <OnEnterWrapper onEnter={() => onEnterInvite(navigate, props.store, location, searchParams)}><Invite/></OnEnterWrapper>
        },
    ]);
};

export interface StoreProps {
    store: Store<IState>
}
const AppWrapper = (props: StoreProps) => {
    return (
        <HashRouter>
            <App><RouterApp store={props.store} /></App>
        </HashRouter>        
    );
};

export default AppWrapper;
