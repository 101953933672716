import * as React from "react";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router";
import $ from "jquery";

import FacebookLoginButton from "../../controls/FacebookLoginButton";
import MessagePanel from "../../controls/MessagePanel";
import HidableButton from "../../controls/HidableButton";

import { AuthorizationService, ILoginRequest } from "../../../services/authorizationService";
import { DataService } from "../../../services/dataService";
import { routeToHome, routeToLeagues, routeToCreateProfile, routeToCustom } from "../../../services/routingService";

import { IState, ILoginDestinationState } from "../../../state/state";
import { IAction, login, loginFailed, blockUi, setLoginDestination } from "../../../actions";

export interface ILoginProps {
    dispatch: (action: IAction) => void,
    loginDestination: ILoginDestinationState,
    invitationCode: string,
    loginErrorMessage: string
}

const onLoginSuccess = (navigate: NavigateFunction, props: ILoginProps, token: string): void => {
    props.dispatch(blockUi(false, ""));
    props.dispatch(login(token));
    DataService.getUserState(token, true, props.dispatch);

    const destination = Object.assign({}, props.loginDestination, {});    
    if (destination.destination) {
        props.dispatch(setLoginDestination(null));
        routeToCustom(navigate, destination.destination);
    } else {
        routeToLeagues(navigate);
    }
};

const onLoginFailure = (props: ILoginProps, message: string): void => {
    props.dispatch(loginFailed(message));
    props.dispatch(blockUi(false, ""));
};

const doLogin = (navigate: NavigateFunction, props: ILoginProps, e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    const loginRequest: ILoginRequest = {
        email: $('#emailLoginInput').val(),
        password: $('#passwordLoginInput').val(),
        code: props.invitationCode
    };
    props.dispatch(blockUi(true, "Authenticating..."));
    AuthorizationService.login(navigate, props, loginRequest, onLoginSuccess, onLoginFailure);
};

const onResetPassword = (navigate: NavigateFunction, props: ILoginProps, e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    const loginRequest: ILoginRequest = {
        email: $('#emailLoginInput').val(),
        password: $('#passwordLoginInput').val(),
        requestReset: true
    };
    props.dispatch(blockUi(true, "Sending Password Reset Email..."));
    AuthorizationService.login(navigate, props, loginRequest, onLoginSuccess, onLoginFailure);
};

const Login = (props: ILoginProps) => {
    const navigate = useNavigate();
    return (
        <div>
            <div className="jumbotron">
            </div>

            <h2 className="tight-panel"><img src="/img/new/profile-32x32.png" className="icon32" alt=""/> Log in with Facebook</h2>
            <div className="content-panel">
                <table>
                    <tbody>
                        <tr>
                            <td><FacebookLoginButton/></td>
                            <td>
                                &nbsp;
                                <span className="glyphicon glyphicon-arrow-left" aria-hidden="true"/> 
                                &nbsp;
                                Click here to log in to Pick'Em Leagues with your Facebook profile.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <h2 className="tight-panel"><img src="/img/new/profile-32x32.png" className="icon32" alt=""/> Log in with Pick'Em Leagues</h2>
            <div className="content-panel">
                <form>
                    <div className="form-group">
                        <label htmlFor="emailLoginInput">Email Address</label>
                        <input type="email" className="form-control" id="emailLoginInput" placeholder="Email" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="passwordLoginInput">Password</label>
                        <input type="password" className="form-control" id="passwordLoginInput" placeholder="Password" />
                    </div>
                    <MessagePanel className="bg-danger" message={props.loginErrorMessage} />
                    <button
                        type="submit"
                        className="btn btn-pickem-blue"
                        onClick={e => doLogin(navigate, props, e)}>
                        <i className="fa fa-user" aria-hidden="true"/> Login
                    </button>
                    &nbsp;
                    <HidableButton 
                        className="btn btn-success btn-float-right" 
                        image="envelope"
                        text="Send Password Reset Email"
                        visible={true /*props.loginErrorMessage && props.loginErrorMessage.length > 1*/}
                        onClick={e => onResetPassword(navigate, props, e)}
                        />
                </form>        
                <hr/>
                <p>
                    <button
                        type="submit"
                        className="btn btn-pickem-blue"
                        onClick={() => routeToCreateProfile(navigate)} >
                        <i className="fa fa-user-plus" aria-hidden="true"/> Create Profile
                    </button>
                    &nbsp;
                    <span className="glyphicon glyphicon-arrow-left" aria-hidden="true"/> 
                    &nbsp;
                    No profile yet? Go here.
                </p>
                <p>
                    <button
                        type="submit"
                        className="btn btn-pickem-blue"
                        onClick={() => routeToHome(navigate)} >
                        <i className="fa fa-question-circle-o" aria-hidden="true"/> Help
                    </button>
                    &nbsp;
                    <span className="glyphicon glyphicon-arrow-left" aria-hidden="true"/>
                    &nbsp;
                    What is all this? Help is over here!
                </p>
            </div>
        </div>
    );
};

function mapStateToProps(state: IState) {
    return {
        loginDestination: state.loginDestination,
        invitationCode: state.invitationCode,
        loginErrorMessage: state.loginErrorMessage
    };
}

export default connect(mapStateToProps)(Login as any);
