import * as React from "react";

import Game from "./Game";

import { ILeagueView, IGamePick, ICurrentEliminationPick } from "../../../models/interfaces";

export interface IGamesListProps {
    league: ILeagueView;
    gamePicks: Array<IGamePick>;
    currentWeekId: number;
    currentEliminationPicks: Array<ICurrentEliminationPick>;
    toggleGameDetail: (weekId: number, gameId: number) => void;
    updateGameSelection: (weekId: number, gameId: number, teamId: number) => void;
    updateMarginSelection: (weekId: number, gameId: number, margin: number) => void;
    updateMarginSelectionInProgress: (weekId: number, gameId: number, margin: number) => void;
}

const GamesList = (props: IGamesListProps) => {

    const listMapper = props.gamePicks.map(
        (gamePick: IGamePick) =>
            <Game
                key={`leagueWeekGame${gamePick.gameId}`}
                league={props.league} 
                gamePick={gamePick}
                currentWeekId={props.currentWeekId}
                currentEliminationPicks={props.currentEliminationPicks}
                toggleGameDetail={props.toggleGameDetail}
                updateGameSelection={(weekId: number, gameId: number, teamId: number) => props.updateGameSelection(weekId, gameId, teamId)}
                updateMarginSelection={(weekId: number, gameId: number, margin: number) => props.updateMarginSelection(weekId, gameId, margin)}
                updateMarginSelectionInProgress={(weekId: number, gameId: number, margin: number) => props.updateMarginSelectionInProgress(weekId, gameId, margin)}
            />
    );

    if (props.gamePicks.length === 0) {
        return <div/>;
    }

    return (
        <span>
            {listMapper}
        </span>
    );

}

export default GamesList;
