import * as React from "react";

import { ILeagueView, ILeagueRuleSet } from "../../models/interfaces";

export interface LeagueTableProps {
    leagueView: ILeagueView | null;
    leagueRuleSet?: ILeagueRuleSet | null;
};

const leagueDescription = (props: LeagueTableProps): JSX.Element | null => {
    if (props.leagueView && props.leagueView.description && props.leagueView.description.length > 0) {
        return <p>{props.leagueView.description}</p>;
    } else {
        return null;
    }
}

const membersMessage = (props: LeagueTableProps): JSX.Element | null => {
    if (!props.leagueView) {
        return null;
    }
    if (props.leagueView.members === 1) {
        return <span>There is <b>1</b> member.</span>;
    } else {
        return <span>There are <b>{props.leagueView.members.toString()}</b> members.</span>;
    }
}

const eliminatedMembersMessage = (props: LeagueTableProps): JSX.Element | null => {
    if (props.leagueRuleSet && !props.leagueRuleSet.elimination) {
        return null;
    }
    else if (props.leagueView && props.leagueView.eliminatedMembers === 0) {
        return <span>No one has been eliminated.</span>;
    } else {
        if (!props.leagueView) {
            return null;
        }
        const remainingMembers = props.leagueView.members - props.leagueView.eliminatedMembers;

        let eliminated: JSX.Element;
        let remaining: JSX.Element;

        if (props.leagueView.eliminatedMembers === 1) {
            eliminated = <span><b>1</b> member has been eliminiated.</span>;
        } else {
            eliminated = <span><b>{props.leagueView.eliminatedMembers.toString()}</b> members have been eliminated.</span>;
        }

        if (remainingMembers === 1) {
            remaining = <span><b>One</b> member remains.</span>;
        } else {
            remaining = <span><b>{remainingMembers.toString()}</b> members are still playing.</span>;
        }

        return <span>{eliminated} {remaining}</span>;
    }
}

const publicMessage = (props: LeagueTableProps) => {
    if (props.leagueView && props.leagueView.isPublic) {
        return "This league is public.";
    } else if (props.leagueView && !props.leagueView.isPublic && props.leagueView.isPublicViewable) {
        return "This league is not public, but it is public-viewable.";
    } else {
        return "This league is not public.";
    }
}

const LeagueTable = (props: LeagueTableProps): JSX.Element | null => {
    if (!props.leagueView || !props.leagueRuleSet) {
        return null;
    }
    return (
        <span>
            <p>
                <b>{props.leagueView.leagueName}</b> is a {props.leagueView.seasonName} <b>{props.leagueRuleSet.name}</b> league.
                The owner is <b>{props.leagueView.ownerName}</b>. {membersMessage(props)} {eliminatedMembersMessage(props)}
            </p>
            {leagueDescription(props)}
            <p>This league is{props.leagueView.locked ? '' : ' not'} locked.</p>
            <p>{publicMessage(props)}</p>
        </span>
    );
}

export default LeagueTable;
