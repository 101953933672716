import _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router";
import $ from "jquery";

import HidableImage from "../../controls/HidableImage";
import MessagePanel from "../../controls/MessagePanel";
import LeagueShareLink from "../../controls/LeagueShareLink";

import LeagueTable from "../../widgets/LeagueTable";
import LoginOrCreateProfile from "../../widgets/LoginOrCreateProfile";
import LeagueWeekEliminationSummary from "../../widgets/LeagueWeekEliminationSummary";
import LeagueWeekPickEmSummary from "../../widgets/LeagueWeekPickEmSummary";

import { DataService } from "../../../services/dataService";
import {
    routeToLeagues,
    routeToPublicPage,
    routeToLeagueSeason,
    routeToInvite,
    routeToLeagueManagement
} from "../../../services/routingService";

import { popup, confirm } from "../../../services/notifyService";

import { IState, ILeagueState, IUserState } from "../../../state/state";
import {
    IAction,
    setThisWeekSummaryVisible,
    setLastWeekSummaryVisible,
    setStandingsVisible
} from "../../../actions";
import { ILeagueMember, IEliminationGrid, IAjaxResponse } from "../../../models/interfaces";

/* eslint-disable jsx-a11y/anchor-has-content */

const isSmall: boolean = $(window).width() < 600;

export interface ILeagueProps {
    dispatch: (action: IAction) => void,
    onSetThisWeekSummaryVisible: (visible: number) => void,
    onSetLastWeekSummaryVisible: (visible: number) => void,
    onSetStandingsVisible: (visible: number) => void,
    authenticationToken: string,
    leagueState: ILeagueState,
    userState: IUserState
}

const lockLeague = (props: ILeagueProps): void => {
    confirm("Confirm Lock League", "Are you sure you want to lock this league?")
        .then((ok: Boolean) => {
            if (ok) {
                if (!props.leagueState.leagueView) {
                    popup("League Not Locked", 'Unable to lock league. Data error.', "error");
                    return;
                }
                DataService.lockLeague(
                    props.authenticationToken,
                    props.leagueState.leagueView.leagueId,
                    props.dispatch).then((result: IAjaxResponse) => {
                        if (result.success) {
                            popup("League Locked", "The league was successfully locked.", "info");
                        } else {
                            popup("League Not Locked", `Unable to lock league. ${result.message}`, "error");
                        }
                    });
            }
        });
}

const percentDisplay = (p: number): string | null => {
    if (_.isUndefined(p) || _.isNull(p)) {
        return null;
    }

    return `${p.toString()}%`;
}

const inviteButton = (navigate: NavigateFunction, props: ILeagueProps): JSX.Element | null => {
    if (!props.leagueState.leagueView) {
        return null;
    }    
    if (props.userState && props.leagueState.leagueView.userId === props.userState.userId && !props.leagueState.leagueView.locked) {
        return (
            <button
                type="submit"
                className="btn btn-pickem-blue button-margin"
                onClick={() => {
                    if (props.leagueState.leagueView) {                        
                        routeToInvite(navigate, props.leagueState.leagueView.leagueId);
                    }
                } }
                >
                <i className="fa fa-envelope" aria-hidden="true"/> Invite New Members
            </button>);
    } else {
        return null;
    }
}

const lockButton = (props: ILeagueProps): JSX.Element | null => {
    if (!props.leagueState.leagueView) {
        return null;
    }
    if (props.leagueState.leagueView.userId === props.userState.userId && !props.leagueState.leagueView.locked) {
        return (
            <button
                type="submit"
                className="btn btn-pickem-blue button-margin"
                onClick={() => { lockLeague(props); } }
                >
                <i className="fa fa-lock" aria-hidden="true"/> Lock League
            </button>);
    } else {
        return null;
    }
}

const manageButton = (navigate: NavigateFunction, props: ILeagueProps): JSX.Element | null => {
    if (!props.leagueState.leagueView) {
        return null;
    }
    if (props.leagueState.leagueView.userId === props.userState.userId) {
        return (
            <button
                type="submit"
                className="btn btn-pickem-blue button-margin"
                onClick={() => {
                    if (props.leagueState.leagueView) {                        
                        routeToLeagueManagement(navigate, props.leagueState.leagueView.leagueId);
                    }
                } }
                >
                <i className="fa fa-wrench" aria-hidden="true"/> Manage League
            </button>);
    } else {
        return null;
    }
}

const backToLeaguesButton = (navigate: NavigateFunction, props: ILeagueProps): JSX.Element => {
    return <button
        type="submit"
        className="btn btn-pickem-blue button-margin"
        onClick={() => {            
            if (props.userState.userId > 0) {
                routeToLeagues(navigate);
            } else {
                routeToPublicPage(navigate);
            }
        } }
        >
        <span className="glyphicon glyphicon-triangle-left" aria-hidden="true"/>
        &nbsp;
            Back To Leagues
        </button>;
}

const userIsInLeague = (props: ILeagueProps): boolean => {
    const userId = props.userState.userId;
    const leagueMember: ILeagueMember | undefined = _.find(props.leagueState.leagueMembers, lm => lm.userId === userId);
    return _.isObject(leagueMember);
}

const userIsLeagueWinner = (props: ILeagueProps): boolean => {
    if (!props.leagueState.leagueView || !props.leagueState.leagueView.complete) {
        return false;
    }
    const userId = props.userState.userId;
    const leagueMember: ILeagueMember | undefined = _.find(props.leagueState.leagueMembers, lm => lm.userId === userId);
    if (!_.isObject(leagueMember)) {
        return false;
    }
    return leagueMember?.winner || false;
}

const myPicksSectionStatus = (props: ILeagueProps): JSX.Element | null => {
    if (!props.leagueState.leagueMemberCurrentWeekPicksDue) {
        return null;    
    }         
    const picksDue: number = props.leagueState.leagueMemberCurrentWeekPicksDue.picksDue;
    const picksMade: number = props.leagueState.leagueMemberCurrentWeekPicksDue.picksMade;
    const picksCanMake: number = props.leagueState.leagueMemberCurrentWeekPicksDue.picksCanMake;
    const picksCanNotMake: number = props.leagueState.leagueMemberCurrentWeekPicksDue.picksCanNotMake;

    if (picksMade === picksDue) {
        // user has made all their picks.
        const pickWord: string = (picksMade === 1) ? "pick" : "picks";
        return <div className="happy-green-div"><i className="fa fa-check-square-o happy-green" aria-hidden="true"/> Looks good! You have made your {pickWord} for this week!</div>;
    } else {
        let missedMessage: JSX.Element | null = null;
        let warningMessage: JSX.Element | null = null;

        const picksNeeded = picksDue - picksMade;

        if (picksCanMake < picksNeeded) {
            const pickWord: string = (picksCanNotMake === 1) ? "pick" : "picks";
            missedMessage = <div className="danger-red-div"><i className="fa fa-exclamation-triangle danger-red" aria-hidden="true"/> Whoops, looks like you missed {picksCanNotMake} {pickWord} this week!</div>;
        }

        if (picksNeeded > 0) {
            const pickWord: string = (picksCanMake === 1) ? "pick" : "picks";
            warningMessage = <div className="caution-yellow-div"><i className="fa fa-exclamation-triangle caution-yellow" aria-hidden="true"/> You still have {picksNeeded} {pickWord} to make this week!</div>;
        }

        return <span>{missedMessage}{warningMessage}</span>;
    }
}

const standingsExplaination = (props: ILeagueProps): JSX.Element => {
    if (props.leagueState.leagueRuleSet && props.leagueState.leagueRuleSet.elimination) {
        return (<span>
            <p>These are the current league standings. Eliminated players are highlighted in red [<span className="eliminated">&nbsp;&nbsp;&nbsp;&nbsp;</span>].</p>
        </span>);
    } else {
        return (<span>
            <p>These are the current league standings.</p>
        </span>);
    }
}

const recapSection = (props: ILeagueProps): JSX.Element => {
    if (props.leagueState.leagueRuleSet && props.leagueState.leagueRuleSet.elimination) {
        return (<span>
            <LeagueWeekEliminationSummary titleSuffix="So Far" leagueWeekSummary={props.leagueState.thisWeekSummary} />
            <LeagueWeekEliminationSummary titleSuffix="Recap" leagueWeekSummary={props.leagueState.lastWeekSummary} />
        </span>);
    } else {
        return (<span>
            <LeagueWeekPickEmSummary
                titleSuffix="So Far"
                leagueWeekSummary={props.leagueState.thisWeekSummary}
                onSetWeekSummaryVisible={props.onSetThisWeekSummaryVisible}
                userId={props.userState.userId}
                />
            <LeagueWeekPickEmSummary
                titleSuffix="Recap"
                leagueWeekSummary={props.leagueState.lastWeekSummary}
                onSetWeekSummaryVisible={props.onSetLastWeekSummaryVisible}
                userId={props.userState.userId}
                />
        </span>);
    }
}

const myPicksSection = (navigate: NavigateFunction, props: ILeagueProps): JSX.Element | null => {
    if (!props.leagueState.leagueView) {
        return null;
    }
    if (props.userState.userId === -1 && props.leagueState.leagueView.isPublic && !props.leagueState.leagueView.locked) {
        return (
            <span>
                <p>Click here <span className="glyphicon glyphicon-arrow-down" aria-hidden="true"/> to log in or create a profile... then join this league and make some picks!</p>
                <LoginOrCreateProfile />
            </span>
        );
    } else if (props.userState.userId === -1) {
        return (
            <span>
                <p>This league is not accepting new members. If you log in, you can create your own league!</p>
                <LoginOrCreateProfile />
            </span>
        );
    } else if (userIsLeagueWinner(props)) {        
        return (
            <span>
                <h4>You Won!</h4>
                <div className="alert alert-success" role="alert">
                    Excellent job. You have crushed the competition, and taken your place in history. You may feel free to request that all others bow down!
                        </div>
                <p>Click here <span className="glyphicon glyphicon-arrow-down" aria-hidden="true"/> to open this season and view your previous picks.</p>
                <button
                    type="submit"
                    className="btn btn-pickem-blue"
                    onClick={() => {
                        routeToLeagueSeason(navigate, props.leagueState.leagueView?.leagueId, props.leagueState.leagueMember?.leagueMemberId);
                    } }
                    ><i className="fa fa-eye" aria-hidden="true"/> View Picks</button>
            </span>
        );
    } else if (userIsInLeague(props)) {
        if (props.leagueState.leagueRuleSet?.elimination && props.leagueState.leagueMember?.eliminated === true) {
            return (
                <span>
                    <h4>You Have Been Eliminated</h4>
                    <div className="alert alert-danger" role="alert">
                        Unfortunately you have been eliminated. You cannot make further picks.
                        </div>
                    <p>Click here <span className="glyphicon glyphicon-arrow-down" aria-hidden="true"/> to open this season and view your previous picks.</p>
                    <button
                        type="submit"
                        className="btn btn-pickem-blue"
                        onClick={() => {
                            routeToLeagueSeason(navigate, props.leagueState.leagueView?.leagueId, props.leagueState.leagueMember?.leagueMemberId);
                        } }
                        ><i className="fa fa-eye" aria-hidden="true"/> View Picks</button>
                </span>
            );

        } else {
            return (
                <span>
                    {myPicksSectionStatus(props)}
                    <p>Click here <span className="glyphicon glyphicon-arrow-down" aria-hidden="true"/> to open this season and make or view your picks.</p>
                    <button
                        type="submit"
                        className="btn btn-pickem-blue"
                        onClick={() => {
                            routeToLeagueSeason(navigate, props.leagueState.leagueView?.leagueId, props.leagueState.leagueMember?.leagueMemberId);
                        } }
                        ><i className="fa fa-check-square-o" aria-hidden="true"/> Make and View Picks</button>
                </span>
            );
        }
    } else if (!props.leagueState.leagueView.isPublic || props.leagueState.leagueView.locked) {
        return (
            <span>
                <p>You are not a member of this league, and the league is not accepting new members.</p>
            </span>
        );
    } else {
        return (
            <span>
                <p>Click here <span className="glyphicon glyphicon-arrow-down" aria-hidden="true"/> to join this league!</p>
                <button
                    type="submit"
                    className="btn btn-pickem-blue"
                    onClick={() => {
                        if (props.leagueState.leagueView) {
                            const leagueId = props.leagueState.leagueView.leagueId;
                            DataService.joinPublicLeague(
                                props.authenticationToken,
                                leagueId,
                                props.dispatch
                            ).then((response: IAjaxResponse) => {
                                if (response.success) {
                                    DataService.getLeagueState(
                                        props.authenticationToken,
                                        leagueId,
                                        null,
                                        props.dispatch);
                                }
                            });
                        }
                    } }
                    >Join League</button>
            </span>
        );
    }
}

const teamRow = (props: ILeagueProps, leagueMember: ILeagueMember, userId: number, place: number) => {
    let backgroundColor: string;
    let teamName: JSX.Element;
    if (leagueMember.userId === userId) {
        backgroundColor = '#DDD';
        teamName = <b>{leagueMember.teamName}</b>;
    } else {
        backgroundColor = '#FFF';
        teamName = <span>{leagueMember.teamName}</span>;
    }
    let elimClass = (eliminated: boolean) => {
        return eliminated ? "eliminated" : "";
    };

    return <tr key={leagueMember.leagueMemberId}>
        <td style={{ backgroundColor }}>{(place).toString()}</td>
        <td style={{ backgroundColor }} className={elimClass(leagueMember.eliminated && place > 1)}>
            <a href={'#/leagueMember?leagueMemberId=' + leagueMember.leagueMemberId.toString()}><i className="fa fa-search" aria-hidden="true"/></a>
            &nbsp;
            {teamName}
            <HidableImage
                className=""
                src="/img/winner.png"
                height={54}
                width={96}
                visible={props.leagueState.leagueView?.complete === true && place === 1} />
        </td>
        <td style={{ backgroundColor }}>{leagueMember.points}</td>
        <td style={{ backgroundColor }}>{percentDisplay(leagueMember.percent)}</td>
        <td style={{ backgroundColor }}>{leagueMember.avm}</td>
        <td style={{ backgroundColor }}>{leagueMember.alm}</td>
    </tr>
};

const teamMapper = (props: ILeagueProps): Array<JSX.Element> => {
    if (props.leagueState.leagueMembers.length === 0) {
        return [<tr key="row1"><td colSpan={6}>No teams. Invite some members!</td></tr>]
    } 

    let place: number = 0;
    let renderedUser: boolean = false;
    const userId = props.userState.userId;

    const visible = props.leagueState.standings?.visible || false;
    const members = props.leagueState.leagueMembers;
    const visibleMembers = members.filter((m, i) => i < visible);

    const rows = visibleMembers.map(
        (leagueMember: ILeagueMember) => {
            if (leagueMember.userId === userId) {
                renderedUser = true;
            }
            return teamRow(props, leagueMember, userId, ++place);
        }           
    );

    if (visibleMembers.length < members.length) {
        if (!renderedUser) {
            const membersIndex = members.findIndex(m => m.userId === userId);
            if (membersIndex > -1) {
                rows.push(<td colSpan={5}>&nbsp;&nbsp;&nbsp;&nbsp;...</td>);
                rows.push(teamRow(props, members[membersIndex], userId, membersIndex + 1));
            }
        }

        rows.push(<tr key="loaderRow">
            <td colSpan={6}>
                <button
                    type="submit"
                    className="btn btn-pickem-blue"
                    onClick={() => {
                        props.onSetStandingsVisible(visibleMembers.length + 10);
                    } }
                    ><i className="fa fa-eye" aria-hidden="true"/> Show More</button>
                &nbsp;&nbsp;
                <button
                    type="submit"
                    className="btn btn-pickem-blue"
                    onClick={() => {
                        props.onSetStandingsVisible(members.length);
                    } }
                    ><i className="fa fa-eye" aria-hidden="true"/> Show All</button>
            </td>
        </tr>);
    }

    return rows;
}

const pickEmStandings = (props: ILeagueProps): JSX.Element => {
    return (
        <div>
            <table className="table table-bordered table-hover shadow">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Team</th>
                        <th>Pts</th>
                        <th>%</th>
                        <th>AVM*</th>
                        <th>ALM*</th>
                    </tr>
                </thead>
                <tbody>
                    {teamMapper(props)}
                </tbody>
            </table>

            * <b>AVM</b>: Average Victory Margin<br />
            * <b>ALM</b>: Average Loss Margin<br />
        </div>
    );
}

const renderGridPick = (leagueMemberId: number, grid: Array<IEliminationGrid>, week: number): JSX.Element[] | null => {
    const row = _.find(grid, g => g.leagueMemberId === leagueMemberId);
    if (row === null || _.isUndefined(row) || !row.weeks || row.weeks.length < week || row.weeks[week] === null) {
        return null;
    }

    const picks = (row.weeks[week] || "").split(';');
    const items: JSX.Element[] = [];

    picks.forEach((pick, index) => {

        const parts = (pick || "").split('|');
        if (parts.length === 2) {

            const className: string = parts[1] === "0"
                ? "grid-in-progress"
                : parts[1] === "1"
                    ? "grid-eliminated"
                    : "grid-success";


            const iconClassName: string = parts[1] === "0"
                ? "fa fa-square-o"
                : parts[1] === "1"
                    ? "fa fa-close"
                    : "fa fa-check-square-o";

            items.push(<div key={index} className={className}><i className={iconClassName} aria-hidden="true"/>&nbsp;{parts[0]}</div>);
        }

    });

    return items;
}

const getVisibleWeeks = (grid: Array<IEliminationGrid>) => {
    const visibleWeeks: number[] = [];
    let lastWeekPushed: boolean = false;
    for (let i = 0; i < 17; i++) {
        if (grid.some(g => g.weeks[i] !== null)) {
            visibleWeeks.push(i);
            if (i === 16) {
                lastWeekPushed = true;
            }
        }
    }

    const width = $(window).width();

    // target number of columns to show in default view
    const colTarget = Math.floor(Math.min(Math.max(((width - 450) / 100) + 2, 2), 17));

    if (visibleWeeks.length === 0) {

        visibleWeeks.push(0);
    }

    if (visibleWeeks.length < colTarget && !lastWeekPushed) {

        const lastVal = visibleWeeks[visibleWeeks.length - 1];

        for (let i = 0; i < colTarget - visibleWeeks.length; i++) {
            visibleWeeks.push(lastVal + 1 + i);
        }
    }

    while (visibleWeeks.length > colTarget) {
        // remove beginning week
        visibleWeeks.splice(0, 1);
    }

    return visibleWeeks;
}

const cellMapper = (visibleWeeks: number[], leagueMemberId: number, eliminationGrid: Array<IEliminationGrid>): Array<JSX.Element> => {
    return visibleWeeks.map(w => {
        return (<td key={w}>{renderGridPick(leagueMemberId, eliminationGrid, w)}</td>);
    });
}

const gridCountForMember = (lm: ILeagueMember, grid: Array<IEliminationGrid>, currentWeekIndex: number): { count: number, team: string, status: string } => {
    const row = _.find(grid, g => g.leagueMemberId === lm.leagueMemberId);
    if (row === null || _.isUndefined(row) || !row.weeks) {
        return { count: 0, team: '', status: ''};
    }
    const count = row.weeks.filter(w => w !== null).length;
    const cell = row.weeks[currentWeekIndex];
    let team: string = '';
    let status: string = '';
    if (!_.isUndefined(cell) && cell !== null) {
        const parts = cell.split('|');
        if (parts.length === 2) {
            team = parts[0];
            status = parts[1] === "1" ? "-1" : parts[1];
        }
    }
    return { count, team, status };
}

const gridSort = (a: ILeagueMember, b: ILeagueMember, grid: Array<IEliminationGrid>, currentWeekIndex: number): number => {
    const {count: aCount, team: aTeam, status: aStatus} = gridCountForMember(a, grid, currentWeekIndex);
    const {count: bCount, team: bTeam, status: bStatus} = gridCountForMember(b, grid, currentWeekIndex);
    
    if (a.points > b.points) {
        return -1;
    }
    if (a.points < b.points) {
        return 1;
    }

    if (a.eliminated && !b.eliminated) {
        return 1;
    }
    if (!a.eliminated && b.eliminated) {
        return -1;
    }

    if (aCount > bCount) {
        return -1;
    }
    if (aCount < bCount) {
        return 1;
    }

    if (aCount > bCount) {
        return -1;
    }
    if (aCount < bCount) {
        return 1;
    }

    if (aStatus > bStatus) {
        return -1;
    }
    if (aStatus < bStatus) {
        return 1;
    }

    if (aTeam < bTeam) {
        return -1;
    }
    if (aTeam > bTeam) {
        return 1;
    }

    if (a.teamName.toLowerCase() < b.teamName.toLowerCase()) {
        return -1;
    }
    if (a.teamName.toLowerCase() > b.teamName.toLowerCase()) {
        return 1;
    }
    return 0;
};

const gridMapper = (eliminationGrid: Array<IEliminationGrid>, visibleWeeks: number[], props: ILeagueProps): Array<JSX.Element> | null => {
    if (!props.leagueState.thisWeekSummary) {
        return null;
    }
    // HACK: this is only good for Season 9. 2024.
    // I think the number here is the weekId of week 1, current season (was 22 since season 2, so maybe it does not matter)
    const currentWeekIndex = props.leagueState.thisWeekSummary.weekId - 174; // 151 (2023) // 107 (2021)
    if (props.leagueState.leagueMembers.length === 0) {
        return [<tr key="row1"><td colSpan={7}>No teams. Invite some members!</td></tr>]
    } else {
        let place: number = 0;
        let elimClass = (eliminated: boolean) => {
            return eliminated ? "eliminated" : "";
        };        
        return props.leagueState.leagueMembers.sort((a, b) => gridSort(a, b, eliminationGrid, currentWeekIndex)).map(
            (leagueMember: ILeagueMember) =>
                <tr key={leagueMember.leagueMemberId}>
                    <td>{(++place).toString()}</td>
                    <td className={elimClass(leagueMember.eliminated && place > 1 && leagueMember.leagueMemberId !== 505)}>
                        <a href={'#/leagueMember?leagueMemberId=' + leagueMember.leagueMemberId.toString()}><i className="fa fa-search" aria-hidden="true"/></a>
                        &nbsp;
                            {leagueMember.teamName}
                        <HidableImage
                            className=""
                            src="/img/winner.png"
                            height={54}
                            width={96}
                            visible={(props.leagueState.leagueView?.complete === true && place === 1) || leagueMember.leagueMemberId === 505} />
                    </td>
                    <td className={isSmall ? "hidden" : ""}>{leagueMember.points}</td>
                    {cellMapper(visibleWeeks, leagueMember.leagueMemberId, eliminationGrid)}
                </tr>
        )
    }
}

const headerMapper = (visibleWeeks: number[]): Array<JSX.Element>  => {
    return visibleWeeks.map(w => {
        return (<th key={w}>W{w+1}</th>);
    });
}

const elimStandings = (props: ILeagueProps): JSX.Element => {
    const eliminationGrid = props.leagueState.eliminationGrid;
    const visibleWeeks = getVisibleWeeks(eliminationGrid);
    return (
        <div>
            <table className="table table-bordered table-hover shadow">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Team</th>
                        <th className={isSmall ? "hidden" : ""}>Wins</th>
                        {headerMapper(visibleWeeks)}
                    </tr>
                </thead>
                <tbody>
                    {gridMapper(eliminationGrid, visibleWeeks, props)}
                </tbody>
            </table>

            * <b>AVM</b>: Average Victory Margin<br />
            * <b>ALM</b>: Average Loss Margin<br />
        </div>
    );
}

const League = (props: ILeagueProps) => {

    const navigate = useNavigate();

    if (!_.isObject(props.leagueState) || !_.isObject(props.userState)) {
        // seemingly getting here with no leagueState...
        // returning a blank template until the state arrives fixes this.
        return <div/>;
    }
    
    if (!props.leagueState.leagueView) {
        return <div/>;
    }

    const doGridMapper = (props.leagueState.leagueView.leagueRuleSetId === 3 || props.leagueState.leagueView.leagueRuleSetId === 4)
        && props.leagueState.eliminationGrid;

    if (props.leagueState.initErrorMessage && props.leagueState.initErrorMessage.length > 0) {

        if (props.userState.userId > 0) {
            return (
                <div>
                    <h2 className="tight-panel">League</h2>
                    <div className="content-panel">
                        <MessagePanel className="bg-danger" message={props.leagueState.initErrorMessage} />
                    </div>
                </div>);
        } else {
            return (
                <div>
                    <h2 className="tight-panel">League</h2>
                    <div className="content-panel">
                        <MessagePanel className="bg-danger" message={props.leagueState.initErrorMessage} />
                        <MessagePanel className="bg-info" message='Do you need to log in?'/>
                        <LoginOrCreateProfile />
                    </div>
                </div>);
        }
    }

    const myPicksSectionTitle =
        props.leagueState.leagueMember != null
            ? `My Picks - ${props.leagueState.leagueMember.teamName}`
            : "My Picks";

    return (
        <div>
            <h2 className="tight-panel">
                <img src="/img/new/leagues-32x32.png" className="icon32" alt=""/> {props.leagueState.leagueView.leagueName}
            </h2>
            <div className="content-panel">
                <LeagueTable leagueView={props.leagueState.leagueView} leagueRuleSet={props.leagueState.leagueRuleSet} />
                {backToLeaguesButton(navigate, props)} {inviteButton(navigate, props)} {lockButton(props)} {manageButton(navigate, props)}
                {props.leagueState.leagueView.leagueId === 646000 && <LeagueShareLink top="-3px" altImage={true} guid="e8d8e76f-42a9-4c48-b10e-07dd0bc4de2a" message="Pick'Em Leagues - 2022 Free Elimination League (Win $250)" />}
                {props.leagueState.leagueView.leagueId === 646000 && <LeagueShareLink top="-3px" altImage={false} guid="e8d8e76f-42a9-4c48-b10e-07dd0bc4de2a" message="Pick'Em Leagues - 2022 Free Elimination League (Win $250)" />}
                {props.leagueState.leagueView.leagueId === 647000 && <LeagueShareLink top="-3px" altImage={true} guid="015de98a-ae39-4055-9ed3-23b5efea3966" message="Pick'Em Leagues - 2022 Pick'Em League (Win $250)" />}
                {props.leagueState.leagueView.leagueId === 647000 && <LeagueShareLink top="-3px" altImage={false} guid="015de98a-ae39-4055-9ed3-23b5efea3966" message="Pick'Em Leagues - 2022 Pick'Em League (Win $250)" />}
            </div>
            <h2 className="tight-panel"><img src="/img/new/picks-32x32.png" className="icon32" alt=""/> {myPicksSectionTitle}</h2>
            <div className="content-panel">
                {myPicksSection(navigate, props)}
            </div>
            {recapSection(props)}
            <h2 className="tight-panel"><img src="/img/new/check-list-32x32.png" className="icon32" alt=""/> Standings</h2>
            <div className="content-panel">
                {standingsExplaination(props)}
            </div>
            {doGridMapper ? elimStandings(props) : pickEmStandings(props)}
         </div>
    );
}

function mapStateToProps(state: IState) {
    return {
        leagueState: state.leagueState,
        userState: state.userState,
        authenticationToken: state.authenticationToken
    };
}

const mapDispatchToProps = (dispatch: (action: IAction) => void) => {
    return {
        dispatch,
        onSetThisWeekSummaryVisible: (visible: number) => {
            dispatch(setThisWeekSummaryVisible(visible));
        },
        onSetLastWeekSummaryVisible: (visible: number) => {
            dispatch(setLastWeekSummaryVisible(visible));
        },
        onSetStandingsVisible: (visible: number) => {
            dispatch(setStandingsVisible(visible));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(League as any);
