import $ from "jquery";
import _ from "lodash";
import * as Cookies from "js-cookie";

import { IAjaxResponse } from "../models/interfaces";
import { ILoginProps } from "../components/pages/auth/Login";
import { IPasswordResetProps } from "../components/pages/auth/PasswordReset";
import {NavigateFunction} from "react-router";

export interface ILoginRequest {
    email: string;
    password: string;
    code?: string;
    requestReset?: boolean;
}

export interface IFbLoginRequest extends ILoginRequest {
    facebookId: string;
    name: string;
    fName: string;
    lName: string;
    accessToken: string;
    expiresIn: number;
    signedRequest: string;
    graphDomain: string;
    dataAccessExpirationTime: number;
    status: string;
}

export interface ICreateProfileRequest extends ILoginRequest {
    userName: string;
    timeZone: string;
}

export interface IUpdateProfileRequest extends ICreateProfileRequest {
    token: string;
    reminderOptOut: boolean;
    crossLeaguePicking: boolean;
}

export interface ILoginResponse {
    success: boolean;
    cookie: string;
    message: string;
}

export interface IPasswordResetRequest {
    email: string;
    resetCode: string;
    newPassword: string;    
}

export class AuthorizationService {
    static cookieName: string = "Z2343A2990X";
    static cookieDomain: string = ".pickemleagues.com";
    static facebookLogOutInProgress: boolean = false;
    
    static appearsAuthenticated() {
        const loginCookie = Cookies.get(this.cookieName);
        return !_.isUndefined(loginCookie);
    }

    static currentCookie(): string {
        return Cookies.get(this.cookieName);
    }

    static createProfile(profile: ICreateProfileRequest, onSuccess: (token: string) => void, onFailure: (message: string) => void): void {
        $.ajax({
            type: "POST",
            url: "/api/login/createProfile/",
            data: JSON.stringify(profile),
            success: (response: ILoginResponse) => {
                if (response.success) {
                    onSuccess(response.cookie);
                } else {
                    onFailure(response.message);
                }
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                onFailure(`HTTP Error ${jqXHR.status} (${jqXHR.statusText})`);
            },
            dataType: "json",
            contentType: "application/json",
        });
    }

    static updateProfile(profile: IUpdateProfileRequest, onSuccess: () => void, onFailure: (message: string) => void): void {
        $.ajax({
            type: "POST",
            url: "/api/login/updateProfile/",
            data: JSON.stringify(profile),
            success: (response: IAjaxResponse) => {
                if (response.success) {
                    onSuccess();
                } else {
                    onFailure(response.message);
                }
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                onFailure(`HTTP Error ${jqXHR.status} (${jqXHR.statusText})`);
            },
            dataType: "json",
            contentType: "application/json",
        });
    }

    static login(navigate: NavigateFunction, props: ILoginProps, credentials: ILoginRequest, onSuccess: (navigate: NavigateFunction, props: ILoginProps, token: string) => void, onFailure: (props: ILoginProps, message: string) => void): void {
        $.ajax({
            type: "POST",
            url: "/api/login/dologin/",
            data: JSON.stringify(credentials),
            success: (response: ILoginResponse) => {
                if (response.success) {
                    onSuccess(navigate, props, response.cookie);
                } else {
                    onFailure(props, response.message);
                }
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                onFailure(props, `HTTP Error ${jqXHR.status} (${jqXHR.statusText})`);
            },
            dataType: "json",
            contentType: "application/json",
        });
    }

    static resetPassword(navigate: NavigateFunction, props: IPasswordResetProps, credentials: IPasswordResetRequest, onSuccess: (navigate: NavigateFunction, props: IPasswordResetProps, token: string) => void, onFailure: (props: IPasswordResetProps, message: string) => void): void {
        $.ajax({
            type: "POST",
            url: "/api/login/resetPassword/",
            data: JSON.stringify(credentials),
            success: (response: ILoginResponse) => {
                if (response.success) {
                    onSuccess(navigate, props, response.cookie);
                } else {
                    onFailure(props, response.message);
                }
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                onFailure(props, `HTTP Error ${jqXHR.status} (${jqXHR.statusText})`);
            },
            dataType: "json",
            contentType: "application/json",
        });
    }

    static logout(callback: () => void) {

        const onFbDone = () => {
            Cookies.remove(this.cookieName, { domain: this.cookieDomain });
            callback();
        };

        if (!FB) {
            return;
        }
        
        FB.getLoginStatus(function (response: any) {
            if (response.status === 'connected') {
                // The user is logged in and has authenticated your
                // app, and response.authResponse supplies
                // the user's ID, a valid access token, a signed
                // request, and the time the access token 
                // and signed request each expire.
                /*FB.logout(function (response: any) {
                    onFbDone();
                });*/

                const handleSessionResponse = (response: any) => {

                    if (!response.session) {
                        onFbDone();
                        return;
                    }

                    FB.logout(handleSessionResponse);
                }

                FB.logout(handleSessionResponse);

            } else if (response.status === 'not_authorized') {
                // The user hasn't authorized your application.  They
                // must click the Login button, or you must call FB.login
                // in response to a user gesture, to launch a login dialog.
                onFbDone();
            } else {
                // The user isn't logged in to Facebook. You can launch a
                // login dialog with a user gesture, but the user may have
                // to log in to Facebook before authorizing your application.
                onFbDone();
            }
        }, true); // the second parameter of true forces a round trip to Facebook, avoiding cached data issues
    }
}
