import * as React from "react";

import ResponsiveImage from "../controls/ResponsiveImage";

const HowItWorksPickEm = () => {

    return (<div>
        <h3 className="tight-panel"><img src="/img/new/picks-32x32.png" className="icon32" alt=""/> Make Your Picks (Pick'Em)</h3>
        <div className="content-panel">
            <p>For each Pick'Em league, click through to make your picks. Games lock 5 minutes before the official start time. You must pick to get points.</p>
            <ResponsiveImage fileName="picks.png" />
            <p>The only season available right now is NFL 2024. You pick every game, every week, straight up.</p>
            <p>You score points when your teams win. SICK.</p>
        </div>

        <h3 className="tight-panel"><img src="/img/new/view-32x32.png" className="icon32" alt=""/> View the Results (Pick'Em)</h3>
        <div className="content-panel">
            <p>Results are populated in real time. (OK, it's delayed a minute or so...)</p>
            <ResponsiveImage fileName="results.png" />
        </div>

        <h3 className="tight-panel"><img src="/img/new/check-list-32x32.png" className="icon32" alt=""/> Check the Standings (Pick'Em)</h3>
        <div className="content-panel">
            <p>Check out those standing and behold the glory. You won! Or not.</p>
            <ResponsiveImage fileName="standings.png" />
        </div>
    </div>);
};

export default HowItWorksPickEm;
