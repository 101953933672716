import * as React from "react";
import { connect } from "react-redux";

import ResponsiveImage from "../../controls/ResponsiveImage";

import LoginOrCreateProfile from "../../widgets/LoginOrCreateProfile";
import HowItWorksElimination from "../../widgets/HowItWorksElimination";
import HowItWorksPickEm from "../../widgets/HowItWorksPickEm";
import LeagueShareLink from "../../controls/LeagueShareLink";

import { IState } from "../../../state/state";
import { IAction } from "../../../actions";

export interface IHomeProps {
    dispatch: (action: IAction) => void,
    authenticationToken: string,
}

const loggedInSection = (props: IHomeProps) => {
    if (props && props.authenticationToken != null) {
        return (
            <span>
                <p>No worries here. Looks like you're already logged in!</p>
            </span>
        );
    } else {
        return (
            <span>
                <p>Just create a profile, or log in if you already have one.</p>
                <LoginOrCreateProfile />
            </span>
        );
    }
}

const freeRollLinks = (props: IHomeProps): JSX.Element => {
    return (<span/>);
    /*return (
        <table>
            <tbody>
                <tr>
                    <td className="plain-table-padding">Free Public Elimination Tournament - Win $250</td>
                    <td className="plain-table-padding"><span className="glyphicon glyphicon-arrow-right" aria-hidden="true"/></td>
                    <td className="plain-table-padding">
                        <a className="btn btn-pickem-blue" href="/#/inviteVerify?g=d38d1147-9c96-4380-b32e-94f8b2436514"><i className="fa fa-rocket" aria-hidden="true"/> Click Here</a>
                    </td>
                </tr>
                <tr>
                    <td className="plain-table-padding">Invite Friends!</td>
                    <td className="plain-table-padding"><span className="glyphicon glyphicon-arrow-right" aria-hidden="true"/></td>
                    <td className="plain-table-padding">
                        <LeagueShareLink top="0px" altImage={true} guid="793f4227-0294-4378-a737-1f3d6c8a327c" message="Pick'Em Leagues - 2022 Free Elimination League (Win $250)" />
                    </td>
                </tr>                
                <tr>
                    <td className="plain-table-padding">Free Public Pick'Em Tournament - Win $250</td>
                    <td className="plain-table-padding"><span className="glyphicon glyphicon-arrow-right" aria-hidden="true"/></td>
                    <td className="plain-table-padding">
                        <a className="btn btn-pickem-blue" href="/#/inviteVerify?g=700e2f41-a273-42f9-9565-6c585076929a"><i className="fa fa-rocket" aria-hidden="true"/> Click Here</a>
                    </td>
                </tr>
                <tr>
                    <td className="plain-table-padding">Invite Friends!</td>
                    <td className="plain-table-padding"><span className="glyphicon glyphicon-arrow-right" aria-hidden="true"/></td>
                    <td className="plain-table-padding">
                        <LeagueShareLink top="0px" altImage={true} guid="a693ef04-781d-49e2-a0c6-dc1d691fb759" message="Pick'Em Leagues - 2022 Free Pick'Em League (Win $250)" />
                    </td>
                </tr>
            </tbody>
        </table>
    );*/
}

const Home = (props: IHomeProps) => {

    return (
        <div>        
            <div className="jumbotron">
            </div>
            
            <h2 className="tight-panel"><img src="/img/new/works-32x32.png" className="icon32" alt=""/> How It Works</h2>
            <div className="content-panel">
                <ul>
                    <li>It's FREE.</li>
                    <li>You pick who wins. Nice and simple, and quick.</li>
                    <li>Pick correctly and get points! Pick incorrectly and... no points.</li>
                    <li>There are four types of leagues: Pick'Em, Pick'Em vs Spread, Elimination, and Touchdown Pick'Em.</li>
                    <li><b>Pick'Em:</b> players pick every game, every week, through the whole season.</li>
                    <li><b>Pick'Em vs Spread:</b> just like Pick'Em, but versus the spread.</li>
                    <li><b>Touchdown Pick'Em:</b> just like Pick'Em, but also pick a winning margin for extra points.</li>
                    <li><b>Elimination:</b> players pick ONE game, every week. One wrong pick and they are eliminated!.</li>
                    <li>READ ON! JOIN UP! VICTORY AWAITS!</li>                    
                </ul>
                {freeRollLinks(props)}
            </div>

            <h3 className="tight-panel"><img src="/img/new/profile-32x32.png" className="icon32" alt=""/> You Need A Profile</h3>
            <div className="content-panel">
                {loggedInSection(props)}
            </div>

            <h3 className="tight-panel"><img src="/img/new/leagues-32x32.png" className="icon32" alt=""/> Create or Join Leagues</h3>
            <div className="content-panel">
                <p>On the My Leagues page, you can create or join a league or leagues.</p>
                <p>Or, if you received an invite email, clicking the link in the email takes care of all that.</p>
                <p>There are two league types - "Pick'Em", and "Elimination".</p>
                <ResponsiveImage fileName="leagues.png" />
            </div>
        
            <h3 className="tight-panel"><img src="/img/new/invite-32x32.png" className="icon32" alt=""/> Invite Friends</h3>
            <div className="content-panel">
                <p>The more, the merrier! Leagues have no size limit.</p>
                <p>Note: you can only invite friends to leagues where you are the owner.</p>
                <ResponsiveImage fileName="invite.png" />
            </div>
 
            <HowItWorksPickEm />

            <HowItWorksElimination />

            <h3 className="tight-panel"><img src="/img/new/academic-cap-32x32.png" className="icon32" alt=""/> That's It!</h3>
            <div className="content-panel">              
                <p>We're working on a ton of other features, but that's where we are today.</p>
                <p>Have ideas? Send 'em over! <a href="mailto:big-ideas@pickemleagues.com">big-ideas@pickemleagues.com</a></p>
                <p><a href="/#/privacy">Privacy Policy</a></p>
                <p><a href="/#/tos">Terms of Service</a></p>
            </div>
        </div>
    );
}

function mapStateToProps(state: IState) {
    return {
        authenticationToken: state.authenticationToken
    };
}

export default connect(mapStateToProps)(Home as any);
