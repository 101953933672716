import * as React from "react";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router";
import _ from "lodash";

import MessagePanel from "../../controls/MessagePanel";

import LoginOrCreateProfile from "../../widgets/LoginOrCreateProfile";
import LeagueTable from "../../widgets/LeagueTable";
import HowItWorksElimination from "../../widgets/HowItWorksElimination";
import HowItWorksPickEm from "../../widgets/HowItWorksPickEm";

import { routeToLeague } from "../../../services/routingService";
import { UtilService } from "../../../services/utilService";

import { IState, IInviteVerifyState } from "../../../state/state";
import { IAction } from "../../../actions";

export interface IInviteVerifyProps {
    dispatch: (action: IAction) => void,
    invitationCode: string,
    inviteVerifyState: IInviteVerifyState
}

const page = (navigate: NavigateFunction, props: IInviteVerifyProps) => {
    if (props.inviteVerifyState.authRequired && props.inviteVerifyState.leagueView?.locked) {
        return (
            <div>
                <h2 className="tight-panel"><img src="/img/new/lock-32x32.png" className="icon32" alt=""/> Your
                    invitation has been verified, but...</h2>
                <div className="content-panel">
                    Your invitation has been verified, but this league is locked. No further additions are allowed.
                </div>
            </div>);
    } else if (props.inviteVerifyState.authRequired) {
        return (
            <div>
                <h2 className="tight-panel"><img src="/img/new/picks-32x32.png" className="icon32" alt=""/> You may join
                    this league!</h2>
                <div className="content-panel">
                    <p>Your invitation has been verified. Please log in or create a profile to continue.</p>
                    <LoginOrCreateProfile/>
                </div>
            </div>);
    } else {
        UtilService.fbEventTrack('Signup', {leagueId: props.inviteVerifyState.leagueView?.leagueId || 'null'});
        return <div>
            <h2 className="tight-panel"><img src="/img/new/picks-32x32.png" className="icon32" alt=""/> Your invitation
                has been verified!</h2>
            <div className="content-panel">
                <p>You have been added to the league.</p>
                <table>
                    <tbody>
                    <tr>
                        <td className="plain-table-padding">Click here to check out your new league!</td>
                        <td className="plain-table-padding"><span className="glyphicon glyphicon-arrow-right"
                                                                  aria-hidden="true"/></td>
                        <td className="plain-table-padding">
                            <button
                                type="submit"
                                className="btn btn-pickem-blue"
                                onClick={() => {
                                    if (props.inviteVerifyState.leagueView) {
                                        routeToLeague(navigate, props.inviteVerifyState.leagueView.leagueId, null)
                                    }
                                }}>
                                <i className="fa fa-users" aria-hidden="true"/> Go To
                                "{props.inviteVerifyState.leagueView?.leagueName}"
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>;
    }
}, howItWorks = (props: IInviteVerifyProps): JSX.Element => {
    if (props.inviteVerifyState.leagueRuleSet?.elimination === true) {
        return <HowItWorksElimination/>;
    } else {
        return <HowItWorksPickEm/>;
    }
}, InviteVerify = (props: IInviteVerifyProps) => {

    const navigate = useNavigate();
    
    if (!_.isObject(props.inviteVerifyState)) {
        // seemingly getting here with no inviteVerifyState...
        // returning a blank template until the state arrives fixes this.
        return <div/>;
    }

    if (props.inviteVerifyState.initErrorMessage && props.inviteVerifyState.initErrorMessage.length > 0) {
        return (
            <div>
                <h2 className="tight-panel">Join League - Error</h2>
                <div className="content-panel">
                    <MessagePanel className="bg-danger" message={props.inviteVerifyState.initErrorMessage}/>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="jumbotron">
            </div>

            <h2 className="tight-panel"><img src="/img/new/invite-32x32.png" className="icon32"
                                             alt=""/> {props.inviteVerifyState.leagueView?.leagueName} - Invite Verified
            </h2>
            <div className="content-panel">
                <LeagueTable leagueView={props.inviteVerifyState.leagueView}
                             leagueRuleSet={props.inviteVerifyState.leagueRuleSet}/>
            </div>
            {page(navigate, props)}
            <h2><img src="/img/new/works-32x32.png" className="icon32" alt=""/> How It Works</h2>
            {howItWorks(props)}
        </div>
    );
};


function mapStateToProps(state: IState) {
    return {
        invitationCode: state.invitationCode,
        inviteVerifyState: state.inviteVerifyState
    };
}

export default connect(mapStateToProps)(InviteVerify as any);
