import * as React from "react";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router";
import $ from "jquery";

import FacebookLoginButton from "../../controls/FacebookLoginButton";
import MessagePanel from "../../controls/MessagePanel";
import TimeZoneSelector from "../../controls/TimeZoneSelector";

import { AuthorizationService, ICreateProfileRequest } from "../../../services/authorizationService";
import { DataService } from "../../../services/dataService";
import { routeToHome, routeToLogin } from "../../../services/routingService";

import { IState } from "../../../state/state";
import { IAction, login, loginFailed, blockUi } from "../../../actions";
import { UtilService } from "../../../services/utilService";

export interface CreateProfileProps {
    dispatch: (action: IAction) => void,
    invitationCode: string,
    loginErrorMessage: string
}

const doCreateProfile = (navigate: NavigateFunction, e: React.MouseEvent<HTMLButtonElement>, props: CreateProfileProps): void => {
    e.preventDefault();
    const createProfileRequest: ICreateProfileRequest = {
        userName: $('#userNameCreateProfileInput').val(),
        email: $('#emailCreateProfileInput').val(),
        password: $('#passwordCreateProfileInput').val(),
        timeZone: $('#timeZoneSelect').val(),
        code: props.invitationCode
    };
    const onLoginSuccess = (token: string): void => {        
        UtilService.fbEventTrack('CreateProfileSuccess', {invitationCode: props?.invitationCode});
        props.dispatch(blockUi(false, ""));
        props.dispatch(login(token));
        DataService.getUserState(token, true, props.dispatch);
        navigate('/leagues');
    }
    const onLoginFailure = (message: string): void => {
        UtilService.fbEventTrack('CreateProfileFailure', {invitationCode: props?.invitationCode, message: message});
        props.dispatch(loginFailed(message));
        props.dispatch(blockUi(false, ""));
    }
    props.dispatch(blockUi(true, "Setting Things Up..."));
    AuthorizationService.createProfile(createProfileRequest, onLoginSuccess, onLoginFailure);
};

const CreateProfile = (props: CreateProfileProps) => {
    const navigate = useNavigate();
    
    /*if (!_.isUndefined(props.loginErrorMessage)) {
        // seemingly getting here with no state...
        // returning a blank template until the state arrives fixes this.
        return <div></div>;
    }*/
    
    return (<div>
        <div className="jumbotron">
        </div>

        <h2 className="tight-panel"><img src="/img/new/profile-32x32.png" className="icon32" alt=""/> Log in with Facebook</h2>
        <div className="content-panel">
            <table>
                <tbody>
                    <tr>
                        <td><FacebookLoginButton/></td>
                        <td>
                            &nbsp;
                                <span className="glyphicon glyphicon-arrow-left" aria-hidden="true"/>
                            &nbsp;
                                Click here to log in to Pick'Em Leagues with your Facebook profile.
                            </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <h2 className="tight-panel"><img src="/img/new/profile-32x32.png" className="icon32" alt=""/> Create Profile (do not use Facebook)</h2>
        <div className="content-panel">
            <form>
                <div className="form-group">
                    <label htmlFor="userName">User Name</label>
                    <input type="text" className="form-control" id="userNameCreateProfileInput" placeholder="User Name" />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email Address</label>
                    <input type="email" className="form-control" id="emailCreateProfileInput" placeholder="Email" />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input type="password" className="form-control" id="passwordCreateProfileInput" placeholder="Password" />
                </div>
                <div className="form-group">
                    <label htmlFor="timeZoneSelect">Time Zone</label>
                    <TimeZoneSelector
                        defaultValue="Pacific Standard Time"
                        value={undefined}
                        onChange={undefined}
                        />
                </div>
                <MessagePanel className="bg-danger" message={props.loginErrorMessage} />
                <button
                    type="submit"
                    className="btn btn-pickem-blue"
                    onClick={e => doCreateProfile(navigate, e, props)}>
                    <i className="fa fa-user-plus" aria-hidden="true"/> Create Profile
                </button>
            </form>
            <hr/>
            <p>
                <button
                    type="submit"
                    className="btn btn-pickem-blue"
                    onClick={e => routeToLogin(navigate)} >
                    <i className="fa fa-user" aria-hidden="true"/> Login
                </button>
                &nbsp;
                <span className="glyphicon glyphicon-arrow-left" aria-hidden="true"/>
                &nbsp;
                Already have a profile? Go here.
            </p>
            <p>
                <button
                    type="submit"
                    className="btn btn-pickem-blue"
                    onClick={e => routeToHome(navigate)} >
                    <i className="fa fa-question-circle-o" aria-hidden="true"/> Help
                </button>
                &nbsp;
                <span className="glyphicon glyphicon-arrow-left" aria-hidden="true"/>
                &nbsp;
                What is all this? Help is over here!
            </p>
        </div>
    </div>);
};

function mapStateToProps(state: IState) {
    return {
        invitationCode: state.invitationCode,
        loginErrorMessage: state.loginErrorMessage
    };
}

export default connect(mapStateToProps)(CreateProfile as any);
