import * as React from "react";
import { useNavigate } from "react-router";

import FacebookLoginButton from "../controls/FacebookLoginButton";

import { routeToLogin, routeToCreateProfile } from "../../services/routingService";

export interface ILoginOrCreateProfileProps {
}

const LoginOrCreateProfile = (props: ILoginOrCreateProfileProps) => {
    const navigate = useNavigate();
    return (
        <table>
            <tbody>
                <tr>
                    <td className="plain-table-padding">If you want to join with a Facebook profile, click here.</td>
                    <td className="plain-table-padding"><span className="glyphicon glyphicon-arrow-right" aria-hidden="true"/></td>
                    <td className="plain-table-padding">
                        <FacebookLoginButton/>
                    </td>
                </tr>
                <tr>
                    <td className="plain-table-padding">If you have an existing Pick'Em Leagues profile, log in.</td>
                    <td className="plain-table-padding"><span className="glyphicon glyphicon-arrow-right" aria-hidden="true"/></td>
                    <td className="plain-table-padding">
                        <button
                            type="submit"
                            className="btn btn-pickem-blue"
                            onClick={e => routeToLogin(navigate)} >
                            <i className="fa fa-user" aria-hidden="true"/> Login
                        </button>
                    </td>
                </tr>
                <tr>
                    <td className="plain-table-padding">If you do not have an existing profile, create one.</td>
                    <td className="plain-table-padding"><span className="glyphicon glyphicon-arrow-right" aria-hidden="true"/></td>
                    <td className="plain-table-padding">
                        <button
                            type="submit"
                            className="btn btn-pickem-blue"
                            onClick={e => routeToCreateProfile(navigate)} >
                            <i className="fa fa-user-plus" aria-hidden="true"/> Create Profile
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default LoginOrCreateProfile;
