import * as React from "react";

export interface IHidableButtonProps {
    className: string;
    image: string;
    text: string;
    visible: boolean;
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const HidableButton = (props: IHidableButtonProps) => {

    if (!props.visible) {
        return <span/>;
    }

    return (<button
        type="submit"
        className={props.className}
        onClick={e => { props.onClick(e) } }
        >
        <i className={`fa fa-${props.image}`} aria-hidden="true"/> {props.text}
    </button>);
};

export default HidableButton;
