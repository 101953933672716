import { IUserState, ICreateLeagueState, ILeagueState, ILeagueManageState, ILeagueMemberState, ILeagueSeasonState, IProfileUpdateState, IInviteState, IInviteVerifyState, IPasswordResetState } from "../state/state"
import { ISeason, IWeek, IGamePick, IGamePickLiveUpdate, IAjaxResponse, ILeagueView, IGamePickDetail, ICurrentEliminationPick } from "../models/interfaces"

export class Action {
    // would like to use enum, but '@@redux/INIT' is a string.    
    public static readonly Login: string = "Login";
    public static readonly LoginFailed: string = "LoginFailed";
    public static readonly SetLoginDestination: string = "SetLoginDestination";
    public static readonly SetGeneralErrorMessage: string = "SetGeneralErrorMessage";
    public static readonly SetEmailVerifyState: string = "SetEmailVerifyState";
    public static readonly SetInvitationVerifyState: string = "SetInvitationVerifyState";
    public static readonly SetPasswordResetState: string = "SetPasswordResetState";
    public static readonly SetProfileUpdateState: string = "SetProfileUpdateState";
    public static readonly SetUserData: string = "SetUserData";
    public static readonly SetPublicLeagues: string = "SetPublicLeagues";
    public static readonly SetPublicLeaguesError: string = "SetPublicLeaguesError";
    public static readonly SetSeasons: string = "SetSeasons";
    public static readonly SetCreateLeagueState: string = "SetCreateLeagueState";
    public static readonly SetLeagueState: string = "SetLeagueState";
    public static readonly SetLeagueManageState: string = "SetLeagueManageState";
    public static readonly SetLeagueMemberState: string = "SetLeagueMemberState";
    public static readonly SetLeagueSeasonState: string = "SetLeagueSeasonState";
    public static readonly SetCurrentEliminationPicks: string = "SetCurrentEliminationPicks";
    public static readonly SetLeagueWeekPicks: string = "SetLeagueWeekPicks";
    public static readonly HideLeagueWeekPicks: string = "HideLeagueWeekPicks";
    public static readonly SetLeagueWeekPickLiveUpdates: string = "SetLeagueWeekPickLiveUpdates";
    public static readonly SetLeagueGamePick: string = "SetLeagueGamePick";
    public static readonly SetLeagueLocked: string = "SetLeagueLocked";
    public static readonly SetLeagueDeleted: string = "SetLeagueDeleted";
    public static readonly SetAddedUserLeagueEntries: string = "SetAddedUserLeagueEntries";
    public static readonly LeagueWeekPicksUndo: string = "LeagueWeekPicksUndo";
    public static readonly PickGame: string = "PickGame";
    public static readonly SetGamePickDetailLoading: string = "SetGamePickDetailLoading";
    public static readonly ToggleGamePickDetail: string = "ToggleGamePickDetail";
    public static readonly SetGamePickDetail: string = "SetGamePickDetail";
    public static readonly SetInviteState: string = "SetInviteState";
    public static readonly SetInviteVerifyState: string = "SetInviteVerifyState";
    public static readonly BlockUi: string = "BlockUi";
    public static readonly Logout: string = "Logout";
    public static readonly SetThisWeekSummaryVisible: string = "SetThisWeekSummaryVisible";
    public static readonly SetLastWeekSummaryVisible: string = "SetLastWeekSummaryVisible";
    public static readonly SetStandingsVisible: string = "SetStandingsVisible";
    public static readonly SetPickMargin: string = "SetPickMargin";
}

export interface IAction { type: string, payload?: any, error?: boolean, meta?: any }
export interface ILoginAction extends IAction { type: string, payload: { token: string } }
export interface ISetLoginDestinationAction extends IAction { type: string, payload: { destination: string | null } }
export interface ISetGeneralErrorMessageAction extends IAction { type: string, payload: { message: string | null } }
export interface ILoginFailedAction extends IAction { type: string, payload: { message: string } }
export interface ISetEmailVerifyState extends IAction { type: string, payload: { response: IAjaxResponse } }
export interface ISetInvitationVerifyState extends IAction { type: string, payload: { response: IAjaxResponse } }
export interface ISetSetPasswordResetState extends IAction { type: string, payload: { passwordResetState: IPasswordResetState } }
export interface ISetProfileUpdateStateAction extends IAction { type: string, payload: { profileUpdateState: IProfileUpdateState } }
export interface ISetUserDataAction extends IAction { type: string, payload: { userState: IUserState } }
export interface ISetPublicLeaguesAction extends IAction { type: string, payload: { publicLeagues: Array<ILeagueView> } }
export interface ISetPublicLeaguesErrorAction extends IAction { type: string, payload: { errorMessage: string } }
export interface ISetSeasonsAction extends IAction { type: string, payload: { seasons: Array<ISeason> } }
export interface ISetCreateLeagueStateAction extends IAction { type: string, payload: { createLeagueState: ICreateLeagueState } }
export interface ISetLeagueStateAction extends IAction { type: string, payload: { leagueState: ILeagueState | null } }
export interface ISetLeagueManageStateAction extends IAction { type: string, payload: { leagueManageState: ILeagueManageState | null } }
export interface ISetLeagueMemberStateAction extends IAction { type: string, payload: { leagueMemberState: ILeagueMemberState | null } }
export interface ISetLeagueSeasonStateAction extends IAction { type: string, payload: { leagueSeasonState: ILeagueSeasonState | null } }
export interface ISetCurrentEliminationPicksAction extends IAction { type: string, payload: { currentEliminationPicks: Array<ICurrentEliminationPick> } }
export interface ISetLeagueWeekPicksAction extends IAction { type: string, payload: { weekId: number, gamePicks: Array<IGamePick>, showPicks: boolean } }
export interface IHideLeagueWeekPicksAction extends IAction { type: string, payload: { weekId: number } }
export interface ISetLeagueWeekPickLiveUpdatesAction extends IAction { type: string, payload: { week: IWeek, gamePickLiveUpdates: Array<IGamePickLiveUpdate>, members: number, eliminatedMembers: number } }
export interface ISetLeagueGamePickAction extends IAction { type: string, payload: { weekId: number | null, weekPickCount: number | null, gameId: number | null, pickTeamId: number | null, elimPickName: string | null } }
export interface ISetLeagueLockedAction extends IAction { type: string, payload: { leagueId: number, locked: boolean } }
export interface ISetLeagueDeletedAction extends IAction { type: string, payload: { leagueId: number, deleted: boolean } }
export interface ISetAddedUserLeagueEntriesAction extends IAction { type: string, payload: { leagueMemberId: number, count: number } }
export interface ILeagueWeekPicksUndoAction extends IAction { type: string, payload: { weekId: number } }
export interface IPickGameAction extends IAction { type: string, payload: { weekId: number, gameId: number, teamId: number } }
export interface ISetGamePickDetailLoadingAction extends IAction { type: string, payload: { weekId: number, loading: boolean } }
export interface IToggleGamePickDetailAction extends IAction { type: string, payload: { weekId: number, gameId: number } }
export interface ISetGamePickDetailAction extends IAction { type: string, payload: { weekId: number, gameId: number, gamePickDetail: IGamePickDetail } }
export interface ISetInviteStateAction extends IAction { type: string, payload: { inviteState: IInviteState | null } }
export interface ISetInviteVerifyStateAction extends IAction { type: string, payload: { inviteVerifyState: IInviteVerifyState } }
export interface IBlockUiAction extends IAction { type: string, payload: { blocked: boolean, message: string, clearAll: boolean } }
export interface ILogoutAction extends IAction { type: string }
export interface ISetThisWeekSummaryVisibleAction extends IAction { type: string, payload: { visible: number } }
export interface ISetLastWeekSummaryVisibleAction extends IAction { type: string, payload: { visible: number } }
export interface ISetStandingsVisibleAction extends IAction { type: string, payload: { visible: number } }
export interface ISetPickMarginAction extends IAction { type: string, payload: { weekId: number, gameId: number, margin: number } }

export const login = (token: string): ILoginAction => {
    return {
        type: Action.Login,
        payload: {
            token: token
        }
    }
}

export const loginFailed = (message: string): ILoginFailedAction => {
    return {
        type: Action.LoginFailed,
        payload: {
            message: message
        }
    }
}

export const setLoginDestination = (destination: string | null): ISetLoginDestinationAction => {
    return {
        type: Action.SetLoginDestination,
        payload: {
            destination: destination
        }
    }
}

export const setGeneralErrorMessage = (message: string | null): ISetGeneralErrorMessageAction => {
    return {
        type: Action.SetGeneralErrorMessage,
        payload: {
            message: message
        }
    }
}

export const setEmailVerifyState = (response: IAjaxResponse): ISetEmailVerifyState => {
    return {
        type: Action.SetEmailVerifyState,
        payload: {
            response: response
        }
    }
}

export const setInvitationVerifyState = (response: IAjaxResponse): ISetInvitationVerifyState => {
    return {
        type: Action.SetInvitationVerifyState,
        payload: {
            response: response
        }
    }
}

export const setPasswordResetState = (passwordResetState: IPasswordResetState): ISetSetPasswordResetState => {
    return {
        type: Action.SetPasswordResetState,
        payload: {
            passwordResetState: passwordResetState
        }
    }
}

export const setProfileUpdateState = (profileUpdateState: IProfileUpdateState): ISetProfileUpdateStateAction => {
    return {
        type: Action.SetProfileUpdateState,
        payload: {
            profileUpdateState: profileUpdateState
        }
    }
}

export const setUserData = (userState: IUserState): ISetUserDataAction => {
    return {
        type: Action.SetUserData,
        payload: {
            userState: userState
        }
    }
}

export const setPublicLeagues = (publicLeagues: Array<ILeagueView>): ISetPublicLeaguesAction => {
    return {
        type: Action.SetPublicLeagues,
        payload: {
            publicLeagues: publicLeagues
        }
    }
}

export const setPublicLeaguesError = (errorMessage: string): ISetPublicLeaguesErrorAction => {
    return {
        type: Action.SetPublicLeaguesError,
        payload: {
            errorMessage: errorMessage
        }
    }
}

export const setSeasons = (seasons: Array<ISeason>): ISetSeasonsAction => {
    return {
        type: Action.SetSeasons,
        payload: {
            seasons: seasons
        }
    }
}

export const setCreateLeagueState = (createLeagueState: ICreateLeagueState): ISetCreateLeagueStateAction => {
    return {
        type: Action.SetCreateLeagueState,
        payload: {
            createLeagueState: createLeagueState
        }
    }
}

export const setLeagueState = (leagueState: ILeagueState | null): ISetLeagueStateAction => {
    return {
        type: Action.SetLeagueState,
        payload: {
            leagueState: leagueState
        }
    }
}

export const setLeagueManageState = (leagueManageState: ILeagueManageState | null): ISetLeagueManageStateAction => {
    return {
        type: Action.SetLeagueManageState,
        payload: {
            leagueManageState: leagueManageState
        }
    }
}

export const setLeagueMemberState = (leagueMemberState: ILeagueMemberState | null): ISetLeagueMemberStateAction => {
    return {
        type: Action.SetLeagueMemberState,
        payload: {
            leagueMemberState: leagueMemberState
        }
    }
}

export const setLeagueSeasonState = (leagueSeasonState: ILeagueSeasonState | null): ISetLeagueSeasonStateAction => {
    return {
        type: Action.SetLeagueSeasonState,
        payload: {
            leagueSeasonState: leagueSeasonState
        }
    }
}

export const setCurrentEliminationPicks = (currentEliminationPicks: Array<ICurrentEliminationPick>): ISetCurrentEliminationPicksAction => {
    return {
        type: Action.SetCurrentEliminationPicks,
        payload: {
            currentEliminationPicks: currentEliminationPicks
        }
    }
}

export const setLeagueWeekPicks = (weekId: number, gamePicks: Array<IGamePick>, showPicks: boolean): ISetLeagueWeekPicksAction => {
    return {
        type: Action.SetLeagueWeekPicks,
        payload: {
            weekId: weekId,
            gamePicks: gamePicks,
            showPicks: showPicks
        }
    }
}

export const hideLeagueWeekPicks = (weekId: number): IHideLeagueWeekPicksAction => {
    return {
        type: Action.HideLeagueWeekPicks,
        payload: {
            weekId: weekId
        }
    }
}

export const setLeagueWeekPickLiveUpdates = (week: IWeek, gamePickLiveUpdates: Array<IGamePickLiveUpdate>, members: number, eliminatedMembers: number): ISetLeagueWeekPickLiveUpdatesAction => {
    return {
        type: Action.SetLeagueWeekPickLiveUpdates,
        payload: {
            week: week,
            gamePickLiveUpdates: gamePickLiveUpdates,
            members: members,
            eliminatedMembers: eliminatedMembers
        }
    }
}

export const setLeagueGamePick = (weekId: number | null, weekPickCount: number | null, gameId: number | null, pickTeamId: number | null, elimPickName: string | null): ISetLeagueGamePickAction => {
    return {
        type: Action.SetLeagueGamePick,
        payload: {
            weekId: weekId,
            weekPickCount: weekPickCount,
            gameId: gameId,
            pickTeamId: pickTeamId,
            elimPickName: elimPickName
        }
    }
}

export const setLeagueLocked = (leagueId: number, locked: boolean): ISetLeagueLockedAction => {
    return {
        type: Action.SetLeagueLocked,
        payload: {
            leagueId: leagueId,
            locked: locked
        }
    }
}

export const setLeagueDeleted = (leagueId: number, deleted: boolean): ISetLeagueDeletedAction => {
    return {
        type: Action.SetLeagueDeleted,
        payload: {
            leagueId: leagueId,
            deleted: deleted
        }
    }
}

export const setAddedUserLeagueEntries = (leagueMemberId: number, count: number): ISetAddedUserLeagueEntriesAction => {
    return {
        type: Action.SetAddedUserLeagueEntries,
        payload: {
            leagueMemberId: leagueMemberId,
            count: count
        }
    }
}

export const leagueWeekPicksUndo = (weekId: number): ILeagueWeekPicksUndoAction => {
    return {
        type: Action.LeagueWeekPicksUndo,
        payload: {
            weekId: weekId
        }
    }
}

export const pickGame = (weekId: number, gameId: number, teamId: number): IPickGameAction => {
    return {
        type: Action.PickGame,
        payload: {
            weekId: weekId,
            gameId: gameId,
            teamId: teamId
        }
    }
}

export const setGamePickDetailLoading = (weekId: number, loading: boolean): ISetGamePickDetailLoadingAction => {
    return {
        type: Action.SetGamePickDetailLoading,
        payload: {
            weekId: weekId,
            loading: loading
        }
    }
}

export const toggleGamePickDetail = (weekId: number, gameId: number): IToggleGamePickDetailAction => {
    return {
        type: Action.ToggleGamePickDetail,
        payload: {
            weekId: weekId,
            gameId: gameId
        }
    }
}

export const setGamePickDetail = (weekId: number, gameId: number, gamePickDetail: IGamePickDetail): ISetGamePickDetailAction => {
    return {
        type: Action.SetGamePickDetail,
        payload: {
            weekId: weekId,
            gameId: gameId,
            gamePickDetail: gamePickDetail
        }
    }
}

export const setInviteState = (inviteState: IInviteState | null): ISetInviteStateAction => {
    return {
        type: Action.SetInviteState,
        payload: {
            inviteState: inviteState
        }
    }
}

export const setInviteVerifyState = (inviteVerifyState: IInviteVerifyState): ISetInviteVerifyStateAction => {
    return {
        type: Action.SetInviteVerifyState,
        payload: {
            inviteVerifyState: inviteVerifyState
        }
    }
}

export const blockUi = (blocked: boolean, message: string, clearAll?: boolean): IBlockUiAction => {
    return {
        type: Action.BlockUi,
        payload: {
            blocked: blocked, message: message, clearAll: clearAll || false
        }
    }
}

export const logout = (): ILogoutAction => {
    return {
        type: Action.Logout
    }
}

export const setThisWeekSummaryVisible = (visible: number): ISetThisWeekSummaryVisibleAction => {
    return {
        type: Action.SetThisWeekSummaryVisible,
        payload: {
            visible
        }
    }
}

export const setLastWeekSummaryVisible = (visible: number): ISetLastWeekSummaryVisibleAction => {
    return {
        type: Action.SetLastWeekSummaryVisible,
        payload: {
            visible
        }
    }
}

export const setStandingsVisible = (visible: number): ISetStandingsVisibleAction => {
    return {
        type: Action.SetStandingsVisible,
        payload: {
            visible
        }
    }
}

export const setPickMargin = (weekId: number, gameId: number, margin: number): ISetPickMarginAction => {
    return {
        type: Action.SetPickMargin,
        payload: {
            weekId,
            gameId,
            margin
        }
    }
}
