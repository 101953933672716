import * as React from "react";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router";
import _ from "lodash";

import MessagePanel from "../../controls/MessagePanel";

import { DataService } from "../../../services/dataService";
import { routeToLeagues } from "../../../services/routingService";
import { UtilService } from "../../../services/utilService";

import { DefaultStateProvider } from "../../../state/defaultStateProvider";
import { IState } from "../../../state/state";
import { IAction, setCreateLeagueState } from "../../../actions";
import { IAjaxResponse, ICreateLeagueState, ISeason } from "../../../models/interfaces";

export interface CreateLeagueProps {
    dispatch: (action: IAction) => void,
    onStateChange: (newState: ICreateLeagueState) => void,
    seasons: Array<ISeason>;
    createLeagueState: ICreateLeagueState,
    authenticationToken: string
}

const setErrorMessage = (props: CreateLeagueProps, errorMessage: string): void => {
    const newState = Object.assign({}, props.createLeagueState, { errorMessage: errorMessage });
    props.onStateChange(newState);
}

const createLeague = (navigate: NavigateFunction, props: CreateLeagueProps, e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (props.createLeagueState.createLeague.name.trim().length === 0) {
        setErrorMessage(props, "Name is required.");
        return;
    }
    if (props.createLeagueState.createLeague.name.trim().length < 4) {
        setErrorMessage(props, "Minimum length for name is 4 characters.");
        return;
    }
    setErrorMessage(props, "");
    DataService.createLeague(
        props.authenticationToken,
        props.createLeagueState.createLeague,
        (response: IAjaxResponse) => {
            if (response.success) {                
                // route back to leagues!
                UtilService.fbEventTrack('CreateLeagueSuccess', {name: props?.createLeagueState?.createLeague?.name});
                routeToLeagues(navigate);
            } else {
                UtilService.fbEventTrack('CreateLeagueFailure', {name: props?.createLeagueState?.createLeague?.name, message: response.message});
                setErrorMessage(props, response.message);
            }
        }, props.dispatch);
}

const handleChange = (props: CreateLeagueProps, e: any, property: string): void => {
    let newState = Object.assign({}, props.createLeagueState, {});
    switch (property) {
        case "name":
            newState.createLeague.name = e.target.value;
            break;
        case "seasonId":
            newState.createLeague.seasonId = parseInt(e.target.value, 10);
            break;
        case "leagueTypeId":
            newState.createLeague.leagueTypeId = parseInt(e.target.value, 10);
            break;
        case "isPublic":
            newState.createLeague.isPublic = e.target.value === "1";
            break;
    }
    props.onStateChange(newState);
}

/*const setSeason = (props: CreateLeagueProps, seasonId: number) => {
    let newState = Object.assign({}, props.createLeagueState, {});
    newState.createLeague.seasonId = seasonId;
    props.onStateChange(newState);
}*/

const CreateLeague = (props: CreateLeagueProps) => {

    const navigate = useNavigate();

    if (!_.isObject(props.createLeagueState) || !_.isObject(props.seasons)) {
        // seemingly getting here with no createLeagueState...
        // returning a blank template until the state arrives fixes this.
        return <div/>;
    }

    const seasonOptions = props.seasons.map(
        (season: ISeason) =>
            <option key={season.seasonId} value={season.seasonId}>{season.name}</option>
    );

    return (
        <div>
            <h2 className="tight-panel"><img src="/img/new/leagues-32x32.png" className="icon32" alt=""/> Create League</h2>
            <div className="content-panel">
                <form>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="leagueNameInput"
                            placeholder="Name"
                            value={props.createLeagueState.createLeague.name}
                            onChange={e => handleChange(props, e, 'name')}
                            />
                    </div>
                    <div className="form-group">
                        <label htmlFor="leagueSeasonSelect">First Season</label>
                        <select
                            className="form-control"
                            id="leagueSeasonSelect"                        
                            value={props.createLeagueState.createLeague.seasonId.toString()}
                            onChange={e => handleChange(props, e, 'seasonId')}>
                            {seasonOptions}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="leagueTypeSelect">League Type</label>
                        <select
                            className="form-control"
                            id="leaguePublicSelect"
                            value={props.createLeagueState.createLeague.leagueTypeId.toString()}
                            onChange={e => handleChange(props, e, 'leagueTypeId')}>
                            <option value="1">Pick'Em</option>
                            <option value="2">Pick'Em vs Spread</option>
                            <option value="3">Elimination / Suicide / Knock Out</option>
                            <option value="5">Touchdown Pick'Em</option>
                        </select>
                        <div className="p-spacer">
                            <p>Pick'Em = players pick every game, every week, through the whole season, straight up.</p>
                            <p>Pick'Em vs Spread = players pick every game, every week, through the whole season, against the spread.</p>
                            <p>Elimination = players pick ONE game, every week. One wrong pick and they are eliminated!</p>
                            <p>Touchdown Pick'Em = players every game, and how much they think the team will win by.</p>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="leaguePublicSelect">Public?</label>
                        <select
                            className="form-control"
                            id="leaguePublicSelect"
                            value={props.createLeagueState.createLeague.isPublic ? "1" : "0"}
                            onChange={e => handleChange(props, e, 'isPublic')}>
                            <option value="1">True</option>
                            <option value="0">False</option>
                        </select>
                        <div className="p-spacer">
                            <p>Anyone can see and join a public league (until it is locked).</p>
                            <p>Private leagues require new members to have an invitation from the admin (you) to join.</p>
                        </div>
                    </div>
                    <MessagePanel className="bg-danger" message={props.createLeagueState.errorMessage} />
                    <button
                        type="submit"
                        className="btn btn-pickem-blue"
                        onClick={e => createLeague(navigate, props, e)}>
                        <i className="fa fa-plus-square" aria-hidden="true"/> Create League
                    </button>
                </form>
            </div>
        </div>
    );
};

const mapStateToProps = (state: IState) => {    
    const props = {
        createLeagueState: _.isObject(state.createLeagueState) ? state.createLeagueState : Object.assign({}, DefaultStateProvider.getDefaultCreateLeagueState()),
        seasons: state.seasons,
        authenticationToken: state.authenticationToken
    };
    if (props.createLeagueState && props.createLeagueState.createLeague.seasonId === -1 && props.seasons && props.seasons.length > 0) {
        props.createLeagueState.createLeague.seasonId = props.seasons[0].seasonId;
    }
    return props;
}

const mapDispatchToProps = (dispatch: (action: IAction) => void) => {
    return {
        dispatch,
        onStateChange: (newState: ICreateLeagueState) => {
            dispatch(setCreateLeagueState(newState));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateLeague as any);
