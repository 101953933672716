import * as React from "react";

import { ILeagueWeekSummary, ILeagueWeekTeamPickEmSummary } from "../../models/interfaces";

export interface ILeagueWeekSummaryProps {
    titleSuffix: string;    
    leagueWeekSummary: ILeagueWeekSummary | null,
    onSetWeekSummaryVisible: (visible: number) => void,
    userId: number,
}

const userRow = (leagueWeekTeamPickEmSummary: ILeagueWeekTeamPickEmSummary, userId: number, place: number) => {
    let backgroundColor: string;
    let teamCell: JSX.Element;
    if (leagueWeekTeamPickEmSummary.userId === userId) {
        backgroundColor = '#DDD';
        teamCell = <td style={{ backgroundColor }}><b>{leagueWeekTeamPickEmSummary.teamName}</b></td>;
    } else {
        backgroundColor = '#FFF';
        teamCell = <td style={{ backgroundColor }}>{leagueWeekTeamPickEmSummary.teamName}</td>;
    }

    return <tr key={leagueWeekTeamPickEmSummary.leagueMemberId}>
        <td style={{ backgroundColor }}>{place.toString()}</td>
        {teamCell}
        <td style={{ backgroundColor }}>{leagueWeekTeamPickEmSummary.winnerCount}/{leagueWeekTeamPickEmSummary.loserCount}</td>
        <td style={{ backgroundColor }}>
            {leagueWeekTeamPickEmSummary.winners}
        </td>
        <td style={{ backgroundColor }}>
            {leagueWeekTeamPickEmSummary.losers}
        </td>
    </tr>;
};

const summaryMapper = (props: ILeagueWeekSummaryProps): Array<JSX.Element> | null => {
    if (!props.leagueWeekSummary) {
        return null;
    }    
    // don't show people with no picks complete.    
    const summaries = props.leagueWeekSummary.leagueWeekTeamPickEmSummaries.filter(s => s.winnerCount > 0 || s.loserCount > 0);    
    if (summaries.length === 0) {
        return [<tr key="row1"><td colSpan={5}>No results yet for this week.</td></tr>]
    } 

    const visible = props.leagueWeekSummary.visible;

    let place: number = 0;
    let renderedUser: boolean = false;
    const userId = props.userId;

    const visibleSummaries = summaries.filter((s, i) => i < visible);

    const rows = visibleSummaries.map(
        (leagueWeekTeamPickEmSummary: ILeagueWeekTeamPickEmSummary) => {
            if (leagueWeekTeamPickEmSummary.userId === userId) {
                renderedUser = true;
            }
            return userRow(leagueWeekTeamPickEmSummary, userId, ++place);
        }            
    );

    if (visibleSummaries.length < summaries.length) {
        if (!renderedUser) {
            const userSummaryIndex = summaries.findIndex(s => s.userId === userId);
            if (userSummaryIndex > -1) {
                rows.push(<td colSpan={5}>&nbsp;&nbsp;&nbsp;&nbsp;...</td>);
                rows.push(userRow(summaries[userSummaryIndex], userId, userSummaryIndex + 1));
            }
        }

        rows.push(<tr key="loaderRow">
            <td colSpan={5}>
                <button
                    type="submit"
                    className="btn btn-pickem-blue"
                    onClick={e => {
                        props.onSetWeekSummaryVisible(visibleSummaries.length + 10);
                    } }
                    ><i className="fa fa-eye" aria-hidden="true"/> Show More</button>
                &nbsp;&nbsp;
                <button
                    type="submit"
                    className="btn btn-pickem-blue"
                    onClick={e => {
                        props.onSetWeekSummaryVisible(summaries.length);
                    } }
                    ><i className="fa fa-eye" aria-hidden="true"/> Show All</button>
            </td>
        </tr>);
    }

    return rows;
}

const LeagueWeekSummary = (props: ILeagueWeekSummaryProps): JSX.Element | null => {
    if (!props.leagueWeekSummary) {
        return null;
    }
    
    if (props.leagueWeekSummary.leagueWeekTeamPickEmSummaries.length === 0) {
        return null;
    }

    return (
        <span>
            <h2><img src="/img/new/calendar-32x32.png" className="icon32" alt=""/> {props.leagueWeekSummary.weekName} {props.titleSuffix}</h2>
            <table className="table table-bordered table-hover shadow">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Team</th>
                        <th>Rec</th>
                        <th>Winners</th>
                        <th>Losers</th>
                    </tr>
                </thead>
                <tbody>
                    {summaryMapper(props)}
                </tbody>
            </table>
        </span>);
};

export default LeagueWeekSummary
