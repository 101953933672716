import * as React from "react";

export interface IHidableMessageProps {
    className: string;
    src: string;
    height: number;
    width: number;
    visible: boolean;
}

const HidableImage = (props: IHidableMessageProps) => {

    if (!props.visible) {
        return null;
    }

    return <img className={props.className} src={props.src} height={props.height} width={props.width} alt=""/>;
};

export default HidableImage;
