import * as React from "react";
import { connect } from "react-redux";

import NavMenu from "./NavMenu";
import NavMenuAuthenticated from "./NavMenuAuthenticated";

import { IState } from "../../state/state";

export interface NavProps { authenticationToken: string | null }

const Nav = (props: NavProps) => {

    const navMenu = props && props.authenticationToken != null ? <NavMenuAuthenticated /> : <NavMenu />;

    // noinspection HtmlUnknownAnchorTarget
    return (
        <nav className="navbar navbar-inverse navbar-inverse-pickem navbar-fixed-top">
            <div className="container-fluid">
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-menu" aria-expanded="false">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"/>
                        <span className="icon-bar"/>
                        <span className="icon-bar"/>
                    </button>
                    <a className="navbar-brand navbar-brand-emphasized navbar-inverse-pickem" href="/#/">
                        <span className="glyphicon glyphicon-check" aria-hidden="true"/>
                        &nbsp;&nbsp;Pick'Em
                    </a>
                </div>
                <div id="navbar-menu" className="navbar-collapse collapse">
                    {navMenu}
                </div>
            </div>
        </nav>
    );
};

function mapStateToProps(state: IState): NavProps {
    return { authenticationToken: state ? state.authenticationToken : null };
}

export default connect(mapStateToProps)(Nav as any);
