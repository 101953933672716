import * as React from "react";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router";
import _ from "lodash";

import MessagePanel from "../../controls/MessagePanel";
import TimeZoneSelector from "../../controls/TimeZoneSelector";

import { AuthorizationService, IUpdateProfileRequest } from "../../../services/authorizationService";
import { DataService } from "../../../services/dataService";
import { routeToLeagues } from "../../../services/routingService";
import { UtilService } from "../../../services/utilService";

import { IState, IUserState, IProfileUpdateState } from "../../../state/state";
import { IAction, setProfileUpdateState } from "../../../actions";
import { IAjaxResponse } from "../../../models/interfaces";

/* eslint-disable jsx-a11y/anchor-is-valid, no-script-url */

export interface IProfileUpdateProps {
    dispatch: any,
    userState: IUserState,
    generalErrorMessage: string,
    onStateChange: (newState: IProfileUpdateState) => void,
    profileUpdateState: IProfileUpdateState,
    authenticationToken: string
}

const setErrorMessage = (props: IProfileUpdateProps, errorMessage: string): void => {
    const newState = Object.assign({}, props.profileUpdateState, { errorMessage: errorMessage });
    props.onStateChange(newState);
}

const setSuccessMessage = (props: IProfileUpdateProps, successMessage: string): void => {
    const newState = Object.assign({}, props.profileUpdateState, { successMessage: successMessage });
    props.onStateChange(newState);
}

const updateProfile = (navigate: NavigateFunction, props: IProfileUpdateProps, e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (props.profileUpdateState.newUserName.trim().length === 0) {
        setErrorMessage(props, "UserName is required.");
        return;
    }
    if (props.profileUpdateState.newUserName.trim().length < 2) {
        setErrorMessage(props, "Minimum length for UserName is 2 characters.");
        return;
    }

    setErrorMessage(props, "");

    const onSuccess = (): void => {
        UtilService.fbEventTrack('ProfileUpdateSuccess', {userId: props?.userState?.userId});
        DataService.getUserState(props.authenticationToken, true, props.dispatch);
        routeToLeagues(navigate);
    }

    const onFailure = (message: string): void => {
        UtilService.fbEventTrack('ProfileUpdateFailure', {userId: props?.userState?.userId, message: message});
        setErrorMessage(props, message);
    }

    const updateProfileRequest: IUpdateProfileRequest = {
        email: props.profileUpdateState.newEmail,
        password: props.profileUpdateState.newPassword,
        userName: props.profileUpdateState.newUserName,
        timeZone: props.profileUpdateState.newTimeZone,
        reminderOptOut: props.profileUpdateState.newReminderOptOut,
        crossLeaguePicking: props.profileUpdateState.newCrossLeaguePicking,
        token: props.authenticationToken,
        code: undefined
    };

    AuthorizationService.updateProfile(updateProfileRequest, onSuccess, onFailure);
}

const handleChange = (props: IProfileUpdateProps, e: any, property: string): void => {
    let newState = Object.assign({}, props.profileUpdateState);
    switch (property) {
        case "userName":
            newState.newUserName = e.target.value;
            break;
        case "email":
            newState.newEmail = e.target.value;
            break;
        case "password":
            newState.newPassword = e.target.value;
            break;
        case "timeZone":
            newState.newTimeZone = e.target.value;
            break;
        case "reminderOptOut":
            newState.newReminderOptOut = e.target.value === "true";
            break;
        case "crossLeaguePicking":
            newState.newCrossLeaguePicking = e.target.value === "true";
            break;
    }
    props.onStateChange(newState);
}

const sendEmailVerification = (props: IProfileUpdateProps, e: any) => {
    DataService.sendEmailVerification(props.authenticationToken, (response: IAjaxResponse) => {
        if (response.success) {
            setSuccessMessage(props, response.message);
        } else {
            setErrorMessage(props, response.message);
        }
    }, props.dispatch);
}

const hasChanges = (props: IProfileUpdateProps): boolean => {
    return (
        props.profileUpdateState.origEmail !== props.profileUpdateState.newEmail
        ||
        props.profileUpdateState.origUserName !== props.profileUpdateState.newUserName
        ||
        props.profileUpdateState.origTimeZone !== props.profileUpdateState.newTimeZone
        ||
        props.profileUpdateState.origReminderOptOut !== props.profileUpdateState.newReminderOptOut
        ||
        props.profileUpdateState.origCrossLeaguePicking !== props.profileUpdateState.newCrossLeaguePicking
        ||
        '' !== props.profileUpdateState.newPassword
    );
}

const ProfileUpdate = (props: IProfileUpdateProps) => {
    
    const navigate = useNavigate();
    
    if (!_.isObject(props.profileUpdateState)) {
        // seemingly getting here with no state...
        // returning a blank template until the state arrives fixes this.
        return (
            <div>
                <MessagePanel className="bg-danger" message={props.generalErrorMessage} />
            </div>);
    }

    if (!_.isObject(props.profileUpdateState)) {
        // seemingly getting here with no state...
        // returning a blank template until the state arrives fixes this.
        return <div/>;
    }

    const buttons = hasChanges(props) ?
        <button
            type="submit"
            className="btn btn-pickem-blue"
            onClick={e => updateProfile(navigate, props, e)}>
            <i className="fa fa-user" aria-hidden="true"/> Update
        </button>
        :
        <button
            type="submit"
            className="btn btn-default"
            disabled>
            <i className="fa fa-user" aria-hidden="true"/> Update
        </button>
        ;

    const verifyLink = !props.profileUpdateState.verified
        ? <a href="javascript:;" onClick={e => sendEmailVerification(props, e)}>Resend Verification Email</a>
        : <span />

    const isFacebookLogin = !_.isNil(props.userState.facebookId) && props.userState.facebookId.length;
        
    const passwordClasses = isFacebookLogin ? 'form-group hidden' : 'form-group';

    return (
        <div>
            <h2 className="tight-panel"><img src="/img/new/profile-32x32.png" className="icon32" alt=""/> My Profile</h2>
            <div className="content-panel">
                <form>
                    <div className="form-group">
                        <label htmlFor="userNameInput">User Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="userNameInput"
                            placeholder="UserName"
                            value={props.profileUpdateState.newUserName}
                            onChange={e => handleChange(props, e, 'userName')}
                            />
                    </div>
                    <div className="form-group">
                        <label htmlFor="emailInput">Email Address</label>
                        <input
                            type="email"
                            className="form-control"
                            id="emailInput"
                            placeholder="Email Address"
                            value={props.profileUpdateState.newEmail}
                            onChange={e => handleChange(props, e, 'email')}
                            />
                    </div>
                    <div className={passwordClasses}>
                        <label htmlFor="passwordInput">Password</label>
                        <input
                            type="password"
                            className="form-control"
                            id="passwordInput"
                            placeholder="Password"
                            value={props.profileUpdateState.newPassword}
                            onChange={e => handleChange(props, e, 'password')}
                            />
                    </div>
                    <div className="form-group">
                        <label htmlFor="timeZoneSelect">Time Zone</label>
                        <TimeZoneSelector
                            defaultValue={undefined}
                            value={props.profileUpdateState.newTimeZone}
                            onChange={e => handleChange(props, e, 'timeZone')}
                            />
                    </div>
                    <div className="form-group">
                        <label htmlFor="createdInput">Created</label>
                        <input
                            type="text"
                            className="form-control"
                            id="createdInput"
                            readOnly
                            value={UtilService.dateForDisplay(props.profileUpdateState.created)}
                            />
                    </div>
                    <div className="form-group">
                        <label htmlFor="verifiedInput">Email Address Verified</label>
                        <input
                            type="text"
                            className="form-control"
                            id="verifiedInput"
                            readOnly
                            value={props.profileUpdateState.verified.toString()}
                            />
                        {verifyLink}
                    </div>
                    <div className="form-group">
                        <label htmlFor="verifiedInput">Opt-Out of Reminder Emails</label>
                        <p>Set this to 'Yes' if you do not want to receive reminders emails when you have not made your picks.</p>
                        <select
                            className="form-control"
                            value={props.profileUpdateState.newReminderOptOut.toString()}
                            onChange={e => handleChange(props, e, 'reminderOptOut')}
                            id="timeZoneSelect">
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="verifiedInput">Cross League Picking</label>
                        <p>
                            Set this to 'Yes' if you want picks made for one league to affect all leagues.
                            Note: Cross League Picking only affects Pick'Em leagues, not Elimination leagues.
                            Cross League Picking is also ignored in any leagues where the user has multiple entries.
                        </p>
                        <select
                            className="form-control"
                            value={props.profileUpdateState.newCrossLeaguePicking.toString()}
                            onChange={e => handleChange(props, e, 'crossLeaguePicking')}
                            id="timeZoneSelect">
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </div>
                    {buttons}
                    <br /><br />
                    <MessagePanel className="bg-danger" message={props.profileUpdateState.errorMessage} />
                    <MessagePanel className="bg-success" message={props.profileUpdateState.successMessage} />
                </form>
            </div>
        </div>
    );
}

function mapStateToProps(state: IState) {
    return {
        userState: state.userState,
        generalErrorMessage: state.generalErrorMessage,
        profileUpdateState: state !== undefined ? state.profileUpdateState : null,
        authenticationToken: state !== undefined ? state.authenticationToken : null
    };
}

const mapDispatchToProps = (dispatch: (action: IAction) => void) => {
    return {
        dispatch,
        onStateChange: (newState: IProfileUpdateState) => {
            dispatch(setProfileUpdateState(newState));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileUpdate as any);
