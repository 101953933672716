import * as React from "react";

const Privacy = () => {

    return (
        <div>
            <div className="jumbotron">
            </div>

            <h2 className="tight-panel"><img src="/img/new/works-32x32.png" className="icon32" alt=""/> Privacy Policy</h2>
            <div className="content-panel">
                <p>
                    This privacy policy has been compiled to better serve those who are concerned with how their 'Personally identifiable information' (PII) is being used online. PII, as used in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.
                </p>

                <h4>
                    What personal information do we collect from the people that visit our website?
                </h4>

                <p>
                    If a profile is created, we request a name and email address. Neither are required to be accurate.
                </p>

                <h4>
                    When do we collect information?
                </h4>

                <p>
                    When a profile is created.
                </p>

                <h4>
                    How do we use your information?
                </h4>

                <p>
                    Name: is display on the site. Email: to send pick reminder emails.
                </p>

                <h4>
                    How do we protect visitor information?
                </h4>

                <p>
                    Data is secured in a database.
                </p>

                <h4>
                    Do we use 'cookies'?
                </h4>

                <p>
                    We use Google Analytics which uses cookies to record site usage. We also use cookies for the login.
                </p>

                <p>
                    You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser (like Internet Explorer) settings. Each browser is a little different, so look at your browser's Help menu to learn the correct way to modify your cookies.
                </p>

                <p>
                    If you disable cookies off, some features will be disabled that make your site experience more efficient and some of our services will not function properly.
                </p>

                <h4>
                    Third-party disclosure
                </h4>

                <p>
                    We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information.
                </p>

                <h4>
                    Third-party links
                </h4>

                <p>
                    We do not include or offer third-party products or services on our website.
                </p>

                <h4>
                    Google
                </h4>

                <p>
                    Google's advertising requirements can be summed up by Google's Advertising Principles. They are put in place to provide a positive experience for users. https://support.google.com/adwordspolicy/answer/1316548?hl=en
                </p>

                <p>
                    We may use Google AdSense Advertising on our website.
                </p>

                <p>
                    Google, as a third-party vendor, uses cookies to serve ads on our site. Google's use of the DART cookie enables it to serve ads to our users based on previous visits to our site and other sites on the Internet. Users may opt-out of the use of the DART cookie by visiting the Google Ad and Content Network privacy policy.
                </p>

                <p>
                    We may have implemented 'Remarketing with Google AdSense'.
                </p>

                <p>
                    We along with third-party vendors, such as Google use first-party cookies (such as the Google Analytics cookies) and third-party cookies (such as the DoubleClick cookie) or other third-party identifiers together to compile data regarding user interactions with ad impressions and other ad service functions as they relate to our website.
                </p>

                <p>
                    Opting out: Users can set preferences for how Google advertises to you using the Google Ad Settings page. Alternatively, you can opt out by visiting the Network Advertising initiative opt out page or permanently using the Google Analytics Opt Out Browser add on.
                </p>

                <h4>
                    California Online Privacy Protection Act
                </h4>

                <p>
                    CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law's reach stretches well beyond California to require a person or company in the United States (and conceivably the world) that operates websites collecting personally identifiable information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals with whom it is being shared, and to comply with this policy. - See more at: http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
                </p>

                <h4>
                    According to CalOPPA we agree to the following:
                </h4>

                <p>
                    Users can visit our site anonymously.
                </p>

                <p>
                    Once this privacy policy is created, we will add a link to it on our home page or as a minimum on the first significant page after entering our website.
                </p>

                <p>
                    Our Privacy Policy link includes the word 'Privacy' and can be easily be found on the page specified above.
                </p>

                <p>
                    Users will be notified of any privacy policy changes: On our Privacy Policy Page
                </p>

                <p>
                    Users are able to change their personal information: We do not collect information from visitors of our site.
                </p>

                <h4>
                    How does our site handle do not track signals?
                </h4>

                <p>
                    We don't honor do not track signals and do not track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place. We don't honor them because: we do not track
                </p>

                <h4>
                    Does our site allow third-party behavioral tracking?
                </h4>

                <p>
                    It's also important to note that we do not allow third-party behavioral tracking
                </p>

                <h4>
                    COPPA (Children Online Privacy Protection Act)
                </h4>

                <p>
                    When it comes to the collection of personal information from children under 13, the Children's Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, the nation's consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children's privacy and safety online.
                </p>

                <p>
                    We do not specifically market to children under 13.
                </p>

                <h4>
                    Fair Information Practices
                </h4>

                <p>
                    The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.
                </p>

                <h4>
                    In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:
                </h4>

                <p>
                    We email eash user.
                </p>

                <h4>
                    CAN SPAM Act
                </h4>

                <p>
                    The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.
                </p>

                <h4>
                    User Data Deletion
                </h4>

                <p>
                    Pickem Leagues provides the following ways for users to request their personal data be deleted from the web site and database.<br />
                    <br />
                    If the user has access to the email associated with the Pickem Leagues account email address:<br />
                    1. Send an email to user_data_deletion@pickemleagues.com with the request. The email should be from the email associated with the Pickem Leagues account.<br />
                    2. An admin will reply your email to confirm the request.<br />
                    3. The user will respond, confirming the email address.<br />
                    4. An admin will delete the data.<br />
                    5. An admin will send the user a confirmation email.<br />
                    <br />
                    If the user cannot access to the email associated with the Pickem Leagues account email address:<br />
                    1. Edit the Pickem Leagues account user name, adding "DeleteMe" to the name.<br />
                    2. Send an email to user_data_deletion@pickemleagues.com with the request, and provide the email associated with the Pickem Leagues account.<br />
                    3. An admin will verify that the user name contains "DeleteMe" and will delete the data.<br />
                    4. An admin will send the user a confirmation email.<br />
                </p>

                <h4>
                    Contacting Us
                </h4>

                <p>
                    If there are any questions regarding this privacy policy you may contact us using the information below.
                </p>

                <p>
                    Upway2late.com Software <br />
                    3317 S Higley Rd. STE 114 #113<br />
                    Gilbert, AZ 85297<br />
                    USA
                </p>

                <p>
                    privacy@pickemleagues.com
                </p>

                <p>
                    Last Edited on 2020-06-09
                </p>
            </div>

        </div>
    );
}

export default Privacy;
