import { NavigateFunction } from "react-router";

export const routeToCustom = (navigate: NavigateFunction, destination: string): void => {
    navigate(destination);
}

export const routeToHome = (navigate: NavigateFunction): void => {
    navigate('/');
}

export const routeToLogin = (navigate: NavigateFunction): void => {
    navigate('/login');
}

export const routeToCreateProfile = (navigate: NavigateFunction): void => {
    navigate('/createProfile');
}

export const routeToProfile = (navigate: NavigateFunction): void => {
    navigate('/profile');
}

export const routeToCreateLeague = (navigate: NavigateFunction): void => {
    navigate('/createLeague');
}

export const routeToLeagues = (navigate: NavigateFunction): void => {
    navigate('/leagues');
}

export const routeToPublicPage = (navigate: NavigateFunction): void => {
    navigate('/publicPage');
}

export const routeToLeague = (navigate: NavigateFunction, leagueId: number, leagueMemberId: number | null): void => {
    if (leagueMemberId !== null) {
        navigate(`/league?leagueId=${leagueId}&leagueMemberId=${leagueMemberId}`);
    } else {
        navigate(`/league?leagueId=${leagueId}`);
    }    
}

export const routeToLeagueManagement = (navigate: NavigateFunction, leagueId: number): void => {
    navigate(`/leagueManage?leagueId=${leagueId}`);    
}

export const routeToLeagueSeason = (navigate: NavigateFunction, leagueId: number | undefined, leagueMemberId: number | undefined): void => {
    if (leagueId && leagueMemberId) {
        navigate(`/leagueSeason?leagueId=${leagueId}&leagueMemberId=${leagueMemberId}`);
    }
}

export const routeToInvite = (navigate: NavigateFunction, leagueId: number): void => {
    navigate(`/invite?leagueId=${leagueId}`);
}
