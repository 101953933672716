import * as React from "react";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router";
import _ from "lodash";

import HidableButton from "../../controls/HidableButton";
import MessagePanel from "../../controls/MessagePanel";
import LeagueShareLink from "../../controls/LeagueShareLink";

import { DataService } from "../../../services/dataService";
import { routeToCreateLeague, routeToLeague } from "../../../services/routingService";

import { IState, IUserState, IUserLeaguesState } from "../../../state/state";
import { IAction, setPublicLeaguesError } from "../../../actions";
import { IUserLeagueView, ILeagueView, IAjaxResponse } from "../../../models/interfaces";

import { oldLeagueCutOffId, UtilService } from "../../../services/utilService";

export interface ILeaguesProps {
    dispatch: (action: IAction) => void,
    generalErrorMessage: string,
    authenticationToken: string,
    userState: IUserState,
    userLeaguesState: IUserLeaguesState
}

const picksCanMake = (league: IUserLeagueView): number => {
    if (league.eliminated) {
        return 0;
    }
    return league.picksCanMake;
}

const leagueIcon = (league: IUserLeagueView): any => {
    if (league.winner) {
        return <img src="/img/winner.png" height={54} width={96} alt=""/>;
    }
    else if (league.eliminated) {
        return <i className="fa fa-times-circle danger-red" aria-hidden="true"/>;
    } else if (picksCanMake(league) > 0) {
        return <i className="fa fa-exclamation-triangle caution-yellow" aria-hidden="true"/>;
    } else {
        return null;
    }
}

const picksCanMakeIcon = (league: IUserLeagueView): any => {
    if (picksCanMake(league) > 0) {
        return <i className="fa fa-exclamation-triangle caution-yellow" aria-hidden="true"/>;
    } else {
        return <i className="fa fa-check-square-o" aria-hidden="true"/>;
    }
}

const picksCanMakeColumn = (league: IUserLeagueView): any => {
    if (league.eliminated) {
        return <i className="fa fa-times-circle danger-red" aria-hidden="true"/>;
    }
    return <span>{picksCanMake(league).toString()} {picksCanMakeIcon(league)}</span>;
}

const memberCount = (league: IUserLeagueView): string => {
    if (!league.elimination) {
        return league.members.toString();
    }
    const remaining = league.members - league.eliminatedMembers;
    return `${remaining.toString()}/${league.members.toString()}`;
}

const leagueMapper = (navigate: NavigateFunction, props: ILeaguesProps, currentLeagues: boolean): Array<JSX.Element> => {
    if (props.userState.leagues.length === 0) {
        return [<tr key="row1"><td colSpan={6}>Create or join a league to get started.</td></tr>];
    } else {
        return props.userState.leagues.filter(l => currentLeagues ? l.leagueId >= oldLeagueCutOffId && !l.eliminated : l.leagueId < oldLeagueCutOffId || l.eliminated).map(
            (league: IUserLeagueView) =>
                <tr key={`${league.leagueId}+${league.leagueMemberId}`}>
                    <td>
                        {UtilService.indexedLeagueName(league.leagueName, league.teamName, league.idx)}<br />
                        <button
                            type="submit"
                            className="btn btn-pickem-blue button-margin"
                            onClick={e => routeToLeague(navigate, league.leagueId, league.leagueMemberId)}>
                            <i className="fa fa-share" aria-hidden="true"/> Open
                            </button>
                            {league.leagueId === 646000 && <LeagueShareLink top="-2px" altImage={true} guid="f5027177-7821-419f-bedb-060aa7ffab45" message="Pick'Em Leagues - 2022 Free Elimination League (Win $250)" />}
                            {league.leagueId === 647000 && <LeagueShareLink top="-2px" altImage={true} guid="f93be889-609b-436b-aa3e-a8fe9fba00e1" message="Pick'Em Leagues - 2022 Free Pick'Em League (Win $250)" />}
                            &nbsp;&nbsp;
                            {leagueIcon(league)}
                    </td>
                    {
                        !currentLeagues &&
                        <td>{league.seasonName.replace(/\D/g, '')}</td>
                    }
                    <td>{league.leagueRulesetName}</td>
                    <td className="no-wrap">{memberCount(league)}</td>
                    <td>{league.points}</td>
                    {
                        currentLeagues &&
                        <td>{picksCanMakeColumn(league)}</td>
                    }
                </tr>
        );
    }
}

const publicPickemsLeagueMapper = (navigate: NavigateFunction, props: ILeaguesProps, currentLeagues: boolean): Array<JSX.Element> => {
    const count = props.userLeaguesState.publicLeagues.filter(l => [646,647,655].includes(l.leagueId)).length;
    if (count === 0) {
        return [<tr key="row1"><td colSpan={5}>No free roll Pickem Leagues found.</td></tr>]
    } else {
        return props.userLeaguesState.publicLeagues.filter(l => [646,647,655].includes(l.leagueId)).map(
            (league: ILeagueView) =>
                <tr key={league.leagueId}>
                    <td>
                        <button
                            type="submit"
                            className="btn btn-pickem-blue button-margin"
                            onClick={e => routeToLeague(navigate, league.leagueId, null)}>
                            <i className="fa fa-eye" aria-hidden="true"/> View
                        </button>
                        <HidableButton
                            className="btn btn-pickem-blue button-margin"
                            visible={league.isPublic && !league.locked}
                            image="plus-square"
                            text="Join"
                            onClick={e => {
                                DataService.joinPublicLeague(
                                    props.authenticationToken,
                                    league.leagueId,
                                    props.dispatch).then((response: IAjaxResponse) => {
                                    if (response.success) {
                                        props.dispatch(setPublicLeaguesError(''));
                                        routeToLeague(navigate, league.leagueId, null);
                                    } else {
                                        props.dispatch(setPublicLeaguesError(`Error joining public league. (${response.message})`));
                                    }
                                });
                            } }
                        />
                    </td>
                    <td>{league.leagueName}</td>
                    <td>{league.seasonName}</td>
                    <td>{league.ownerName}</td>
                    <td>{league.members}</td>
                </tr>
        )
    }
}

const publicLeagueMapper = (navigate: NavigateFunction, props: ILeaguesProps, currentLeagues: boolean): Array<JSX.Element> => {
    if (props.userLeaguesState.publicLeagues.length === 0) {
        return [<tr key="row1"><td colSpan={5}>No public leagues found.</td></tr>]
    } else {
        return props.userLeaguesState.publicLeagues.filter(l => currentLeagues ? l.leagueId >= oldLeagueCutOffId : l.leagueId < oldLeagueCutOffId).map(
            (league: ILeagueView) =>
                <tr key={league.leagueId}>
                    <td>
                        <button
                            type="submit"
                            className="btn btn-pickem-blue button-margin"
                            onClick={e => routeToLeague(navigate, league.leagueId, null)}>
                            <i className="fa fa-eye" aria-hidden="true"/> View
                            </button>
                        <HidableButton
                            className="btn btn-pickem-blue button-margin"
                            visible={league.isPublic && !league.locked}
                            image="plus-square"
                            text="Join"
                            onClick={e => {
                                DataService.joinPublicLeague(
                                    props.authenticationToken,
                                    league.leagueId,
                                    props.dispatch).then((response: IAjaxResponse) => {
                                        if (response.success) {
                                            props.dispatch(setPublicLeaguesError(''));
                                            routeToLeague(navigate, league.leagueId, null);
                                        } else {
                                            props.dispatch(setPublicLeaguesError(`Error joining public league. (${response.message})`));
                                        }
                                    });
                                } }
                            />
                    </td>
                    <td>{league.leagueName}</td>
                    <td>{league.seasonName}</td>
                    <td>{league.ownerName}</td>
                    <td>{league.members}</td>
                </tr>
        )
    }
}

const joinablePickemLeagues = (navigate: NavigateFunction, props: ILeaguesProps): JSX.Element | null => {
    const count = props.userLeaguesState.publicLeagues.filter(l => [646,647,655].includes(l.leagueId)).length;
    if (count === 0) {
        return null;
    } else {
        return (
            <div>
                <h2 className="tight-panel"><img src="/img/new/leagues-32x32.png" className="icon32" alt=""/> Official Pickem Leagues</h2>
                <div className="content-panel">
                    <p>These are free roll Pickem Leagues that anyone can join and win money!</p>
                </div>
                <table className="table table-bordered table-hover shadow">
                    <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>Name</th>
                        <th>Season</th>
                        <th>Owner</th>
                        <th><i className="fa fa-user" aria-hidden="true"/></th>
                    </tr>
                    </thead>
                    <tbody>
                    {publicPickemsLeagueMapper(navigate, props, true)}
                    </tbody>
                </table>
            </div>
        );
    }
}

const oldLeagues = (navigate: NavigateFunction, props: ILeaguesProps): JSX.Element | null => {
    const count = props.userState.leagues.filter(l => l.leagueId < oldLeagueCutOffId).length;
    if (count === 0) {
        return null;
    } else {
        return (
            <div>
                <h2 className="tight-panel"><img src="/img/new/leagues-32x32.png" className="icon32" alt=""/> My Old Leagues</h2>
                <div className="content-panel">                    
                    <p>These are your leagues from previous years, and leagues from this year where you have been eliminated.</p>
                </div>
                <table className="table table-bordered table-hover shadow">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Year</th>
                            <th>Type</th>
                            <th><i className="fa fa-user" aria-hidden="true"/></th>
                            <th>My Pts</th>                            
                        </tr>
                    </thead>
                    <tbody>
                        {leagueMapper(navigate, props, false)}
                    </tbody>
                </table>
            </div>
        );
    }
}

const oldPublicLeagues = (navigate: NavigateFunction, props: ILeaguesProps): JSX.Element | null => {
    const count = props.userLeaguesState.publicLeagues.filter(l => l.leagueId < oldLeagueCutOffId).length;
    if (count === 0) {
        return null;
    } else {
        return (
            <div>
                <h2 className="tight-panel"><img src="/img/new/leagues-32x32.png" className="icon32" alt=""/> Old Public Leagues</h2>
                <div className="content-panel">
                    <p>These are old public leagues from previous years.</p>
                </div>
                <table className="table table-bordered table-hover shadow">
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th>Name</th>                            
                            <th>Season</th>
                            <th>Owner</th>
                            <th><i className="fa fa-user" aria-hidden="true"/></th>
                        </tr>
                    </thead>
                    <tbody>
                        {publicLeagueMapper(navigate, props, false)}
                    </tbody>
                </table>
            </div>
        );
    }
}

const Leagues = (props: ILeaguesProps) => {
    const navigate = useNavigate();
    
    if (!_.isObject(props.userState) || !_.isObject(props.userLeaguesState)) {
        // seemingly getting here with no userState...
        // returning a blank template until the state arrives fixes this.
        return (
            <div>
                <MessagePanel className="bg-danger" message={props.generalErrorMessage} />
            </div>);
    }

    const message = props.userState && props.userState.leagues.length === 0 ? 'You are not in any leagues.' : '';
    
    return (
        <div>
            <h2 className="tight-panel"><img src="/img/new/leagues-32x32.png" className="icon32" alt=""/> My Leagues</h2>
            <div className="content-panel">
                <MessagePanel className="bg-info" message={message} />
                <p>Click here to create a brand new league. You'll be the owner and you can control who is in the league, or make it public.</p>
                <button
                    type="submit"
                    className="btn btn-pickem-blue button-margin"
                    onClick={e => routeToCreateLeague(navigate)}>
                    <i className="fa fa-users" aria-hidden="true"/> Create a League
                </button>
            </div>
            <table className="table table-bordered table-hover shadow">
                <thead>
                    <tr>                        
                        <th>Name</th>                        
                        <th>Type</th>
                        <th><i className="fa fa-user" aria-hidden="true"/></th>
                        <th>My Pts</th>
                        <th>Picks Due</th>
                    </tr>
                </thead>
                <tbody>
                    {leagueMapper(navigate, props, true)}
                </tbody>
            </table>
            {joinablePickemLeagues(navigate, props)}
            <h2 className="tight-panel"><img src="/img/new/leagues-32x32.png" className="icon32" alt=""/> Public Leagues</h2>
            <div className="content-panel">                
                <MessagePanel className="bg-danger" message={props.userLeaguesState.publicLeaguesErrorMessage} />
                <p>These are public leagues that anyone can view. If there is a join button, the league is accepting new members.</p>
            </div>
            <table className="table table-bordered table-hover shadow">
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>Name</th>
                        <th>Season</th>
                        <th>Owner</th>
                        <th><i className="fa fa-user" aria-hidden="true"/></th>
                    </tr>
                </thead>
                <tbody>
                    {publicLeagueMapper(navigate, props, true)}
                </tbody>
            </table>
            {oldLeagues(navigate, props)}
            {oldPublicLeagues(navigate, props)}
        </div>
    );
};

function mapStateToProps(state: IState) {
    return {
        generalErrorMessage: state.generalErrorMessage,
        userState: state.userState,
        userLeaguesState: state.userLeaguesState,
        authenticationToken: state.authenticationToken
    };
}

export default connect(mapStateToProps)(Leagues as any);
