import * as React from "react";
import { connect } from "react-redux";

import { IState, IBlockUiState } from "../../state/state";

export interface UiBlockerProps {
    blockUiState: IBlockUiState;
}

const UiBlocker = (props: UiBlockerProps): JSX.Element | null => {

    if (props.blockUiState.blockedCount <= 0) {
        return null;
    }

    return (
        <span>
            <div className="in modal-backdrop block-ui-overlay"/>
            <div className="block-ui-message-container" aria-live="assertive" aria-atomic="true">
                <div className="block-ui-message">
                    <table>
                        <tbody>
                            <tr><td className='center space block-ui-cell'>{props.blockUiState.message}</td></tr>
                            <tr><td className='center block-ui-cell'><img src="/img/blocks.gif" alt=""/></td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </span>);
};

function mapStateToProps(state: IState) {
    return {
        blockUiState: state.blockUiState
    };
}

export default connect(mapStateToProps)(UiBlocker as any);
