import _ from "lodash";
import { IState, IBlockUiState, ILeagueState } from "../state/state";

import { Action, IAction } from "../actions";

import { IWeek, IGamePick, IGamePickLiveUpdate, ILeagueWeekTeamEliminationSummary } from "../models/interfaces";

const debug: boolean = false;

const defaultReducer = (state: IState, action: IAction) => {

    switch (action.type) {

        case Action.Login: {

            if (debug) console.info(`reducer.Login -> ${action.payload.token}`);

            return Object.assign({}, state, {
                authenticationToken: action.payload.token,
                invitationCode: null,
                loginErrorMessage: ''
            });
        }
        case Action.LoginFailed: {

            if (debug) console.info(`reducer.LoginFailed -> ${action.payload.message}`);

            return Object.assign({}, state, {
                loginErrorMessage: action.payload.message
            });
        }
        case Action.SetLoginDestination: {

            if (debug) console.info(`reducer.SetLoginDestination -> ${action.payload.destination}`);

            return Object.assign({}, state, {
                loginDestination: { destination: action.payload.destination }
            });
        }
        case Action.SetGeneralErrorMessage: {

            if (debug) console.info(`reducer.SetGeneralErrorMessage -> ${action.payload.message}`);

            return Object.assign({}, state, {
                generalErrorMessage: action.payload.message
            });
        }
        case Action.SetEmailVerifyState: {

            if (debug) console.info(`reducer.SetEmailVerifyState -> ${JSON.stringify(action.payload.response)}`);

            return Object.assign({}, state, {
                emailVerifyState: { response: action.payload.response }
            });
        }
        case Action.SetInvitationVerifyState: {

            if (debug) console.info(`reducer.SetInvitationVerifyState -> ${JSON.stringify(action.payload.response)}`);

            return Object.assign({}, state, {
                invitationVerifyState: { response: action.payload.response }
            });
        }
        case Action.SetPasswordResetState: {

            if (debug) console.info(`reducer.SetPasswordResetState -> ${JSON.stringify(action.payload.passwordResetState)}`);

            return Object.assign({}, state, {
                passwordResetState: action.payload.passwordResetState
            });
        }
        case Action.SetProfileUpdateState: {

            if (debug) console.info(`reducer.SetProfileUpdateState -> ${JSON.stringify(action.payload.profileUpdateState)}`);

            return Object.assign({}, state, {
                profileUpdateState: action.payload.profileUpdateState
            });
        }
        case Action.SetUserData: {

            if (debug) console.info(`reducer.SetUserData -> ${action.payload.userState}`);

            return Object.assign({}, state, {
                userState: action.payload.userState
            });
        }
        case Action.SetPublicLeagues: {

            if (debug) console.info(`reducer.SetPublicLeagues -> ${action.payload.publicLeagues.length}`);

            let userLeaguesState = Object.assign({}, state.userLeaguesState);

            userLeaguesState.publicLeagues = action.payload.publicLeagues;

            return Object.assign({}, state, {
                userLeaguesState: userLeaguesState
            });
        }
        case Action.SetPublicLeaguesError: {

            if (debug) console.info(`reducer.SetPublicLeaguesError -> ${action.payload.errorMessage}`);

            let userLeaguesState = Object.assign({}, state.userLeaguesState);

            userLeaguesState.publicLeaguesErrorMessage = action.payload.errorMessage;

            return Object.assign({}, state, {
                userLeaguesState: userLeaguesState
            });
        }
        case Action.SetSeasons: {

            if (debug) console.info(`reducer.SetSeasons -> ${JSON.stringify(action.payload.seasons)}`);

            return Object.assign({}, state, {
                seasons: action.payload.seasons
            });
        }
        case Action.SetCreateLeagueState: {

            if (debug) console.info(`reducer.SetCreateLeagueState -> ${JSON.stringify(action.payload.createLeagueState)}`);

            return Object.assign({}, state, {
                createLeagueState: action.payload.createLeagueState
            });
        }
        case Action.SetLeagueState: {

            if (debug) console.info(`reducer.SetLeagueState -> ${JSON.stringify(action.payload.leagueState)}`);

            const leagueState = action.payload.leagueState;
            if (leagueState) {
                if (leagueState.thisWeekSummary) {
                    leagueState.thisWeekSummary.visible = 10;                
                }
                if (leagueState.lastWeekSummary) {
                    leagueState.lastWeekSummary.visible = 10;
                }
                leagueState.standings = { visible: 10 };
            }

            return Object.assign({}, state, {
                leagueState
            });
        }
        case Action.SetLeagueManageState: {

            if (debug) console.info(`reducer.SetLeagueManageState -> ${JSON.stringify(action.payload.leagueManageState)}`);

            return Object.assign({}, state, {
                leagueManageState: action.payload.leagueManageState
            });
        }
        case Action.SetLeagueMemberState: {

            if (debug) console.info(`reducer.SetLeagueMemberState -> ${JSON.stringify(action.payload.leagueMemberState)}`);

            return Object.assign({}, state, {
                leagueMemberState: action.payload.leagueMemberState
            });
        }
        case Action.SetLeagueSeasonState: {

            if (debug) console.info(`reducer.SetLeagueSeasonState -> ${JSON.stringify(action.payload.leagueSeasonState)}`);

            return Object.assign({}, state, {
                leagueSeasonState: action.payload.leagueSeasonState
            });
        }
        case Action.SetCurrentEliminationPicks: {

            if (debug) console.info(`reducer.SetCurrentEliminationPicks -> ${action.payload.currentEliminationPicks.length}`);

            let leagueSeasonState = Object.assign({}, state.leagueSeasonState);

            leagueSeasonState.currentEliminationPicks = action.payload.currentEliminationPicks;

            return Object.assign({}, state, {
                leagueSeasonState: leagueSeasonState
            });
        }
        case Action.SetLeagueWeekPicks: {

            if (debug) console.info(`reducer.SetLeagueWeekPicks -> week ${action.payload.weekId} ${JSON.stringify(action.payload.gamePicks.length)} showPicks ${action.payload.showPicks}`);

            if (!(state && state.leagueSeasonState && state.leagueSeasonState.weeks)) {
                // nothing to do.
                return state;
            }

            let leagueSeasonState = Object.assign({}, state.leagueSeasonState);

            let week = _.find(leagueSeasonState.weeks, (w: IWeek) => w.weekId === action.payload.weekId);

            if (week && _.isObject(week)) {
                if (action.payload.showPicks === true) {
                    week.gamePicks = action.payload.gamePicks;
                }

                const picks = _.reduce(action.payload.gamePicks, (sum: number, n: IGamePick) => (sum + (n.currentPickTeamId > 0 ? 1 : 0)), 0)
                week.pickCount = picks;

                if (picks === 0) {
                    week.elimPickName = "";
                }
            }

            return Object.assign({}, state, {
                leagueSeasonState: leagueSeasonState
            });
        }
        case Action.HideLeagueWeekPicks: {

            if (debug) console.info(`reducer.HideLeagueWeekPicks -> week ${action.payload.weekId}`);

            if (!(state && state.leagueSeasonState && state.leagueSeasonState.weeks)) {
                // nothing to do.
                return state;
            }

            let leagueSeasonState = Object.assign({}, state.leagueSeasonState);

            let week = _.find(leagueSeasonState.weeks, (w: IWeek) => w.weekId === action.payload.weekId);

            if (week && _.isObject(week)) {
                week.gamePicks = [];

                return Object.assign({}, state, {
                    leagueSeasonState: leagueSeasonState
                });
            }

            return state;
        }
        case Action.SetLeagueWeekPickLiveUpdates: {

            if (debug) console.info(`reducer.SetLeagueWeekPickLiveUpdates -> week ${JSON.stringify(action.payload.week)} ${JSON.stringify(action.payload.gamePickLiveUpdates.length)}`);

            if (action.payload.gamePickLiveUpdates.length === 0) {
                // nothing to do.
                return state;
            }

            let madeChanges: boolean = false;
            let leagueState = state.leagueState != null ? Object.assign({}, state.leagueState) : null;
            let leagueSeasonState = state.leagueSeasonState != null ? Object.assign({}, state.leagueSeasonState) : null;

            if (leagueSeasonState && leagueSeasonState.leagueView) {

                // verify league. possible (however unlikely) that the league state now does not match.
                if (leagueSeasonState.leagueView.leagueId === action.payload.gamePickLiveUpdates[0].leagueId) {

                    let week = _.find(leagueSeasonState.weeks, (w: IWeek) => w.weekId === action.payload.week.weekId);

                    if (week) {
                        week.pickCount = action.payload.week.pickCount;
                        week.points = action.payload.week.points;

                        _.forEach(action.payload.gamePickLiveUpdates, (u: IGamePickLiveUpdate) => {

                            // @ts-ignore
                            let gamePick = _.find(week.gamePicks, (gp: IGamePick) => gp.gameId === u.gameId);

                            if (gamePick && _.isObject(gamePick)) {
                                gamePick.gameId = u.gameId;
                                gamePick.weekId = u.weekId;
                                gamePick.locationId = u.locationId;
                                gamePick.awayTeamId = u.awayTeamId;
                                gamePick.awayTeamRecord = u.awayTeamRecord;
                                gamePick.homeTeamId = u.homeTeamId;
                                gamePick.homeTeamRecord = u.homeTeamRecord;
                                gamePick.complete = u.complete;
                                gamePick.awaySpread = u.awaySpread;
                                gamePick.homeSpread = u.homeSpread;
                                gamePick.totalPoints = u.totalPoints;
                                gamePick.locked = u.locked;
                                gamePick.awayPoints = u.awayPoints;
                                gamePick.homePoints = u.homePoints;
                                gamePick.period = u.period;
                                gamePick.progress = u.progress;
                                gamePick.possessionTeamId = u.possessionTeamId;
                                gamePick.rz = u.rz;
                                gamePick.ga = u.ga;
                                gamePick.lastNote = u.lastNote;
                                gamePick.final = u.final;
                                gamePick.winningTeamId = u.winningTeamId;
                                gamePick.currentPickTeamId = u.currentPickTeamId;
                                gamePick.points = u.points;
                                gamePick.awayPickCount = u.awayPickCount;
                                gamePick.homePickCount = u.homePickCount;
                            }
                        });

                        madeChanges = true;
                    }

                    leagueSeasonState.leagueView.members = action.payload.members;
                    leagueSeasonState.leagueView.eliminatedMembers = action.payload.eliminatedMembers;
                }
            }

            if (leagueState && leagueState.leagueView && leagueState.thisWeekSummary) {

                // verify league. possible (however unlikely) that the league state now does not match.
                if (leagueState.leagueView.leagueId === action.payload.gamePickLiveUpdates[0].leagueId) {

                    let summaries = leagueState.thisWeekSummary.leagueWeekTeamEliminationSummaries;

                    _.forEach(action.payload.gamePickLiveUpdates, (u: IGamePickLiveUpdate) => {

                        let awaySummary = _.find(summaries, (s: ILeagueWeekTeamEliminationSummary) => s.gameId === u.gameId && s.teamId === u.awayTeamId);

                        if (awaySummary) {
                            awaySummary.period = u.period;
                            awaySummary.progress = u.progress;
                            awaySummary.possession = u.possessionTeamId === u.awayTeamId;
                            awaySummary.rz = u.rz;
                            awaySummary.final = u.final;
                            awaySummary.status = u.awayPoints - u.homePoints;
                            awaySummary.winner = u.final && u.awayPoints > u.homePoints;

                            madeChanges = true;
                        }

                        let homeSummary = _.find(summaries, (s: ILeagueWeekTeamEliminationSummary) => s.gameId === u.gameId && s.teamId === u.homeTeamId);

                        if (homeSummary) {
                            homeSummary.period = u.period;
                            homeSummary.progress = u.progress;
                            homeSummary.possession = u.possessionTeamId === u.homeTeamId;
                            homeSummary.rz = u.rz;
                            homeSummary.final = u.final;
                            homeSummary.status = u.homePoints - u.awayPoints;
                            homeSummary.winner = u.final && u.homePoints > u.awayPoints;

                            madeChanges = true;
                        }
                    });

                    leagueState.leagueView.members = action.payload.members;
                    leagueState.leagueView.eliminatedMembers = action.payload.eliminatedMembers;
                }
            }

            if (madeChanges) {
                return Object.assign({}, state, {
                    leagueState: leagueState,
                    leagueSeasonState: leagueSeasonState
                });
            }

            // nothing to do.
            return state;
        }
        case Action.SetLeagueGamePick: {

            if (debug) console.info(`reducer.SetLeagueGamePick -> week ${action.payload.weekId} weekPickCount ${action.payload.weekPickCount} gameId ${action.payload.gameId} pickTeamId ${action.payload.pickTeamId}`);

            if (!(state && state.leagueSeasonState && state.leagueSeasonState.weeks)) {
                // nothing to do.
                return state;
            }

            let leagueSeasonState = Object.assign({}, state.leagueSeasonState);

            let targetWeek = _.find(leagueSeasonState.weeks, (w: IWeek) => w.weekId === action.payload.weekId);

            if (targetWeek) {

                targetWeek.pickCount = action.payload.weekPickCount;
                targetWeek.elimPickName = action.payload.elimPickName;

                let targetGp = _.find(targetWeek.gamePicks, (gp: IGamePick) => gp.gameId === action.payload.gameId);

                if (targetGp) {
                    targetGp.origPickTeamId = action.payload.pickTeamId;
                    targetGp.pendingPickTeamId = 0;
                    targetGp.currentPickTeamId = action.payload.pickTeamId;

                    if (leagueSeasonState.leagueRuleSet?.elimination === true) {
                        if (leagueSeasonState.leagueRuleSet?.maxWeekPicks === 1) {
                            // for elimination leagues, set all other picks to blank (since there can be only one)                            
                            _.each(targetWeek.gamePicks, (gp: IGamePick) => {
                                if (gp.gameId !== action.payload.gameId) {
                                    gp.origPickTeamId = 0;
                                    gp.pendingPickTeamId = 0;
                                    gp.currentPickTeamId = 0;
                                }
                            });
                        } else {
                            // to do: will have to account for other things and actually not assume they are not all picked later
                            // handled!
                        }
                    }
                }
            }

            return Object.assign({}, state, {
                leagueSeasonState: leagueSeasonState
            });
        }
        case Action.SetLeagueLocked: {

            if (debug) console.info(`reducer.SetLeagueLocked -> leagueId ${action.payload.leagueId} locked ${action.payload.locked}`);

            
            let madeChanges: boolean = false;
            let leagueState = state.leagueState != null ? Object.assign({}, state.leagueState) : null;
            let leagueSeasonState = state.leagueSeasonState != null ? Object.assign({}, state.leagueSeasonState) : null;

            if (leagueState && leagueState.leagueView && leagueSeasonState && leagueSeasonState.leagueView) {

                if (leagueState.leagueView.locked !== action.payload.locked) {
                    madeChanges = true;
                    leagueState.leagueView.locked = action.payload.locked;
                }

                if (leagueSeasonState && leagueSeasonState.leagueView.locked !== action.payload.locked) {
                    madeChanges = true;
                    leagueSeasonState.leagueView.locked = action.payload.locked;
                }
            }
            
            if (!madeChanges) {
                // nothing to do.
                return state;
            }

            return Object.assign({}, state, {
                leagueState: leagueState,
                leagueSeasonState: leagueSeasonState
            });
        }
        case Action.SetLeagueDeleted: {

            if (debug) console.info(`reducer.SetLeagueDeleted -> leagueId ${action.payload.leagueId} deleted ${action.payload.deleted}`);

            let changed: boolean = false;
            let leagueState = state.leagueState != null ? Object.assign({}, state.leagueState) : null;
            let leagueSeasonState = state.leagueSeasonState != null ? Object.assign({}, state.leagueSeasonState) : null;
            
            if (leagueState && leagueState.leagueView && leagueSeasonState && leagueSeasonState.leagueView) {

                if (leagueState.leagueView.deleted !== action.payload.deleted) {
                    changed = true;
                    leagueState.leagueView.deleted = action.payload.deleted;
                }

                if (leagueSeasonState.leagueView.deleted !== action.payload.deleted) {
                    changed = true;
                    leagueSeasonState.leagueView.deleted = action.payload.deleted;
                }
            }

            if (!changed) {
                // nothing to do.
                return state;
            }

            return Object.assign({}, state, {
                leagueState: leagueState,
                leagueSeasonState: leagueSeasonState
            });
        }
        case Action.SetAddedUserLeagueEntries: {

            if (debug) console.info(`reducer.SetAddedUserLeagueEntries -> leagueMemberId ${action.payload.leagueMemberId} count ${action.payload.count}`);

            let changed: boolean = false;
            let leagueMemberState = state.leagueMemberState != null ? Object.assign({}, state.leagueMemberState) : null;
            
            if (leagueMemberState && _.isObject(leagueMemberState.leagueMember) && leagueMemberState.leagueMember?.leagueMemberId === action.payload.leagueMemberId) {
                changed = true;
                leagueMemberState.userEntries = leagueMemberState.userEntries + action.payload.count;
            }

            if (!changed) {
                // nothing to do.
                return state;
            }

            return Object.assign({}, state, {
                leagueMemberState: leagueMemberState
            });
        }
        case Action.LeagueWeekPicksUndo: {

            if (debug) console.info(`reducer.LeagueWeekPicksUndo -> week ${action.payload.weekId}`);

            if (!(state && state.leagueSeasonState && state.leagueSeasonState.weeks)) {
                // nothing to do.
                return state;
            }

            let leagueSeasonState = Object.assign({}, state.leagueSeasonState);

            let week = _.find(leagueSeasonState.weeks, (w: IWeek) => w.weekId === action.payload.weekId);

            if (week) {
                _.forEach(week.gamePicks, (gp: IGamePick) => gp.currentPickTeamId = gp.origPickTeamId);

                return Object.assign({}, state, {
                    leagueSeasonState: leagueSeasonState
                });
            }

            // didn't find matching data... nothing to do
            return state;
        }
        case Action.PickGame: {

            // set as PENDING.

            if (debug) console.info(`reducer.PickGame -> week ${action.payload.weekId} game ${action.payload.gameId} team ${action.payload.teamId}`);

            if (!(state && state.leagueSeasonState && state.leagueSeasonState.weeks)) {
                // nothing to do.
                return state;
            }

            let leagueSeasonState = Object.assign({}, state.leagueSeasonState);

            let week = _.find(leagueSeasonState.weeks, (w: IWeek) => w.weekId === action.payload.weekId);

            if (week) {

                let gamePick = _.find(week.gamePicks, (gp => gp.gameId === action.payload.gameId));

                if (gamePick && !gamePick.locked) {
                    gamePick.pendingPickTeamId = action.payload.teamId;

                    return Object.assign({}, state, {
                        leagueSeasonState: leagueSeasonState
                    });
                }
            }

            // didn't find matching data... nothing to do
            return state;
        }
        case 'xxx': {

            // this version for SetLeagueWeekPicks does not work!

            if (debug) console.info(`reducer.SetLeagueWeekPicks -> ${JSON.stringify(action.payload.gamePicks.length)}`);

            if (!(state && state.leagueSeasonState && state.leagueSeasonState.weeks)) {
                // nothing to do.
                return state;
            }

            let newState = Object.assign({}, state, {});
            
            if (!newState || !newState.leagueSeasonState) {
                return state;
            }

            let week = _.find(newState.leagueSeasonState.weeks, (w: IWeek) => w.weekId === action.payload.weekId);

            if (week) {
                week.gamePicks = action.payload.gamePicks;
                return newState;
            }

            // didn't find the week - return original state.
            return state;
        }
        case Action.SetGamePickDetailLoading: {

            if (debug) console.info(`reducer.SetGamePickDetailLoading -> week ${action.payload.weekId} loading ${action.payload.loading}`);

            if (!(state && state.leagueSeasonState && state.leagueSeasonState.weeks)) {
                // nothing to do.
                return state;
            }

            let leagueSeasonState = Object.assign({}, state.leagueSeasonState);

            let week = _.find(leagueSeasonState.weeks, (w: IWeek) => w.weekId === action.payload.weekId);

            if (week) {

                week.loading = action.payload.loading;

                return Object.assign({}, state, {
                    leagueSeasonState: leagueSeasonState
                });
            }

            // didn't find matching data... nothing to do
            return state;
        }
        case Action.ToggleGamePickDetail: {

            if (debug) console.info(`reducer.ToggleGamePickDetail -> week ${action.payload.weekId} game ${action.payload.gameId}`);

            if (!(state && state.leagueSeasonState && state.leagueSeasonState.weeks)) {
                // nothing to do.
                return state;
            }

            let leagueSeasonState = Object.assign({}, state.leagueSeasonState);

            let week = _.find(leagueSeasonState.weeks, (w: IWeek) => w.weekId === action.payload.weekId);

            if (week) {

                let gamePick = _.find(week.gamePicks, (gp => gp.gameId === action.payload.gameId));

                if (gamePick) {
                    gamePick.detailVisible = !gamePick.detailVisible;

                    return Object.assign({}, state, {
                        leagueSeasonState: leagueSeasonState
                    });
                }
            }

            // didn't find matching data... nothing to do
            return state;
        }
        case Action.SetGamePickDetail: {

            if (debug) console.info(`reducer.SetGamePickDetail -> ${JSON.stringify(action.payload.gamePickDetail)}`);

            if (!(state && state.leagueSeasonState && state.leagueSeasonState.weeks)) {
                // nothing to do.
                return state;
            }

            let leagueSeasonState = Object.assign({}, state.leagueSeasonState);

            let week = _.find(leagueSeasonState.weeks, (w: IWeek) => w.weekId === action.payload.weekId);

            if (week) {

                let gamePick = _.find(week.gamePicks, (gp => gp.gameId === action.payload.gameId));

                if (gamePick) {
                    gamePick.detail = action.payload.gamePickDetail;

                    return Object.assign({}, state, {
                        leagueSeasonState: leagueSeasonState
                    });
                }
            }

            // didn't find matching data... nothing to do
            return state;
        }
        case Action.SetInviteState: {

            if (debug) console.info(`reducer.SetInviteState -> ${JSON.stringify(action.payload.inviteState)}`);

            return Object.assign({}, state, {
                inviteState: action.payload.inviteState
            });
        }
        case Action.SetInviteVerifyState: {

            if (debug) console.info(`reducer.SetInviteVerifyState -> ${JSON.stringify(action.payload.inviteVerifyState)}`);

            const invitationCode =
                action.payload.inviteVerifyState.valid && action.payload.inviteVerifyState.authRequired && action.payload.inviteVerifyState.code && action.payload.inviteVerifyState.code.length > 0
                    ? action.payload.inviteVerifyState.code
                    : null;

            return Object.assign({}, state, {
                invitationCode: invitationCode,
                inviteVerifyState: action.payload.inviteVerifyState
            });
        }
        case Action.BlockUi: {

            if (debug) console.info(`reducer.BlockUi -> ${action.payload.blocked}, ${action.payload.message}`);

            let blockUiState: IBlockUiState = Object.assign({}, state.blockUiState);

            if (action.payload.clearAll) {
                blockUiState.blockedCount = 0;
            } else {
                if (action.payload.blocked === true) {
                    blockUiState.blockedCount += 1;
                } else {
                    blockUiState.blockedCount -= 1;
                }    
            }

            blockUiState.message = action.payload.message;

            return Object.assign({}, state, {
                blockUiState: blockUiState
            });
        }
        case Action.Logout: {

            if (debug) console.info(`reducer.Logout`);

            return Object.assign({}, state, {
                authenticationToken: null,
                fbLoginState: null,
                invitationCode: null,
                blockUiState: { blockedCount: 0, message: '' },
                loginErrorMessage: null,
                userState: null,
                userLeaguesState: null,
                profileUpdateState: null,
                emailVerifyState: null,
                inviteVerifyState: null,
                seasons: null,
                createLeagueState: null,
                leagueState: null,
                leagueSeasonState: null,
                inviteState: null
            });
        }
        case Action.SetThisWeekSummaryVisible: {

            if (debug) console.info(`reducer.SetThisWeekSummaryVisible -> ${action.payload.visible}`);

            let leagueState: ILeagueState = Object.assign({}, state.leagueState);

            if (leagueState.thisWeekSummary) {
                leagueState.thisWeekSummary.visible = action.payload.visible;
            }

            return Object.assign({}, state, {
                leagueState
            });
        }
        case Action.SetLastWeekSummaryVisible: {

            if (debug) console.info(`reducer.SetLastWeekSummaryVisible -> ${action.payload.visible}`);

            let leagueState: ILeagueState = Object.assign({}, state.leagueState);

            if (leagueState.lastWeekSummary) {
                leagueState.lastWeekSummary.visible = action.payload.visible;
            }

            return Object.assign({}, state, {
                leagueState
            });
        }
        case Action.SetStandingsVisible: {

            if (debug) console.info(`reducer.SetStandingsVisible -> ${action.payload.visible}`);

            let leagueState: ILeagueState = Object.assign({}, state.leagueState);

            if (leagueState.standings) {
                leagueState.standings.visible = action.payload.visible;
            }

            return Object.assign({}, state, {
                leagueState
            });
        }
        case Action.SetPickMargin: {

            if (debug) console.info(`reducer.SetPickMargin -> ${action.payload.weekId} ${action.payload.gameId} ${action.payload.margin}`);

            if (!(state && state.leagueSeasonState && state.leagueSeasonState.weeks)) {
                // nothing to do.
                return state;
            }

            let leagueSeasonState = Object.assign({}, state.leagueSeasonState);

            let week = _.find(leagueSeasonState.weeks, (w: IWeek) => w.weekId === action.payload.weekId);

            if (!week || !_.isObject(week) || !week.gamePicks) {
                // no week
                return state;                
            }
            
            let gamePick = _.find(week.gamePicks, (g: IGamePick) => g.gameId === action.payload.gameId);

            if (!gamePick || !_.isObject(gamePick)) {
                // no gamePick
                return state;
            }

            gamePick.margin = action.payload.margin;

            return Object.assign({}, state, {
                leagueSeasonState: leagueSeasonState
            });
        }
        case '@@redux/INIT': {

            if (debug) console.info(`reducer.redux.INIT`);

            // nothing to do here now because we added the bootstrap service

            return state;
        }
        default: {

            if (debug) console.info(`reducer.default`);

            return state;
        }
    }    
}

export default defaultReducer;
