import * as React from "react";
import _ from "lodash";

import {IGamePick, ICurrentEliminationPick, ILeagueView} from "../../../models/interfaces";

export interface ITeamBoxProps {
    league: ILeagueView;
    gamePick: IGamePick;
    isAway: boolean;
    currentWeekId: number;
    currentEliminationPicks: Array<ICurrentEliminationPick>;
    updateGameSelection: (weekId: number, gameId: number, teamId: number) => void;
    toggleGameDetail: (weekId: number, gameId: number) => void;
}

const teamId = (props: ITeamBoxProps): number => {
    if (props.isAway) {
        return props.gamePick.awayTeamId;
    } else {
        return props.gamePick.homeTeamId;
    }
}

const teamName = (props: ITeamBoxProps): string => {
    let name: string;
    if (props.isAway) {
        name = props.gamePick.awayTeamName;
    } else {
        name = props.gamePick.homeTeamName;
    }
    return (name === 'Buccaneers') ? 'Bucs' : name;
}

const teamLocation = (props: ITeamBoxProps): string => {
    if (props.isAway) {
        return props.gamePick.awayTeamLocation;
    } else {
        return props.gamePick.homeTeamLocation;
    }
}

const spreadToString = (spread: number): string => {
    let str = '';
    if (spread > 0) {
        str = '+' + spread.toString();
    } else if (spread < 0) {
        str = spread.toString();
    } else {
        str = '±0';
    }
    return `(${str})`;
}

const spread = (props: ITeamBoxProps): string => {
    const val = props.isAway
        ? props.gamePick.awaySpread != null ? props.gamePick.awaySpread : null
        : props.gamePick.homeSpread != null ? props.gamePick.homeSpread : null;
    
    if (props.gamePick.locked && val != null) {
        if (props.league.leagueRuleSetId === 2) {
            // only show spread on the picked team
            if (!selected(props)) {
                return '';
            }
        } else {
            // only show spread on the favorite
            // has started and is the positive spread.
            if (val > 0) {
                return '';
            }
        }        
    }
    
    return val != null ? spreadToString(val) : '';
}

const spreadColorClass = (props: ITeamBoxProps): string => {
    if (props.gamePick.locked) {
        // has started
        if (props.isAway) {
            return 'game-spread-locked-away';
        } else {
            return 'game-spread-locked-home';
        }        
    }

    let spr = (props.isAway) ? props.gamePick.awaySpread : props.gamePick.homeSpread;

    if (_.isNull(spr) || spr === 0) {
        return '';
    }

    return (spr < 0) ? "game-spread-favored" : "game-spread-not-favored";
}

const score = (props: ITeamBoxProps): string => {
    if (!props.gamePick.locked || _.isNull(props.gamePick.period)) {
        // has not started
        return '';
    }

    if (props.isAway) {
        return props.gamePick.awayPoints.toString();
    } else {
        return props.gamePick.homePoints.toString();
    }
}

const pickCount = (props: ITeamBoxProps) => {
    if (!props.gamePick.locked) {
        // has not started
        return null;
    }

    let pickCount: number;
    if (props.isAway) {
        pickCount = props.gamePick.awayPickCount;
    } else {
        pickCount = props.gamePick.homePickCount;
    }

    if (_.isNull(pickCount) || _.isUndefined(pickCount)) {
        return null;
    } else {
        if (pickCount === 0) {
            return <span><i className="fa fa-lemon-o" aria-hidden="true"/></span>;
        } else {
            return <span>{pickCount} <i className="fa fa-user" aria-hidden="true"/></span>;
        }
    }
}

const winning = (props: ITeamBoxProps): boolean => {
    if (!props.gamePick.locked) {
        // hasn't started
        return false;
    }

    if (!selected(props)) {
        return false;
    }

    const awayPoints = props.gamePick.awayPoints;
    let homePoints = props.gamePick.homePoints;
    
    if (props.league.leagueRuleSetId === 2)
    {
        // vs spread...
        homePoints += props.gamePick.homeSpread;
    }

    if (props.isAway && awayPoints > homePoints) {
        return true;
    }

    if (!props.isAway && homePoints > awayPoints) {
        return true;
    }

    return false;
}

const losing = (props: ITeamBoxProps): boolean => {
    if (!props.gamePick.locked) {
        // hasn't started
        return false;
    }

    if (!selected(props)) {
        return false;
    }

    const awayPoints = props.gamePick.awayPoints;
    let homePoints = props.gamePick.homePoints;

    if (props.league.leagueRuleSetId === 2)
    {
        // vs spread...
        homePoints += props.gamePick.homeSpread;
    }

    if (props.isAway && awayPoints < homePoints) {
        return true;
    }

    if (!props.isAway && homePoints < awayPoints) {
        return true;
    }

    return false;
}

const hasBall = (props: ITeamBoxProps): boolean => {
    if (!props.gamePick.locked) {
        // hasn't started
        return false;
    }

    if (props.isAway && props.gamePick.awayTeamId === props.gamePick.possessionTeamId) {
        return true;
    }

    if (!props.isAway && props.gamePick.homeTeamId === props.gamePick.possessionTeamId) {
        return true;
    }

    return false;
}

const possessionIndicator = (props: ITeamBoxProps) => {
    if (hasBall(props)) {
        const fileName: string = props.gamePick.rz ? "football-rz.png" : "football.png";
        const alt: string = props.gamePick.rz ? "football-rz" : "football";
        return (<img alt={alt} className="possession-icon" src={`/img/${fileName}`} />);
    } else {
        return <span />;
    }
}

const pending = (props: ITeamBoxProps): boolean => {
    return teamId(props) === props.gamePick.pendingPickTeamId;
}

const selected = (props: ITeamBoxProps): boolean => {
    return teamId(props) === props.gamePick.currentPickTeamId;
}

const boxClass = (props: ITeamBoxProps): string => {
    let style: string;
    if (selected(props)) {
        if (props.gamePick.final) {
            if (props.gamePick.points > 0) {
                style = "green-box";
            } else {
                style = "red-box";
            }
        }
        else {
            if (props.gamePick.locked) {
                if (winning(props)) {
                    style = "green-maybe-box";
                } else if (losing(props)) {
                    style = "red-maybe-box";
                } else {
                    style = "black-box";
                }
            }
            else {
                if (props.isAway) {
                    style = "orange-box";
                } else {
                    style = "orange-box"; // was blue-box
                }
            }
        }
    }
    else {
        // check for elimination - show other picks.
        if (props.gamePick.weekId >= props.currentWeekId && props.currentEliminationPicks.length > 0) {
            const found = _.some(props.currentEliminationPicks, (ep: ICurrentEliminationPick) => {
                return ep.teamId === teamId(props);
            });
            if (found) {
                style = "black-box";
            } else {
                style = "gray-box";
            }
        } else {
            style = "gray-box";
        }
    }

    if (!pending(props)) {
        style += " clickable";
    }

    if (props.isAway) {
        style += " game-right-align";
    }

    return style + " team-box big-text game-min-height";
};

const iconClass = (props: ITeamBoxProps): string => {
    if (pending(props)) {
        return "refresh fa-spin";
    }
    if (selected(props)) {
        if (props.gamePick.locked) {
            return "lock";
        } else {
            return "check-square-o";
        }
    }
    if (props.gamePick.locked) {
        return "minus";
    } else {
        const found = _.some(props.currentEliminationPicks, (ep: ICurrentEliminationPick) => {
            return ep.teamId === teamId(props) && !ep.overwritable;
        });

        if (found) {
            return "lock";
        } else {
            return "square-o";
        }
    }
};

const onClickGame = (props: ITeamBoxProps, weekId: number, gameId: number, teamId: number) => {
    // locked game protection also lives in the reducer, in Action.PickGame (and server side too)
    if (props.gamePick.locked) {
        props.toggleGameDetail(weekId, gameId);
    } else {
        props.updateGameSelection(weekId, gameId, teamId);
    }
}

const TeamBox = (props: ITeamBoxProps) => {

    if (props.isAway) {
        return (
            <div className={boxClass(props)} onClick={e => {                
                onClickGame(props, props.gamePick.weekId, props.gamePick.gameId, teamId(props));
            } }>                
                <table className="game-table game-table-away">
                    <tbody>
                        <tr>
                            <td colSpan={2} className='game-team-location game-table-away'>
                                <div className="game-location game-table-away">{teamLocation(props)}</div>
                            </td>
                            <td/>
                        </tr>
                        <tr>                            
                            <td className="td-center-align">
                                {possessionIndicator(props)}
                                <span className={`game-spread ${spreadColorClass(props)}`}>{spread(props)}</span>
                                <span className="game-score">{score(props)}</span>
                            </td>
                            <td className="game-team-padding">                                
                                <div className="">{teamName(props)}</div>
                            </td>
                            <td>
                                <i className={`fa fa-${iconClass(props)} game-check-min-width`} aria-hidden="true"/>
                            </td>
                        </tr>
                        <tr>                            
                            <td colSpan={2}>
                                <div className="game-team-padding game-record"><span className="game-pick-count-away">{pickCount(props)}</span><span>{props.gamePick.awayTeamRecord}</span></div>
                            </td>
                            <td/>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    } else {
        return (
            <div className={boxClass(props)} onClick={e => {
                onClickGame(props, props.gamePick.weekId, props.gamePick.gameId, teamId(props));
            } }>
                <table className="game-table">
                    <tbody>
                        <tr>
                            <td/>    
                            <td  colSpan={2} className='game-team-location'>
                                <div className="game-location">{teamLocation(props)}</div>
                            </td>                            
                        </tr>
                        <tr>
                            <td>
                                <i className={`fa fa-${iconClass(props)} game-check-min-width`} aria-hidden="true"/>
                            </td>
                            <td className="game-team-padding">                                
                                <div className="">{teamName(props)}</div>                                
                            </td>
                            <td className="td-center-align">              
                                <span className="game-score">{score(props)}</span>
                                <span className={`game-spread ${spreadColorClass(props)}`}>{spread(props)}</span>
                                &nbsp;
                                {possessionIndicator(props)}
                            </td>
                        </tr>
                        <tr>
                            <td/>
                            <td colSpan={2}>
                                <div className="game-team-padding game-record"><span>{props.gamePick.homeTeamRecord}</span><span className="game-pick-count-home">{pickCount(props)}</span></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

}

export default TeamBox;
