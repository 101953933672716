import * as React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import _ from "lodash";

import MessagePanel from "../../controls/MessagePanel";
import HidableButton from "../../controls/HidableButton";

import LeagueTable from "../../widgets/LeagueTable";

import { DataService } from "../../../services/dataService"
import { UtilService } from "../../../services/utilService";
import { routeToLeague, routeToProfile } from "../../../services/routingService"

import { IState, IUserState, IInviteState } from "../../../state/state";
import { IAction, setInviteState } from "../../../actions";
import { IInvite } from "../../../models/interfaces";

export interface IInviteProps {
    dispatch: (action: IAction) => void,
    onStateChange: (newState: IInviteState) => void,
    authenticationToken: string,
    userState: IUserState,
    inviteState: IInviteState
}

const handleChange = (props: IInviteProps, e: any): void => {
    const newState = Object.assign({}, props.inviteState, {
        emailAddresses: e.target.value
    });

    props.onStateChange(newState);
}

const sendInvites = (props: IInviteProps) => {
    if (!props.inviteState.leagueView) {
        return;
    }
    DataService.sendLeagueInvites(
        props.authenticationToken,
        props.inviteState.leagueView.leagueId,
        props.inviteState.emailAddresses,
        props.dispatch
    ).then();
}

const sendMessage = (props: IInviteProps) => {
    if (props.inviteState.lastResponse === null) {
        return <span />;
    } else if (props.inviteState.lastResponse.success) {
        return <MessagePanel className="bg-success" message={props.inviteState.lastResponse.message} />
    } else {
        return <MessagePanel className="bg-danger" message={props.inviteState.lastResponse.message} />
    }
}

const Invite = (props: IInviteProps) => {

    const navigate = useNavigate();

    if (!_.isObject(props.inviteState)) {
        // seemingly getting here with no inviteState...
        // returning a blank template until the state arrives fixes this.
        return <div/>;
    }

    if (props.inviteState.initErrorMessage && props.inviteState.initErrorMessage.length > 0) {
        return (
            <div>
                <h1>League - Invite New Members</h1>
                <MessagePanel className="bg-danger" message={props.inviteState.initErrorMessage} />
            </div>
        );
    }

    const verifiedWarning = props.userState.verified ? '' : 'You must go to My Profile and verify your email address before sending invitations.';

    const inviteMapper = props.inviteState.invites.map(
        (invite: IInvite) =>
            <tr key={invite.id}>
                <td>{UtilService.dateForDisplay(invite.created)}</td>
                <td>{UtilService.dateForDisplay(invite.sent)}</td>
                <td>{invite.emailAddress}</td>
                <td>{invite.verified}</td>
                <td>{invite.userName}</td>                
            </tr>
    );

    return (
        <div>
            <h2 className="tight-panel">
                <img src="/img/new/leagues-32x32.png" className="icon32" alt=""/> {props.inviteState.leagueView?.leagueName || ''} - Invite New Members
            </h2>
            <div className="content-panel">
                <LeagueTable leagueView={props.inviteState.leagueView} leagueRuleSet={props.inviteState.leagueRuleSet} />
                <button
                    type="submit"
                    className="btn btn-pickem-blue button-margin"
                    onClick={() => {
                        if (props.inviteState.leagueView) {
                            routeToLeague(navigate, props.inviteState.leagueView.leagueId, null);
                        }
                    } }
                    >
                    <span className="glyphicon glyphicon-triangle-left" aria-hidden="true"/>
                    &nbsp;
                    Back To League
                </button>
            </div>

            <h2 className="tight-panel"><img src="/img/new/invite-32x32.png" className="icon32" alt=""/> Invite New Members</h2>
            <div className="content-panel">
                <p>Enter one or more email addresses, one email address per line: </p>
                <textarea
                    id="emailAddresses"
                    cols={80}
                    rows={10}
                    value={props.inviteState.emailAddresses}
                    onChange={e => handleChange(props, e)}
                    >
                </textarea><br/>
                <p>* invites will contain your league name, user name and email address.</p>
                <MessagePanel className="bg-danger" message={verifiedWarning} />
                {sendMessage(props)}
                <HidableButton
                    className="btn btn-pickem-blue"
                    visible={verifiedWarning.length === 0}
                    image="envelope"
                    text="Send Invites"
                    onClick={() => {
                        sendInvites(props);
                    } }
                    />
                <HidableButton
                    className="btn btn-pickem-blue"
                    visible={verifiedWarning.length > 0}
                    image="user"
                    text="My Profile"
                    onClick={() => {
                        routeToProfile(navigate);
                    } }
                    />
            </div>

            <h2><img src="/img/new/clock-32x32.png" className="icon32" alt=""/> Invites Already Sent</h2>
            <table className="table table-bordered table-hover shadow">
                <thead>
                    <tr>
                        <th>Created</th>
                        <th>Sent</th>
                        <th>Email Address</th>
                        <th>Accepted</th>
                        <th>User Name</th>                    
                    </tr>
                </thead>
                <tbody>
                    {inviteMapper}
                </tbody>
            </table>
        </div>
    );
}

function mapStateToProps(state: IState) {
    return {
        authenticationToken: state.authenticationToken,
        userState: state.userState,
        inviteState: state.inviteState
    };
}

const mapDispatchToProps = (dispatch: (action: IAction) => void) => {
    return {
        dispatch,
        onStateChange: (newState: IInviteState) => {
            dispatch(setInviteState(newState));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Invite as any);
