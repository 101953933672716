// noinspection JSUnusedLocalSymbols

import $ from "jquery";

import {
    IUserState,
    IFbLoginState,
    ILeagueState,
    ILeagueManageState,
    ILeagueSeasonState,
    ILeagueMemberState,
    IProfileUpdateState,
    IInviteState,
    IInviteVerifyState
} from "../state/state";

import { DefaultStateProvider } from "../state/defaultStateProvider";

import { UtilService } from "./utilService";

import {
    IAction,
    login,
    setGeneralErrorMessage,
    setProfileUpdateState,
    setEmailVerifyState,
    setUserData,
    setLeagueState,
    setLeagueManageState,
    setSeasons,
    setLeagueMemberState,
    setLeagueSeasonState,
    setLeagueWeekPicks,
    setLeagueGamePick,
    setInviteState,
    setInviteVerifyState,
    setPublicLeagues,
    setGamePickDetail,
    blockUi,
    setGamePickDetailLoading,
    setLeagueWeekPickLiveUpdates,
    setCurrentEliminationPicks,
    setLeagueLocked,
    setLeagueDeleted,
    setAddedUserLeagueEntries
} from "../actions";

import {
    IAjaxResponse,
    ICreateLeague,
    IUpdateLeague,
    IGamePick,
    IGamePickUpdate,
    ISeason,
    ILeagueView,
    IGamePickDetail,
    ICurrentEliminationPick,
    IWeek,
    IGamePickLiveUpdate
} from "../models/interfaces";

export interface ILeagueStateRequest {
    token: string;
    leagueId: number;
    leagueMemberId: number;
}

export interface IUserStateResponse extends IAjaxResponse {
    userState: IUserState;
}

export interface IFbUserStateResponse extends IAjaxResponse {
    userState: IUserState;
    authenticationToken: string;
}

export interface ILeagueStateResponse extends IAjaxResponse {
    leagueState: ILeagueState;
}

export interface ILeagueManageStateResponse extends IAjaxResponse {
    leagueManageState : ILeagueManageState;
}

export interface IGetSeasonsResponse extends IAjaxResponse {
    seasons: Array<ISeason>;
}

export interface IGetPublicLeaguesRequest {
    token: string;
}

export interface IGetPublicLeaguesResponse extends IAjaxResponse {
    leagues: Array<ILeagueView>;
}

export interface IJoinPublicLeagueRequest {
    token: string;
    leagueId: number;
}

export interface IGamePicksResponse extends IAjaxResponse {
    gamePicks: Array<IGamePick>;
}

export interface IGamePickUpdateRequest {
    token: string;
    leagueId: number;
    weekId: number;
    withReplace: boolean;
    gamePickUpdate: IGamePickUpdate;
}

export interface IGamePickUpdateResponse extends IAjaxResponse {
    weekId: number | null;
    weekPickCount: number | null;
    gameId: number | null;
    pickTeamId: number | null;
    overwritable: boolean;
    overwriteWeekId: number | null;
    elimPickName: string | null;
}

export interface IGamePicksUpdateRequest {
    token: string;
    leagueId: number;
    weekId: number;
    gamePickUpdates: Array<IGamePickUpdate>;
}

export interface ILeagueSeasonStateRequest {
    token: string;
    leagueId: number;
}

export interface ILeagueSeasonStateResponse extends IAjaxResponse {
    leagueSeasonState: ILeagueSeasonState;
}

export interface ILeagueMemberStateRequest {
    token: string;
    leagueMemberId: number;
}

export interface ILeagueMemberStateResponse extends IAjaxResponse {
    leagueMemberState: ILeagueMemberState;
}

export interface IInviteStateRequest {
    token: string;
    leagueId: number;
}

export interface IInviteStateResponse extends IAjaxResponse {
    trackerGuid: string;
    complete: boolean;
    sent: number;
    inviteState: IInviteState;
}

export interface ISendInvitesRequest {
    token: string;
    leagueId: number;
    emailAddresses: string;
    trackerGuid: string;
}

export interface ISendInvitesResponse extends IAjaxResponse {
    badEmailAddresses: Array<string>;
}

export interface ISendInvitesRequest {
    token: string;
    leagueId: number;
    emailAddresses: string
}

export interface ISendInvitesResponse extends IAjaxResponse {
    badEmailAddresses: Array<string>;
}

export interface IInviteVerifyStateRequest {
    token: string;
    code: string;
}

export interface IInviteVerifyStateResponse extends IAjaxResponse {
    inviteVerifyState: IInviteVerifyState;
}

export interface IGamePickDetailRequest {
    token: string;
    leagueId: number;
    gameId: number;
}

export interface IGamePickDetailResponse extends IAjaxResponse {
    gamePickDetail: IGamePickDetail;
}

export interface IGamePickLiveUpdatesRequest {
    token: string;
    leagueId: number;
    weekId: number;
}

export interface IGamePickLiveUpdatesResponse extends IAjaxResponse {
    week: IWeek;
    gamePickLiveUpdates: Array<IGamePickLiveUpdate>;
    members: number;
    eliminatedMembers: number;
}

export interface ICurrentEliminationPicksRequest {
    token: string;
    leagueId: number;
}

export interface ICurrentEliminationPicksResponse extends IAjaxResponse {    
    currentEliminationPicks: Array<ICurrentEliminationPick>;
}

export class DataService {

    static getUserState(token: string | null, block: boolean, dispatch: (action: IAction) => void): void {
        if (block) {
            dispatch(blockUi(true, "Getting User Data..."));
        }
        $.ajax({
            type: "POST",
            url: "/api/user/getUserState",
            data: JSON.stringify({ token: token }),
            success: (response: IUserStateResponse) => {
                if (response.success) {
                    dispatch(setGeneralErrorMessage(null));
                    dispatch(setUserData(response.userState));
                } else {
                    dispatch(setGeneralErrorMessage(response.message));
                }
                if (block) {
                    dispatch(blockUi(false, ""));
                }
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                if (block) {
                    dispatch(blockUi(false, ""));
                }
                dispatch(setGeneralErrorMessage(`HTTP Error ${jqXHR.status} (${jqXHR.statusText})`));
            },
            dataType: "json",
            contentType: "application/json",
        });
    }

    static getFbUserState(fbLoginState: IFbLoginState, block: boolean, dispatch: (action: IAction) => void): void {
        if (block) dispatch(blockUi(true, "Getting User Data..."));
        $.ajax({
            type: "POST",
            url: "/api/user/getFbUserState",
            data: JSON.stringify(fbLoginState),
            success: (response: IFbUserStateResponse) => {
                if (response.success) {
                    dispatch(setGeneralErrorMessage(null));
                    dispatch(setUserData(response.userState));
                    dispatch(login(response.authenticationToken));
                } else {
                    dispatch(setGeneralErrorMessage(response.message));
                }
                if (block) dispatch(blockUi(false, ""));
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                if (block) dispatch(blockUi(false, ""));
                dispatch(setGeneralErrorMessage(`HTTP Error ${jqXHR.status} (${jqXHR.statusText})`));
            },
            dataType: "json",
            contentType: "application/json",
        });
    }

    static sendEmailVerification(token: string, onComplete: (response: IAjaxResponse) => void, dispatch: (action: IAction) => void): void {
        dispatch(blockUi(true, "Sending Email..."));  
        $.ajax({
            type: "POST",
            url: "/api/verification/sendEmailVerification",
            data: JSON.stringify({ token: token }),
            success: (response: IAjaxResponse) => {
                onComplete(response);
                dispatch(blockUi(false, ""));
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                onComplete({ success: false, message: `HTTP Error ${jqXHR.status} (${jqXHR.statusText})` });
                dispatch(blockUi(false, ""));
            },
            dataType: "json",
            contentType: "application/json",
        });
    }

    static verifyEmail(code: string, dispatch: (action: IAction) => void): void {
        $.ajax({
            type: "POST",
            url: "/api/verification/verifyEmail",
            data: JSON.stringify({ code: code }),
            success: (response: IAjaxResponse) => {
                dispatch(setEmailVerifyState(response));
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                dispatch(setEmailVerifyState({ success: false, message: `HTTP Error ${jqXHR.status} (${jqXHR.statusText})` }));
            },
            dataType: "json",
            contentType: "application/json",
        });
    }

    static getProfileUpdateState(token: string | null, dispatch: (action: IAction) => void): void {
        $.ajax({
            type: "POST",
            url: "/api/user/getUserState",
            data: JSON.stringify({ token: token }),
            success: (response: IUserStateResponse) => {
                if (response.success) {
                    dispatch(setGeneralErrorMessage(null));
                    var profileUpdateState: IProfileUpdateState = {
                        errorMessage: '',
                        successMessage: '',
                        origUserName: response.userState.userName,
                        newUserName: response.userState.userName,
                        origEmail: response.userState.email,
                        newEmail: response.userState.email,
                        origTimeZone: response.userState.timeZone,
                        newTimeZone: response.userState.timeZone,
                        newPassword: '',
                        created: response.userState.created,
                        verified: response.userState.verified,
                        origReminderOptOut: response.userState.reminderOptOut,
                        newReminderOptOut: response.userState.reminderOptOut,
                        origCrossLeaguePicking: response.userState.crossLeaguePicking,
                        newCrossLeaguePicking: response.userState.crossLeaguePicking
                    };

                    dispatch(setProfileUpdateState(profileUpdateState));
                } else {
                    dispatch(setGeneralErrorMessage(response.message));
                }
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                dispatch(setGeneralErrorMessage(`HTTP Error ${jqXHR.status} (${jqXHR.statusText})`));
            },
            dataType: "json",
            contentType: "application/json",
        });
    }

    static getSeasons(dispatch: (action: IAction) => void): void {
        $.ajax({
            type: "GET",
            url: "/api/league/getSeasons",
            success: (response: IGetSeasonsResponse) => {
                if (response.success) {
                    dispatch(setSeasons(response.seasons));
                } else {
                    // handle error
                }
            },
            dataType: "json",
            contentType: "application/json",
        });
    }

    static getPublicLeagues(token: string | null, block: boolean, dispatch: (action: IAction) => void): void {
        if (block) dispatch(blockUi(true, "Getting Public Leagues..."));
        $.ajax({
            type: "POST",
            url: "/api/league/getPublicLeagues",
            data: JSON.stringify({ token: token } as IGetPublicLeaguesRequest),
            success: (response: IGetPublicLeaguesResponse) => {
                if (response.success) {
                    dispatch(setPublicLeagues(response.leagues));
                } else {
                    // handle error
                }
                if (block) dispatch(blockUi(false, ""));
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                // handle error
                if (block) dispatch(blockUi(false, ""));
            },
            dataType: "json",
            contentType: "application/json",
        });
    }

    static joinPublicLeague(
        token: string,
        leagueId: number,
        dispatch: (action: IAction) => void
    ): Promise<IAjaxResponse> {
        return new Promise((resolve) => {
            dispatch(blockUi(true, "Joining League..."));
            $.ajax({
                type: "POST",
                url: "/api/league/joinPublicLeague",
                data: JSON.stringify({ token: token, leagueId: leagueId } as IJoinPublicLeagueRequest),
                success: (response: IAjaxResponse) => {
                    UtilService.fbEventTrack('JoinPublicLeagueSuccess', {leagueId: leagueId});
                    dispatch(blockUi(false, ""));
                    resolve(response);
                },
                error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                    UtilService.fbEventTrack('JoinPublicLeagueFailure', {leagueId: leagueId, statusText: jqXHR.statusText});
                    dispatch(blockUi(false, ""));
                    resolve({ success: false, message: `HTTP Error ${jqXHR.status} (${jqXHR.statusText})` });
                },
                dataType: "json",
                contentType: "application/json",
            });
        });
    }

    static getGamePicks(
        token: string,
        leagueId: number,
        leagueMemberId: number,
        weekId: number,
        showPicks: boolean,
        dispatch: (action: IAction) => void): void
    {
        dispatch(setGamePickDetailLoading(weekId, true));
        $.ajax({
            type: "POST",
            url: "/api/league/getGamePicks",
            data: JSON.stringify({ token: token, leagueId: leagueId, leagueMemberId: leagueMemberId, weekId: weekId }),
            success: (response: IGamePicksResponse) => {
                if (response.success) {                    
                    dispatch(setLeagueWeekPicks(weekId, response.gamePicks, showPicks));
                } else {
                    // handle error
                }
                dispatch(setGamePickDetailLoading(weekId, false));
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                dispatch(setGamePickDetailLoading(weekId, false));
            },
            dataType: "json",
            contentType: "application/json",
        });
    }

    static createLeague(
        token: string,
        league: ICreateLeague,
        callback: (message: IAjaxResponse) => void,
        dispatch: (action: IAction) => void): void {

        dispatch(blockUi(true, "Creating League..."));
        $.ajax({
            type: "POST",
            url: "/api/league/createLeague",
            data: JSON.stringify({ token: token, createLeague: league }),
            success: (response: IAjaxResponse) => {
                callback(response);
                dispatch(blockUi(false, ""));
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                callback({ success: false, message: `HTTP Error ${jqXHR.status} (${jqXHR.statusText})` });
                dispatch(blockUi(false, ""));
            },
            dataType: "json",
            contentType: "application/json",
        });
    }

    static getLeagueState(token: string | null, leagueId: number, leagueMemberId: number | null, dispatch: (action: IAction) => void): void {
        $.ajax({
            type: "POST",
            url: "/api/league/getLeagueState",
            data: JSON.stringify({ token: token, leagueId: leagueId, leagueMemberId: leagueMemberId || 0 }),
            success: (response: ILeagueStateResponse) => {
                if (response.success) {
                    dispatch(setLeagueState(response.leagueState));
                } else {
                    dispatch(setLeagueState(DefaultStateProvider.getLeagueStateError(response.message)));
                }
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                const errorMessage = `HTTP Error ${jqXHR.status} (${jqXHR.statusText})`;
                dispatch(setLeagueState(DefaultStateProvider.getLeagueStateError(errorMessage)));
            },
            dataType: "json",
            contentType: "application/json",
        });
    }

    static getLeagueManageState(token: string | null, leagueId: number, dispatch: (action: IAction) => void): void {
        $.ajax({
            type: "POST",
            url: "/api/league/getLeagueManageState",
            data: JSON.stringify({ token: token, leagueId: leagueId}),
            success: (response: ILeagueManageStateResponse) => {
                if (response.success) {
                    dispatch(setLeagueManageState(response.leagueManageState));
                } else {
                    dispatch(setLeagueManageState(DefaultStateProvider.getLeagueManageStateError(response.message)));
                }
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                const errorMessage = `HTTP Error ${jqXHR.status} (${jqXHR.statusText})`;
                dispatch(setLeagueManageState(DefaultStateProvider.getLeagueManageStateError(errorMessage)));
            },
            dataType: "json",
            contentType: "application/json",
        });
    }
    
    static getLeagueMemberState(token: string | null, leagueMemberId: number, dispatch: (action: IAction) => void): void {
        $.ajax({
            type: "POST",
            url: "/api/league/getLeagueMemberState",
            data: JSON.stringify({ token: token, leagueMemberId: leagueMemberId }),
            success: (response: ILeagueMemberStateResponse) => {
                if (response.success) {
                    dispatch(setLeagueMemberState(response.leagueMemberState));
                } else {
                    dispatch(setLeagueMemberState(DefaultStateProvider.getLeagueMemberStateError(response.message)));
                }
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                const errorMessage = `HTTP Error ${jqXHR.status} (${jqXHR.statusText})`;
                dispatch(setLeagueMemberState(DefaultStateProvider.getLeagueMemberStateError(errorMessage)));
            },
            dataType: "json",
            contentType: "application/json",
        });
    }
    
    static getLeagueSeasonState(token: string | null, leagueId: number, leagueMemberId: number, dispatch: (action: IAction) => void): void {
        $.ajax({
            type: "POST",
            url: "/api/league/getLeagueSeasonState",
            data: JSON.stringify({ token: token, leagueId: leagueId, leagueMemberId: leagueMemberId || 0 }),
            success: (response: ILeagueSeasonStateResponse) => {
                if (response.success) {
                    dispatch(setLeagueSeasonState(response.leagueSeasonState));
                } else {
                    dispatch(setLeagueSeasonState(DefaultStateProvider.getLeagueSeasonStateError(response.message)));
                }
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                const errorMessage = `HTTP Error ${jqXHR.status} (${jqXHR.statusText})`;
                dispatch(setLeagueSeasonState(DefaultStateProvider.getLeagueSeasonStateError(errorMessage)));
            },
            dataType: "json",
            contentType: "application/json",
        });
    }

    static getCurrentEliminationPicks(token: string, leagueId: number, leagueMemberId: number, dispatch: (action: IAction) => void): void {
        $.ajax({
            type: "POST",
            url: "/api/league/getCurrentEliminationPicks",
            data: JSON.stringify({ token: token, leagueId: leagueId, leagueMemberId: leagueMemberId }),
            success: (response: ICurrentEliminationPicksResponse) => {
                if (response.success) {
                    dispatch(setCurrentEliminationPicks(response.currentEliminationPicks));
                } else {
                    // handle error
                }
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                // handle error
            },
            dataType: "json",
            contentType: "application/json",
        });
    }

    static updateGamePick(
        token: string,
        leagueId: number,
        leagueMemberId: number,
        weekId: number,
        withReplace: boolean,
        gamePick: IGamePick,        
        onComplete: (response: IGamePickUpdateResponse) => void,
        dispatch: (action: IAction) => void): void {

        const gamePickUpdate: IGamePickUpdate = {
            gameId: gamePick.gameId,
            leagueMemberPickId: gamePick.leagueMemberPickId,
            leagueMemberId: leagueMemberId,
            origPickTeamId: gamePick.origPickTeamId,
            currentPickTeamId: gamePick.currentPickTeamId,
            created: gamePick.created,
            margin: gamePick.margin
        };

        const gamePickUpdateRequest: IGamePickUpdateRequest = {
            token: token,
            leagueId: leagueId,
            weekId: weekId,
            withReplace: withReplace,
            gamePickUpdate: gamePickUpdate
        }

        $.ajax({
            type: "POST",
            url: "/api/league/updateGamePick",
            data: JSON.stringify(gamePickUpdateRequest),
            success: (response: IGamePickUpdateResponse) => {
                if (response.success) {
                    UtilService.fbEventTrack('GamePickSuccess', {leagueId: gamePickUpdateRequest.leagueId});
                    dispatch(setLeagueGamePick(response.weekId, response.weekPickCount, response.gameId, response.pickTeamId, response.elimPickName));               
                } else {
                    UtilService.fbEventTrack('GamePickUnable', {leagueId: gamePickUpdateRequest.leagueId});    
                }                
                onComplete(response);
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                const errorMessage = `HTTP Error ${jqXHR.status} (${jqXHR.statusText})`;

                UtilService.fbEventTrack('GamePickFailure', {leagueId: gamePickUpdateRequest.leagueId, errorMessage: errorMessage});

                const gamePickUpdateResponse: IGamePickUpdateResponse = { 
                    success: false, 
                    message: errorMessage, 
                    weekId: null, 
                    weekPickCount: null, 
                    gameId: null, 
                    pickTeamId: null, 
                    overwritable: false, 
                    overwriteWeekId: null, 
                    elimPickName: null 
                }; 

                onComplete(gamePickUpdateResponse);
            },
            dataType: "json",
            contentType: "application/json",
        });
    }

    static getInviteState(token: string | null, leagueId: number, dispatch: (action: IAction) => void): void {
        $.ajax({
            type: "POST",
            url: "/api/league/getInviteState",
            data: JSON.stringify({ token: token, leagueId: leagueId } as IInviteStateRequest),
            success: (response: IInviteStateResponse) => {
                if (response.success) {
                    dispatch(setInviteState(response.inviteState));
                } else {
                    dispatch(setInviteState(DefaultStateProvider.getInviteStateError(response.message)));
                }
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                const errorMessage = `HTTP Error ${jqXHR.status} (${jqXHR.statusText})`;
                dispatch(setInviteState(DefaultStateProvider.getInviteStateError(errorMessage)));
            },
            dataType: "json",
            contentType: "application/json",
        });
    }

    static getGamePickDetail(token: string, leagueId: number, weekId: number, gameId: number, dispatch: (action: IAction) => void): void {
        $.ajax({
            type: "POST",
            url: "/api/league/getGamePickDetail",
            data: JSON.stringify({ token: token, leagueId: leagueId, gameId: gameId } as IGamePickDetailRequest),
            success: (response: IGamePickDetailResponse) => {
                if (response.success) {
                    dispatch(setGamePickDetail(weekId, gameId, response.gamePickDetail));
                } else {
                    dispatch(setGamePickDetail(weekId, gameId, {
                        initErrorMessage: response.message,
                        havePicked: [],
                        haveNotPicked: [],
                        pickedHome: [],
                        pickedAway: []
                    }));
                }
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                dispatch(setGamePickDetail(weekId, gameId, {
                    initErrorMessage: `HTTP Error ${jqXHR.status} (${jqXHR.statusText})`,
                    havePicked: [],
                    haveNotPicked: [],
                    pickedHome: [],
                    pickedAway: []
                }));
            },
            dataType: "json",
            contentType: "application/json",
        });
    }

    static sendLeagueInvitesUpdate(
        token: string | null, trackerGuid: string, dispatch: (action: IAction) => void
    ): Promise<IInviteStateResponse> {
        return new Promise((resolve) => {
            $.ajax({
                type: "POST",
                url: "/api/verification/sendLeagueInvitesUpdate",
                data: JSON.stringify({ token: token, trackerGuid: trackerGuid } as ISendInvitesRequest),
                success: (response: IInviteStateResponse) => {
                    resolve(response);
                },
                error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                    resolve({
                        success: false,
                        message: `HTTP Error ${jqXHR.status} (${jqXHR.statusText})`,
                        trackerGuid: trackerGuid,
                        complete: true,
                        sent: 0,
                        inviteState: {
                            initErrorMessage: `HTTP Error ${jqXHR.status} (${jqXHR.statusText})`,
                            leagueView: null,
                            leagueRuleSet: null,
                            invites: [],
                            emailAddresses: '',
                            lastResponse: null
                        }
                    });
                },
                dataType: "json",
                contentType: "application/json",
            });
        });
    }

    static async sendLeagueInvites(token: string, leagueId: number, emailAddresses: string, dispatch: (action: IAction) => void): Promise<void> {
        dispatch(blockUi(true, "Sending Invites..."));
        $.ajax({
            type: "POST",
            url: "/api/verification/sendLeagueInvites",
            data: JSON.stringify({ token: token, leagueId: leagueId, emailAddresses: emailAddresses } as ISendInvitesRequest),
            success: async (response: IInviteStateResponse) => {
                if (response.success) {
                    const trackerGuid = response.trackerGuid;
                    let complete = false;
                    let failCount = 0;
                    while (!complete) {
                        const result = await DataService.sendLeagueInvitesUpdate(token, trackerGuid, dispatch);
                        if (!result.success) {
                            failCount++;
                            if (failCount > 3) {
                                complete = true;
                                UtilService.fbEventTrack('SendInvitesFailure', {message: result.message});
                                dispatch(setInviteState({
                                    initErrorMessage: result.message,
                                    leagueView: null,
                                    leagueRuleSet: undefined,
                                    invites: [],
                                    emailAddresses: '',
                                    lastResponse: null
                                } as IInviteState));
                            }
                            continue;
                        }
                        if (!result.complete) {
                            // still working                            
                            dispatch(blockUi(true, "Sent " + result.sent + " Invites..."));
                        } else {
                            // it is complete
                            complete = true;
                            UtilService.fbEventTrack('SendInvitesSuccess', {count: result?.inviteState?.invites?.length});
                            dispatch(setInviteState(result.inviteState));
                        }
                    }
                } else {
                    UtilService.fbEventTrack('SendInvitesFailure', {message: response.message});
                    dispatch(setInviteState({
                        initErrorMessage: response.message,
                        leagueView: null,
                        leagueRuleSet: undefined,
                        invites: [],
                        emailAddresses: '',
                        lastResponse: null
                    } as IInviteState));
                }
                dispatch(blockUi(false, "", true));
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                dispatch(setInviteState({
                    initErrorMessage: `HTTP Error ${jqXHR.status} (${jqXHR.statusText})`,
                    leagueView: null,
                    leagueRuleSet: undefined,
                    invites: [],
                    emailAddresses: '',
                    lastResponse: null
                } as IInviteState));
                dispatch(blockUi(false, ""));
            },
            dataType: "json",
            contentType: "application/json",
        });
    }

    static getInviteVerifyState(token: string | null, code: string, dispatch: (action: IAction) => void): void {
        $.ajax({
            type: "POST",
            url: "/api/verification/getInviteVerifyState",
            data: JSON.stringify({ token: token, code: code } as IInviteVerifyStateRequest),
            success: (response: IInviteVerifyStateResponse) => {
                if (response.success) {
                    dispatch(setInviteVerifyState(response.inviteVerifyState));
                } else {
                    dispatch(setInviteVerifyState(DefaultStateProvider.getInviteVerifyStateError(response.message)));
                }
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                const errorMessage = `HTTP Error ${jqXHR.status} (${jqXHR.statusText})`;
                dispatch(setInviteVerifyState(DefaultStateProvider.getInviteVerifyStateError(errorMessage)));
            },
            dataType: "json",
            contentType: "application/json",
        });
    }

    static getGamePickLiveUpdates(token: string | null, leagueId: number, leagueMemberId: number, weekId: number, dispatch: (action: IAction) => void): void {
        dispatch(setGamePickDetailLoading(weekId, true));
        $.ajax({
            type: "POST",
            url: "/api/league/getGamePickLiveUpdates",
            data: JSON.stringify({ token: token, leagueId: leagueId, leagueMemberId: leagueMemberId, weekId: weekId } as IGamePickLiveUpdatesRequest),
            success: (response: IGamePickLiveUpdatesResponse) => {
                if (response.success) {
                    dispatch(setLeagueWeekPickLiveUpdates(
                        response.week,
                        response.gamePickLiveUpdates,
                        response.members,
                        response.eliminatedMembers
                    ));
                } else {
                    // handle error
                }
                dispatch(setGamePickDetailLoading(weekId, false));
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                dispatch(setGamePickDetailLoading(weekId, false));
            },
            dataType: "json",
            contentType: "application/json",
        });
    }

    static lockLeague(token: string, leagueId: number, dispatch: (action: IAction) => void): Promise<IAjaxResponse> {
        return new Promise((resolve) => {
            $.ajax({
                type: "POST",
                url: "/api/league/lockLeague",
                data: JSON.stringify({ token: token, leagueId: leagueId, locked: true }),
                success: (response: IAjaxResponse) => {
                    if (response.success) {
                        dispatch(setLeagueLocked(leagueId, true));                        
                    }
                    resolve(response);
                },
                error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                    const errorMessage = `HTTP Error ${jqXHR.status} (${jqXHR.statusText})`;
                    resolve({ success: false, message: errorMessage});
                },
                dataType: "json",
                contentType: "application/json",
            });
        });
    }

    static deleteLeague(token: string, leagueId: number, dispatch: (action: IAction) => void): void {
        $.ajax({
            type: "POST",
            url: "/api/league/deleteLeague",
            data: JSON.stringify({ token: token, leagueId: leagueId, deleted: true }),
            success: (response: IAjaxResponse) => {
                if (response.success) {
                    dispatch(setLeagueDeleted(leagueId, true));
                } else {
                    // todo: handle error
                }
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                // const errorMessage = `HTTP Error ${jqXHR.status} (${jqXHR.statusText})`;
                // todo: handle error
            },
            dataType: "json",
            contentType: "application/json",
        });
    }

    static updateLeague(token: string, league: IUpdateLeague, dispatch: (action: IAction) => void): Promise<IAjaxResponse> {
        return new Promise((resolve) => {
            $.ajax({
                type: "POST",
                url: "/api/league/updateLeague",
                data: JSON.stringify({ token: token, updateLeague: league }),
                success: (response: IAjaxResponse) => {                    
                    resolve(response);
                },
                error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                    const errorMessage = `HTTP Error ${jqXHR.status} (${jqXHR.statusText})`;
                    resolve({ success: false, message: errorMessage });
                },
                dataType: "json",
                contentType: "application/json",
            });
        });
    }

    static addUserLeagueEntries(token: string, leagueMemberId: number, count: number, dispatch: (action: IAction) => void): Promise<IAjaxResponse> {
        return new Promise((resolve) => {
            $.ajax({
                type: "POST",
                url: "/api/league/addUserLeagueEntries",
                data: JSON.stringify({ token: token, leagueMemberId: leagueMemberId, count: count }),
                success: (response: IAjaxResponse) => {
                    if (response.success) {
                        dispatch(setAddedUserLeagueEntries(leagueMemberId, count));
                    }
                    resolve(response);
                },
                error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                    const errorMessage = `HTTP Error ${jqXHR.status} (${jqXHR.statusText})`;
                    resolve({ success: false, message: errorMessage });
                },
                dataType: "json",
                contentType: "application/json",
            });
        });
    }

    static addUserLeagueRebuy(token: string, leagueMemberId: number, dispatch: (action: IAction) => void): Promise<IAjaxResponse> {
        return new Promise((resolve) => {
            $.ajax({
                type: "POST",
                url: "/api/league/addUserLeagueRebuy",
                data: JSON.stringify({ token: token, leagueMemberId: leagueMemberId }),
                success: (response: IAjaxResponse) => {
                    if (response.success) {
                        // nothing to do
                    }
                    resolve(response);
                },
                error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                    const errorMessage = `HTTP Error ${jqXHR.status} (${jqXHR.statusText})`;
                    resolve({ success: false, message: errorMessage });
                },
                dataType: "json",
                contentType: "application/json",
            });
        });
    }

    static deleteLeagueMember(token: string, leagueMemberId: number, dispatch: (action: IAction) => void): Promise<IAjaxResponse> {
        return new Promise((resolve) => {
            $.ajax({
                type: "POST",
                url: "/api/league/deleteLeagueMember",
                data: JSON.stringify({ token: token, leagueMemberId: leagueMemberId }),
                success: (response: IAjaxResponse) => {
                    if (response.success) {
                        // nothing to do
                    }
                    resolve(response);
                },
                error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                    const errorMessage = `HTTP Error ${jqXHR.status} (${jqXHR.statusText})`;
                    resolve({ success: false, message: errorMessage });
                },
                dataType: "json",
                contentType: "application/json",
            });
        });
    }

    static managerPickOverride(token: string, leagueMemberId: number, weekId: number, teamId: number, dispatch: (action: IAction) => void): Promise<IAjaxResponse> {
        return new Promise((resolve) => {
            $.ajax({
                type: "POST",
                url: "/api/league/managerPickOverride",
                data: JSON.stringify({ token, leagueMemberId, weekId, teamId }),
                success: (response: IAjaxResponse) => {
                    if (response.success) {
                        // nothing to do
                    }
                    resolve(response);
                },
                error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                    const errorMessage = `HTTP Error ${jqXHR.status} (${jqXHR.statusText})`;
                    resolve({ success: false, message: errorMessage });
                },
                dataType: "json",
                contentType: "application/json",
            });
        });
    }

    static updateTeamName(token: string, leagueMemberId: number, teamName: string, dispatch: (action: IAction) => void): Promise<IAjaxResponse> {
        return new Promise((resolve) => {
            $.ajax({
                type: "POST",
                url: "/api/league/updateTeamName",
                data: JSON.stringify({ token, leagueMemberId, teamName }),
                success: (response: IAjaxResponse) => {
                    if (response.success) {
                        // nothing to do
                    }
                    resolve(response);
                },
                error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                    const errorMessage = `HTTP Error ${jqXHR.status} (${jqXHR.statusText})`;
                    resolve({ success: false, message: errorMessage });
                },
                dataType: "json",
                contentType: "application/json",
            });
        });
    }
}
