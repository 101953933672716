import * as React from "react";

export interface IResponsiveImageProps {
    fileName: string;    
}

const ResponsiveImage = (props: IResponsiveImageProps) => {

    return (        
        <table>
            <tbody>
                <tr>
                    <td className="gray-box image-holder">
                        <img className="img-responsive"src={`/img/${props.fileName}`} alt=""/>
                    </td>
                </tr>
            </tbody>
        </table>);
};

export default ResponsiveImage;
