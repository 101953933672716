import * as React from "react";
import { connect } from "react-redux";

import MessagePanel from "../../controls/MessagePanel";

import { IState, IEmailVerifyState } from "../../../state/state";

import { UtilService } from "../../../services/utilService";

export interface IEmailVerifyProps { dispatch: any, emailVerifyState: IEmailVerifyState }

const EmailVerify = (props: IEmailVerifyProps) => {

    if (!props.emailVerifyState) {
        return (
            <div/>
        );
    }

    if (props.emailVerifyState.response.success) {
        UtilService.fbEventTrack('EmailVerifySuccess');
        return (
            <div>
                <h2 className="tight-panel"><img src="/img/new/invite-32x32.png" className="icon32" alt=""/> Email Address Verified!</h2>
                <div className="content-panel">
                <p>Thanks for verifying your email address!</p>
                <p>You may now invite other players to your leagues.</p>
                </div>
            </div>
        );
    }

    UtilService.fbEventTrack('EmailVerifyFailure', {message: props.emailVerifyState.response.message});
    return (
        <div>
            <h2 className="tight-panel"><i className="fa fa-exclamation-triangle" aria-hidden="true"/> Email Address Was NOT Verified</h2>
            <div className="content-panel">
                <p>We could not process the requested email verification.</p>
                <MessagePanel className="bg-danger" message={props.emailVerifyState.response.message} />
            </div>
        </div>
    );
}

function mapStateToProps(state: IState) {
    return { emailVerifyState: state !== undefined ? state.emailVerifyState : null };
}

export default connect(mapStateToProps)(EmailVerify as any);
