import * as React from "react";

export interface LeagueShareLinkProps {
    guid: string;
    message: string;
    top: string;
    altImage: boolean;
}

const LeagueShareLink = (props: LeagueShareLinkProps) => {
    const messageEncoded = encodeURI(props.message);
    const scr = props.altImage ? "/img/invite_friends.png" : "/img/share_on_facebook.png";
    const href = "https://www.facebook.com/dialog/share?app_id=418860188759843&display=popup&href=https%3A%2F%2Fwww.pickemleagues.com%2F%23%2FinviteVerify%3Fg%3D" + props.guid + "&quote=" + messageEncoded;
    return (<a style={{ position: 'relative', top: props.top || '0px' }} href={href} target="_blank" rel="noreferrer"><img src={scr} alt=""/></a>);
};

export default LeagueShareLink;
